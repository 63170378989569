import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import projectReducer from '../features/project/projectSlice';
import portalReducer from '../features/portal/portalSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

let rootReducers = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  project: projectReducer,
  portal: portalReducer,
});

export type RootReducer = ReturnType<typeof rootReducers>;

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')

    return rootReducers(undefined, action);
  }
  return rootReducers(state, action);
};

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
