import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const FilePreviewCard = (props: ChildProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const { file, zoom } = props;
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);

  const loadPdf = () => {
    setIsPdf(true);
  };

  const onDocumentLoadSuccess = () => {
    setNumPages(numPages);
  };

  return (
    <FilePreviewContainer>
      {!isPdf ? (
        <img src={file} onError={loadPdf} width={250} alt="fileImage" />
      ) : 
      zoom ?
        <Zoom>
          <PDFContainer>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                <Page pageNumber={1} />
              </Document>
          </PDFContainer>
        </Zoom>
      :
      <PDFContainer>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
            <Page pageNumber={1} />
          </Document>
      </PDFContainer>
      }
    </FilePreviewContainer>
  );
};

interface ChildProps {
  file: any;
  handleImage?: any;
  handleDelete?: any;
  zoom?: boolean;
}

//zooming pdf looks bad
const FilePreviewContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
  }
  .react_pdf_Page {
    width: 250px !important;
    height: 300px !important;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 50% !important;
    object-fit: cover !important;
  }
`;

const PDFContainer = styled.div`
  height: 1000px;
`;

export default FilePreviewCard;
