import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from 'styled-components';

const LoadingWrapper = (props: ChildProps) => {
  const { title, color } = props;

  return (
    <Wrapper>
      <Box sx={{ display: 'flex', marginBottom: '15px' }}>
        <CircularProgress sx={{ color: color ? color : '#000'}} />
      </Box>
      <span>
        <em>{title}</em>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

interface ChildProps {
  title: string;
  modal: boolean;
  color?: string;
}

export default LoadingWrapper;
