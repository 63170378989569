import { useState, useMemo } from 'react';
import { Paper, Button, Fade, Box, Divider, Typography } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';

import { IUnit } from '../../types/unit';
import { IUser } from '../../types/user';
import { IProject } from '../../types/project';
import StandardTable from '../common/StandardTable';
import { numToCurrency, downloadImages, downloadPDF, urlName } from '../../utils/Functions';
import { pdfColumnWidths } from '../../utils/Constants';

import { Flex } from '../../utils/CommonStyles';

const NewHotlist = (props: ChildProps) => {
  const { project, user, logInteraction } = props;
  const navigate = useNavigate();
  const [units, setUnits] = useState<IUnit[]>([]);

  const { loading } = useQuery(HOTLIST, {
    skip: !project._id,
    variables: {
      user: user ? user._id : null,
      project: project._id,
      projects: project.combinedProjects.length ? project.combinedProjects.map((project) => project._id) : [],
    },
    onCompleted: (data) => {
      let sortedSuites = [...data.hotlist].sort((a, b) => a.basePrice - b.basePrice);
      setUnits(sortedSuites);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const download = (type: string) => {
    if (type === 'floorplans') {
      downloadImages(units, `${project.name} Floorplans`, project);
      logInteraction({
        variables: { user: user ? user._id : null, project: project._id, type: 'allFloorPlans' },
      });
    } else if (type === 'hotlist') {
      const realtorName = user?.realtor ? user.realtor.firstName + ' ' + user.realtor.lastName : '';
      let headers = ['suite', 'basePrice', 'level', 'unitType', 'modelType', 'size', 'outdoorSize', 'exposure', 'bathroom'];
      let formattedHeaders = ['Suite', 'Price', 'Level', 'Type', 'Model', 'Interior Size', 'Outdoor Size', 'View', 'Bathrooms'];
      let columnWidths = pdfColumnWidths;
      if (units.length && units[0].tier) {
        headers.push('tier');
        formattedHeaders.push('Collection');
      }

      if (units.length && units[0].rental) {
        headers.push('rental');
        formattedHeaders.push('Rental');
      }

      if (project.portal.showCustom) {
        headers.push('custom');
        formattedHeaders.push('Misc');
      }

      downloadPDF(units, headers, formattedHeaders, [], columnWidths, `${project.name} Hotlist.pdf`, {
        realtor: realtorName,
        projectLogo: project.logoGetUrl,
      });
      logInteraction({ variables: { user: user ? user._id : null, project: project._id, type: 'hotlist' } });
    }
  };

  const columns = useMemo(() => {
    let standardColumns = [
      {
        accessorKey: 'Suite',
        accessorFn: (row: any) => row.suite,
        cell: (info: any) => info.getValue(),
        header: (rowData: any) => {
          return rowData.column.id;
        },
      },
      {
        accessorKey: 'Price',
        accessorFn: (row: any) => row.basePrice,
        cell: (info: any) => <Box>{numToCurrency.format(info.getValue())}</Box>,
        header: (rowData: any) => {
          return rowData.column.id;
        },
      },
      {
        header: 'Floorplan',
        accessorFn: (row: any) => row,
        cell: (info: any) => {
          return (
            <>
              <Button
                sx={{
                  backgroundColor: project.portal.primaryColor,
                  color: '#fff',
                  ':hover': {
                    backgroundColor: project.portal.primaryColor,
                    color: '#fff',
                  },
                  mr: 1,
                  mb: 1,
                  fontFamily: 'Abadi-Standard !important',
                }}
                href={info.getValue().marketingFloorPlan}
                target="_blank"
              >
                Floorplan
              </Button>
              <Button
                sx={{
                  backgroundColor: project.portal.primaryColor,
                  color: '#fff',
                  ':hover': {
                    backgroundColor: project.portal.primaryColor,
                    color: '#fff',
                  },
                  mb: 1,
                  fontFamily: 'Abadi-Standard !important',
                }}
                onClick={() => {
                  if (user) {
                    navigate(`/dashboard/${urlName(project.name)}/${info.getValue()._id}/create-worksheets`);
                  } else {
                    navigate(`/${urlName(project.developerName)}/${urlName(project.name)}/public/${info.getValue()._id}/create-worksheets`);
                  }
                }}
              >
                Submit Worksheet
              </Button>
            </>
          );
        },
      },
      {
        header: 'Level',
        accessorFn: (rowData: any) => rowData.level,
      },
      {
        header: 'Type',
        accessorFn: (rowData: any) => rowData.unitType,
      },
      {
        header: 'Model',
        accessorFn: (rowData: any) => rowData.modelType,
      },
      {
        header: 'Interior Size',
        accessorFn: (rowData: any) => rowData.size,
      },
      {
        header: 'Outdoor Size',
        accessorFn: (rowData: any) => (rowData.outdoorSize ? rowData.outdoorSize : '-'),
      },
      {
        header: 'View',
        accessorFn: (rowData: any) => rowData.exposure,
      },
      {
        header: 'Bathrooms',
        accessorFn: (rowData: any) => rowData.bathroom,
      },
    ];

    if (units.length && units[0].tier) {
      standardColumns.push({
        accessorKey: 'Collection',
        accessorFn: (row: any) => row.tier,
        cell: (info: any) => info.getValue(),
        header: (rowData: any) => {
          return <strong>{rowData.column.id}</strong>;
        },
      });
    }

    if (units.length && units[0].rental) {
      standardColumns.push({
        accessorKey: 'Rental',
        accessorFn: (row: any) => row.rental,
        cell: (info: any) => <Box>{numToCurrency.format(info.getValue())}</Box>,
        header: (rowData: any) => {
          return <strong>{rowData.column.id}</strong>;
        },
      });
    }

    if (project.portal.showCustom) {
      standardColumns.push({
        accessorKey: 'Misc',
        accessorFn: (row: any) => row.custom,
        cell: (info: any) => info.getValue(),
        header: (rowData: any) => {
          return <strong>{rowData.column.id}</strong>;
        },
      });
    }

    return standardColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  return (
    <>
      <Box
        sx={{
          '@media (max-width: 768px)': {
            display: 'none',
          },
          display: 'block',
        }}
      >
        <Fade in={true} timeout={2000}>
          <Box>
            <Paper elevation={24}>
              <StandardTable
                loading={loading}
                columns={columns}
                data={units}
                download={download}
                color={project.portal.primaryColor}
                maxHeight="calc(100vh - 32px)"
              />
            </Paper>
          </Box>
        </Fade>
      </Box>
      <Box
        sx={{
          '@media (max-width: 768px)': {
            display: 'block',
            mt: 5,
          },
          display: 'none',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            backgroundColor: project.portal.primaryColor === '#000000' ? '#fff' : project.portal.primaryColor,
            color: project.portal.primaryColor === '#000000' ? '#000' : '#fff',
            p: 2,
          }}
        >
          Hotlist
        </Typography>
        {units.map((hotlist: any, index: number) => {
          return (
            <Paper sx={{ p: 2, mb: 2 }} elevation={24} key={index}>
              <Box sx={{ p: 1, textAlign: 'center', position: 'relative' }}>
                <Box sx={{ alignSelf: 'center' }}>
                  <strong>Suite {hotlist.suite}</strong>
                </Box>
                <ArticleIcon
                  sx={{ alignSelf: 'center', position: 'absolute', right: '7px', top: '7px' }}
                  onClick={() =>
                    user
                      ? navigate(`/dashboard/${urlName(project.name)}/${hotlist._id}/create-worksheets`)
                      : navigate(`/${urlName(project.developerName)}/${urlName(project.name)}/public/${hotlist._id}/create-worksheets`)
                  }
                />
              </Box>
              <Divider />
              <Flex>
                <Box sx={{ width: '100%', fontSize: '14px', borderLeft: '1px solid rgba(0, 0, 0, 0.12)', borderLeftWidth: 'thin' }}>
                  <Box sx={{ pt: 0.5, pl: 1 }}>Price: {numToCurrency.format(hotlist.basePrice)}</Box>
                  <Divider />
                  <Box sx={{ pt: 0.5, pl: 1 }}>Unit Type: {hotlist.unitType}</Box>
                  <Divider />
                  <Box sx={{ pt: 0.5, pl: 1 }}>Model Type: {hotlist.modelType}</Box>
                  <Divider />
                  <Box sx={{ pt: 0.5, pl: 1 }}>Size: {hotlist.size}</Box>
                  <Divider />
                  <Box sx={{ pt: 0.5, pl: 1 }}>Outdoor Size: {hotlist.outdoorSize}</Box>
                  <Divider />
                  <Box sx={{ pt: 0.5, pl: 1 }}>Exposure: {hotlist.exposure}</Box>
                  <Divider />
                  <Box sx={{ pt: 0.5, pl: 1 }}>Bathroom: {hotlist.bathroom}</Box>
                  <Divider />
                  {hotlist.rental ? (
                    <>
                      <Box sx={{ pt: 0.5, pl: 1 }}>Rental Guarantee: {hotlist.rental}</Box>
                      <Divider />
                    </>
                  ) : null}
                  {hotlist.custom && project.portal.showCustom ? <Box sx={{ pt: 0.5, pl: 1 }}>Custom: {hotlist.custom}</Box> : null}
                </Box>
              </Flex>
            </Paper>
          );
        })}
      </Box>
    </>
  );
};

interface ChildProps {
  user: IUser;
  project: IProject;
  logInteraction: any;
}

const HOTLIST = gql`
  query hotlist($user: MongoID, $project: MongoID!, $projects: [MongoID]) {
    hotlist(user: $user, project: $project, projects: $projects) {
      _id
      suite
      unit
      level
      unitType
      modelType
      size
      outdoorSize
      outdoorType
      bathroom
      exposure
      tier
      basePrice
      marketingFloorPlan
      custom
      rental
    }
  }
`;

export default NewHotlist;
