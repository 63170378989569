import { gql, useMutation } from '@apollo/client';
import FilePreviewCard from './FilePreviewCard';
import { useDropzone } from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import { IMedia } from '../../types/worksheet';
import { FilePreviewContainer, FileStatusContainer, DropzoneContainer } from '../../utils/CommonStyles';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const LicenceUpload = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { purchaserId, identification, index, handleRemoveId } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isDragAccept, isDragReject, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    }
  });

  const [deleteId] = useMutation(DELETEID, {
    onCompleted: () => {
      storeDispatch(showSuccessSnackbar('Document has been deleted!'));
      // setFileImage(null);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (event: any) => {
    handleRemoveId(event, index);
    if (!purchaserId) return;
    deleteId({
      variables: {
        _id: purchaserId,
        documentId: identification._id,
        deleteFile: true,
        type: 'identification',
      },
    });
    // setFileImage(null);
  };

  //if file is uploaded there will be a preview with delete button and if not then the default upload one
  const dropzoneOutput =
    <FilePreviewContainer>
      <FilePreviewCard zoom={true} file={identification.getUrl ? identification.getUrl : identification.url} handleDelete={handleDelete} />
      {identification && identification.name !== '' && (
        <FileStatusContainer>
          <p>{identification.name}</p>
          <IconButton
            sx={{ margin: '0 !important', border: '0 !important', right: '0 !important', width: 'auto !important' }}
            onClick={handleDelete}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </FileStatusContainer>
      )}
    </FilePreviewContainer>

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <DropzoneContainer>{dropzoneOutput}</DropzoneContainer>
    </Grid>
  );
};

interface ChildProps {
  purchaserId?: string | undefined;
  index: number;
  identification: IMedia;
  handleEditId?: any;
  handleRemoveId: any;
}

const DELETEID = gql`
  mutation deletePurchaserId($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deletePurchaserId(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      identifications {
        _id
        name
        getUrl
        putUrl
      }
      getUrl
    }
  }
`;

export default LicenceUpload;
