import React, { useState } from 'react';
import {
  useMediaQuery,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Divider,
  Box,
  Typography,
  Button,
  Checkbox,
  Fade,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import VideocamIcon from '@mui/icons-material/Videocam';

// import styles

import { IProject } from '../../types/project';

// import plugins if you need
import { IMedia } from '../../types/portal';
import { FlexBetween } from '../../utils/CommonStyles';
import { shareDocuments } from '../../utils/Functions';

const Docs = (props: ChildProps) => {
  const { project, titles, documents } = props;
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const [selectedAnimation, setSelectedAnimation] = useState<any[]>([]);

  const is600width = useMediaQuery('(min-width:600px)');
  const icons = (document: IMedia) => {
    if (document.name.includes('.ppt')) {
      return <SlideshowIcon sx={{ color: 'orange' }} />;
    } else if (document.name.includes('.mp4')) {
      return <VideocamIcon sx={{ color: '#000' }} />;
    } else {
      return <PictureAsPdfIcon sx={{ color: 'red' }} />;
    }
  };

  return (
    <Fade in={true} timeout={2000}>
      <Box
        sx={{
          '@media (max-width: 768px)': {
            height: '100%',
            overflowY: 'hidden',
            mt: 10,
            color: '#fff',
            backgroundColor: project.portal.primaryColor,
          },
        }}
      >
        {titles.map((title: string, index: number) => {
          return (
            <React.Fragment key={index}>
              <FlexBetween>
                {documents[index].length ? (
                  <Typography
                    variant="h3"
                    color="#fff"
                    sx={{
                      '@media (max-width: 768px)': {
                        mt: 2,
                        color: '#fff',
                        px: 2,
                      },
                      fontFamily: 'Abadi-Bold !important',
                      mb: 2,
                      mt: index > 0 ? 2 : 0,
                    }}
                  >
                    {title}
                  </Typography>
                ) : null}
                {(documents[index].length && title === 'Documents' && selectedDocuments.length) ||
                (title === 'Animation' && selectedAnimation.length) ? (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: project.portal.primaryColor === '#000000' ? '#fff' : project.portal.primaryColor,
                      color: project.portal.primaryColor === '#000000' ? '#000' : '#fff',
                      ':hover': {
                        backgroundColor: project.portal.primaryColor,
                        color: '#fff',
                      },
                      mr: 1,
                      mb: 1,
                      alignSelf: 'center',
                      fontFamily: 'Abadi-Standard !important',
                    }}
                    onClick={() => {
                      if (title === 'Documents') {
                        shareDocuments(selectedDocuments);
                      } else if (title === 'Animation') {
                        shareDocuments(selectedAnimation);
                      }
                    }}
                  >
                    Share {title}
                  </Button>
                ) : null}
              </FlexBetween>
              {documents[index].map((pdf: IMedia, index: number) => {
                return (
                  <ListItem disablePadding key={index} sx={{ backgroundColor: '#fff', border: `1px solid ${project.portal.primaryColor}` }}>
                    <Checkbox
                      sx={{
                        color: `${project.portal.primaryColor} !important`,
                      }}
                      checked={title === 'Documents' ? selectedDocuments.indexOf(pdf) > -1 : selectedAnimation.indexOf(pdf) > -1}
                      onClick={(e) => {
                        if (title === 'Documents') {
                          if (selectedDocuments.indexOf(pdf) > -1) {
                            setSelectedDocuments(selectedDocuments.filter((document: IMedia) => document._id !== pdf._id));
                          } else {
                            setSelectedDocuments((selectedDocuments) => [...selectedDocuments, pdf]);
                          }
                        } else {
                          if (selectedAnimation.indexOf(pdf) > -1) {
                            setSelectedAnimation(selectedAnimation.filter((document: IMedia) => document._id !== pdf._id));
                          } else {
                            setSelectedAnimation((selectedAnimation) => [...selectedAnimation, pdf]);
                          }
                        }
                      }}
                    />
                    <Link
                      sx={{ width: '100%' }}
                      color="inherit"
                      underline="none"
                      download
                      href={pdf.getUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemButton>
                        <ListItemIcon>{icons(pdf)}</ListItemIcon>
                        <ListItemText
                          sx={{
                            '@media (max-width: 768px)': {
                              color: '#000',
                            },
                          }}
                        >
                          {is600width ? pdf.name : pdf.name.length > 20 ? pdf.name.slice(0, 50) + '...' : pdf.name}
                        </ListItemText>
                      </ListItemButton>
                      <Divider />
                    </Link>
                  </ListItem>
                );
              })}
            </React.Fragment>
          );
        })}
      </Box>
    </Fade>
  );
};

interface ChildProps {
  project: IProject;
  titles: string[];
  documents: any[];
}

export default Docs;
