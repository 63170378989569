import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MenuItem, TextField, Button, Grid, ImageListItem, Box } from '@mui/material';
import { theme } from '../theme/theme';

export const xsWidth = '480px';
export const smWidth = '768px';
export const mdWidth = '992px';
export const lgWidth = '1200px';

export const Container = styled.div`
  margin-top: 64px;
  min-height: 100vh;
  padding: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${theme.palette.primary.main};
`;

export const LogoImgContainer = styled.div`
  height: 20%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%;
  width: 60%;
  margin-bottom: 5%;
  @media (max-width: 600px) {
    width: 80%;
    height: 20%;
  }
`;

export const ResetPasswordContainer = styled.div`
  position: absolute;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;

  width: 50%;
  height: 80%;

  min-height: 350px;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;

    justify-content: center;
  }

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#002044',
});

export const StyledMenuItem = styled(MenuItem)({
  color: theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  ':hover .MuiListItemIcon-root': {
    color: '#fff',
  },
});

export const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& label': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid black',
    },
    '&:hover fieldset': {
      borderColor: '#001630',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#001630',
    },
  },
});

export const InvertedStyledTextField = styled(TextField)({
  width: '100%',
  input: {
    color: 'white',
  },
  '& .MuiFormHelperText-root': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
    },
    '&:hover fieldset': {
      borderColor: '#F7E7CE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F7E7CE',
    },
  },
});

export const Flex = styled(Box)`
  display: flex;
`;

export const FlexEnd = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const FlexBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
  "@media (max-width:600px)": {
    flex-direction: column;
  }
`;

export const InvertedButton = styled(Button)({
  color: `${theme.palette.primary.main} !important`,
  backgroundColor: 'white !important',
  '&:hover': {},
  marginTop: '20px !important',
});

export const ErrorButton = styled(Button)({
  color: `${theme.palette.error.main} !important`,
  borderColor: `${theme.palette.error.main} !important`,
  '&:hover': {
    color: '#fff !important',
    backgroundColor: `${theme.palette.error.main} !important`,
  },
});

export const OutlinedButton = styled(Button)({
  color: `${theme.palette.primary.main} !important`,
  borderColor: `${theme.palette.primary.main} !important`,
  '&:hover': {
    color: '#fff !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
});

export const GreenButton = styled(Button)({
  '&.MuiButton-root': {
    backgroundColor: `${theme.palette.success.main} `,
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  marginLeft: '10px',
  '@media (max-width: 480px)': {
    width: '100%',
  },
});

export const StyledSubheading = styled.div<{ size?: string }>`
  font-size: ${(p) => (p.size === 'small' ? '1rem' : '1.5rem')};
  text-transform: ${(p) => (p.size === 'small' ? 'uppercase' : 'capitalize')};
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: ${(p) => (p.size === 'small' ? '1rem' : '1.5rem')};
  }
  @media (max-width: ${xsWidth}) {
    width: 100%;
  }
`;

export const WorksheetInputContainer = styled.div`
  width: 70%;
  @media (max-width: ${smWidth}) {
    width: 100%;
    margin-left: -10px;
  }
  @media (max-width: ${xsWidth}) {
    width: 100%;
    margin-left: -10px;
  }
`;

export const WorksheetTextField = styled(TextField)({
  width: '100%',
  //iffy way to hide the helper text when the field is empty
  '.MuiFormHelperText-root': {
    display: 'none',
  },
  '.MuiFormHelperText-root.Mui-error': {
    display: 'block',
  },
  '@media (max-width: 480px)': {
    width: '100%',
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  }
});

export const RequiredWorksheetTextField = styled(TextField)({
  width: '100%',
  '.MuiFormHelperText-root': { display: 'none' },
  '.MuiFormHelperText-root.Mui-error': { display: 'block' },
  '@media (max-width: 480px)': { width: '100%' },
  '& .MuiInput-underline:after': { borderBottomColor: 'green' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { border: '1px solid red' },
    '&:hover fieldset': { borderColor: 'green' },
    '&.Mui-focused fieldset': { borderColor: 'green' },
    '&:empty fieldset': { borderColor: 'green' },
  },
});
export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
  @media (max-width: ${smWidth}) {
    font-size: 1.5rem;
  }
`;

export const Heading = styled(Grid)`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const FilePreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #c4c4c4;
`;

export const FileStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #c4c4c4;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const DealsPageLine = styled.hr`
  border-top: 5px solid #002044;
`;

// Project Portal

export const RecButton = styled(Button)<{ secondarycolor?: string }>`
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  width: 200px;
  height: 35px;
  border: 1px solid ${(props) => (props.secondarycolor ? `${props.secondarycolor}` : '#000')};
  border-radius: 0px;
  color: ${(props) => (props.secondarycolor ? `${props.secondarycolor} !important` : '#000')};
  margin-right: 10px;
  min-width: 50px !important;
  @media (max-width: 600px) {
    width: auto;
  }
  :hover {
    background-color: transparent !important;
  }
`;

export const SubheadingContainer = styled.div<{ primaryColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
  background-color: ${(props) => (props.primaryColor ? props.primaryColor : '#e8ddb2')};
`;

export const ProjectInfoH2 = styled.h2<{ secondaryColor?: string }>`
  font-size: 42px;
  padding-left: 24px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : '#000')};
  @media (max-width: 400px) {
    font-size: 28px;
  }
  @media (max-width: 320px) {
    font-size: 20px;
  }
`;

export const ImageItem = styled(ImageListItem)({
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  '& .MuiImageListItem-img': {
    height: '164px !important',
  },
  width: '100%',
  cursor: 'zoom-in',
});

export const RenderingsContainer = styled.div`
  height: 100%;
  position: relative;
  padding: 0 24px;
`;