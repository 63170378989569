import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button } from '@mui/material';
import styled from 'styled-components';
import { Title } from '../../utils/CommonStyles';
import { IAppointment, IAppointmentData } from '../../types/appointment';
import CustomDialog from '../common/CustomDialog';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';

interface Column {
  id: 'date' | 'time' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'confirmed';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'date', label: 'Date', minWidth: 170 },
  { id: 'time', label: 'Time', minWidth: 100 },
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'confirmed',
    label: 'Confirmed',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
];

interface Data {
  date: string;
  time: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  confirmed: string;
}

function createData(
  date: string,
  time: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  confirmed: string
): Data {
  return { date, time, firstName, lastName, email, phoneNumber, confirmed };
}

function Appointments() {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [index, setIndex] = useState(0);
  const [dates, setDates] = useState<Date[] | null>(null);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useQuery<IAppointmentData>(GETAPPOINTMENTS, {
    variables: { filter: { user: user._id } },
    onCompleted: (data) => {
      const appointments = data.appointmentMany.map((appointment: IAppointment) => ({
        _id: appointment._id,
        date: appointment.date,
        purchaserInfo: appointment.purchaserInfo,
        firstName: appointment.purchaserInfo.firstName,
        lastName: appointment.purchaserInfo.lastName,
        confirmed: appointment.confirmed,
        email: appointment.purchaserInfo.email,
      }));

      setAppointments(appointments);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const datesArray: Date[] | null | undefined = [];

  appointments &&
    appointments.forEach((appointment: IAppointment, i: number) => {
      if (appointment.date) {
        datesArray.push(new Date(appointment.date));
      }
    });

  const rows = appointments
    ? appointments.map((appointment: IAppointment, index: number) => {
        return createData(
          `${
            datesArray[index]?.toLocaleString('default', { month: 'long' }) +
            ' ' +
            datesArray[index]?.getDate() +
            ', ' +
            datesArray[index]?.getFullYear()
          }`,
          `${datesArray[index]?.toTimeString()}`,
          `${appointment.purchaserInfo.firstName}`,
          `${appointment.purchaserInfo.lastName}`,
          `${appointment.purchaserInfo.email}`,
          `${appointment.purchaserInfo.primaryPhone}`,
          appointment.confirmed ? `yes` : 'no'
        );
      })
    : [];

  const [deleteAppointment] = useMutation(DELETEAPPOINTMENT, {
    onCompleted: () => {
      storeDispatch(showSuccessSnackbar('Appointment has been deleted!'))
      window.location.reload();
      if (datesArray) {
        setDates(datesArray);
      }
    },
    onError: (error) => {
      console.log(error, 'err');
    },
  });

  const handleCloseSuccess = () => {
    if (appointments) {
      deleteAppointment({ variables: { _id: appointments[index]._id } });
    }
  };

  const handleDialog = (index: number) => {
    setDialogOpen(true);
    setIndex(index);
  };

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => handleCloseSuccess()}
        open={dialogOpen}
        removeButton={'No'}
        successButton={'Yes'}
        dialogContent={'Are you sure you want to delete this appointment?'}
        dialogTitle={'Delete Appointment'}
      />
      <Title>Appointments</Title>
      {appointments.length > 0 ?
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && (
                  <>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.date}>
                          {columns.map((column, index) => {
                            const value = row[column.id];

                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}

                          <NavButton
                            style={{ marginLeft: '30px', marginTop: '5px' }}
                            onClick={() => handleDialog(index)}
                            variant="contained"
                            color="warning"
                            offset={5}
                          >
                            Delete
                          </NavButton>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        : <div><em>You currently have no appointments</em></div>
      }
    </Box>
  );
}

/* Styled Components */
const NavButton = styled(Button)<{ offset: number }>`
  :disabled:hover {
    background-color: transparent !important;
  }
  bottom: 0px;
`;

const GETAPPOINTMENTS = gql`
  query appointmentMany($filter: FilterFindManyAppointmentInput!) {
    appointmentMany(filter: $filter) {
      _id
      purchaserInfo {
        firstName
        lastName
        email
        primaryPhone
      }
      confirmed
      date
    }
  }
`;

const DELETEAPPOINTMENT = gql`
  mutation appointmentRemoveById($_id: MongoID!) {
    appointmentRemoveById(_id: $_id) {
      recordId
    }
  }
`;

export default Appointments;
