import { useState, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../../utils/WithRouter';
import { TextField, Button, InputAdornment } from '@mui/material';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ITokens } from '../../types/user';
import styled from 'styled-components';
import { Background, ResetPasswordContainer, LogoImgContainer, ResetPasswordForm } from '../../utils/CommonStyles';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { selectUser, setUser } from '../../features/auth/authSlice';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { validatePassword } from '../../utils/Functions';

const ResetPassword = (props: any) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [confirmError, setConfirmError] = useState<string>('');
  const { history } = props;
  const { token } = useParams();
  const navigate = useNavigate();

  // Query to get the user by passing in the token from the URL

  useQuery<ITokens>(RESETTOKEN, {
    variables: { resetToken: token },
    onCompleted: (data) => {
      storeDispatch(setUser(data.checkResetToken));
    },
    onError: (err) => {
      if (err) {
        storeDispatch(showErrorSnackbar('Link has been expired!'));
        navigate('/');
      }
      console.log(err, 'err');
    },
  });

  // Mutation to change password

  const [resetPassword] = useMutation(SETNEWPASSWORD, {
    variables: { token: token, id: user?._id, password: password },
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Password has been reset!'));
      navigate('/');
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Password Validation

  const handleValidation = (type: string) => {
    if (type === 'password') {
      if (!validatePassword(password)) {
        setError('Passwords needs to have minimum 8 characters, at least one numerical character and one special character!');
      } else {
        setError('');
      }
    }
    if (type === 'confirmPassword') {
      if (!validatePassword(confirmPassword)) {
        setConfirmError('Passwords needs to have minimum 8 characters, at least one numerical character and one special character!');
      } else {
        setConfirmError('');
      }
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (error || confirmError) {
      return;
    }
    if (password !== confirmPassword) {
      return storeDispatch(showErrorSnackbar('Passwords are not the same!'));
    }
    resetPassword();
  };

  return (
    <Background>
      <ResetPasswordContainer>
        <LogoImgContainer>
          <LogoImg src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png" />
        </LogoImgContainer>
        <Title>Reset Password</Title>
        <p>
          <em>Your password must be 8 characters long</em>
        </p>
        <ResetPasswordForm onSubmit={handleSubmit} noValidate autoComplete="off">
          <TextField
            required
            name="password"
            label="New password"
            placeholder="New password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onBlur={() => handleValidation('password')}
            error={error ? true : false}
            helperText={error ? error : ''}
            inputProps={{ minLength: 8 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                  ) : (
                    <VisibilityOffIcon sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            sx={{ marginBottom: '20px' }}
          />
          <TextField
            required
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            onBlur={() => handleValidation('confirmPassword')}
            error={confirmError ? true : false}
            helperText={confirmError ? confirmError : ''}
            inputProps={{ minLength: 8 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showConfirmPassword ? (
                    <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                  ) : (
                    <VisibilityOffIcon sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
            sx={{ marginBottom: '20px' }}
          />
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </ResetPasswordForm>
      </ResetPasswordContainer>
    </Background>
  );
};

const LogoImg = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

const Title = styled.p`
  font-size: 24px;
  margin-bottom: 0;
`;

const RESETTOKEN = gql`
  query checkResetToken($resetToken: String!) {
    checkResetToken(resetToken: $resetToken) {
      _id
      resetToken
      expireToken
    }
  }
`;

const SETNEWPASSWORD = gql`
  mutation resetPassword($token: String!, $id: String!, $password: String!) {
    resetPassword(token: $token, id: $id, password: $password)
  }
`;

export default withRouter(ResetPassword);
