// prettier-ignore
const env = {
  basePath: process.env.REACT_APP_ENV === 'production' ? 'https://api.rdsre.ca'
          : process.env.REACT_APP_ENV === 'staging' ? ''
          : 'http://localhost:8080',
  socketPath: process.env.REACT_APP_ENV === 'production' ? 'wss://api.rdsre.ca'
  : process.env.REACT_APP_ENV === 'staging' ? ''
  : 'ws://localhost:8080'
}

export default env;
