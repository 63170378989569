import React from 'react';
import styled from 'styled-components';
import { smWidth, WorksheetTextField, DealsPageLine, Heading } from '../../utils/CommonStyles';
import { Grid } from '@mui/material';
import { ISolicitor } from '../../types/deal';

const SolicitorInfo = (props: ChildProps) => {
  const { solicitor } = props;

  return (
    <div>
      <DealsPageLine />
      <h1 style={{textAlign: "center"}}>Solicitor</h1>
      <SolicitorInfoContainer container spacing={2}>
        <Heading item lg={3} md={4} sm={12} xs={12}>Solicitor Information</Heading>
        {solicitor && (
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor" label="Solicitor" required value={solicitor?.solicitor} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-firm" label="Firm" required value={solicitor?.firm} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-phone" label="Phone" required value={solicitor?.primaryPhone} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-email" label="Email" required value={solicitor?.email} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-streetAddress" label="Street Address" required value={solicitor?.streetAddress} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-city" label="City" required value={solicitor?.city} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-province" label="Province" required value={solicitor?.province} disabled />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField id="solicitor-postalCode" label="Postal Code" required value={solicitor?.postalCode} disabled />
              </Grid>
            </Grid>
          </Grid>
        )}
      </SolicitorInfoContainer>
    </div>
  );
};

const SolicitorInfoContainer = styled(Grid)`
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

interface ChildProps {
  solicitor: ISolicitor;
}

export default SolicitorInfo;
