import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Stack, Chip, Grid } from '@mui/material';
import styled from 'styled-components';
import FilePreviewCard from '../worksheet/FilePreviewCard';
import { FilePreviewContainer } from '../../utils/CommonStyles'
import { IDepositImage, IDeposits } from '../../types/project';
import DepositStructureRow from './DepositStructureRow';
import { smWidth, DealsPageLine } from '../../utils/CommonStyles';

const DepositStructure = (props: ChildProps) => {
  const { deposits, depositImages } = props;

  const STATUS_COLOURS: IStatusColours = {
    distributed: '#9fa8da',
    received: '#c5e1a5',
    nsf: '#ef9a9a',
    beingReplaced: '#fff59d',
    cleared: '#ff8c00',
  };

  return (
    <DepositContainer>
        <DealsPageLine />
        <h1 style={{textAlign: "center"}}>Deposits</h1>
        <DepositHeader>
        <Stack direction="row" spacing={1}>
          <Chip label="Cleared" sx={{ backgroundColor: STATUS_COLOURS['cleared'] }} variant="filled" />
          <Chip label="Distributed" sx={{ backgroundColor: STATUS_COLOURS['distributed'] }} variant="filled" />
          <Chip label="Received" sx={{ backgroundColor: STATUS_COLOURS['received'] }} variant="filled" />
          <Chip label="NSF" sx={{ backgroundColor: STATUS_COLOURS['nsf'] }} variant="filled" />
          <Chip label="Being Replaced" sx={{ backgroundColor: STATUS_COLOURS['beingReplaced'] }} variant="filled" />
        </Stack>
      </DepositHeader>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Deposit Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deposits.map((deposit: IDeposits, i: number) => (
              <DepositStructureRow key={i} deposit={deposit} statusColours={STATUS_COLOURS} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        {depositImages.map((image: IDepositImage, i: number) => (
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <a href={image.getUrl} target="_blank" rel="noreferrer">
              <FilePreviewContainer>
                <FilePreviewCard file={image.getUrl} />
              </FilePreviewContainer>
            </a>
          </Grid>
        ))}
      </Grid>
    </DepositContainer>
  );
};

const DepositContainer = styled.div`
  margin-bottom: 20px;
margin-top: 50px;
`;

const DepositHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

interface ChildProps {
  deposits: IDeposits[];
  depositImages: IDepositImage[];
}

export interface IStatusColours {
  [key: string]: string;
}

export default DepositStructure;
