import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import Footer from '../menus/Footer';
import { urlName } from '../../utils/Functions';

const ScheduleOutlet = () => {
  const { shortid } = useParams();
  const { project } = useSelector(selectProject);
  const navigate = useNavigate();

  const { loading } = useQuery(GETSCHEDULEONE, {
    skip: !shortid,
    variables: { filter: { shortUrl: shortid } },
    onCompleted: (data) => {
      if (data.scheduleOne) {
        if (process.env.REACT_APP_ENV === 'local') {
          navigate(
            `/${urlName(data.scheduleOne.project.developerName)}/${urlName(data.scheduleOne.project.name)}/public/book-an-appointment/${
              data.scheduleOne._id
            }`
          );
        } else {
          navigate(
            `/${urlName(data.scheduleOne.project.developerName)}/${urlName(data.scheduleOne.project.name)}/public/book-an-appointment/${
              data.scheduleOne._id
            }`
          );
        }
      } else {
        navigate(`/`);
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  return loading ? null : project ? (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 8 }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  ) : null;
};

const GETSCHEDULEONE = gql`
  query scheduleOne($filter: FilterFindOneScheduleInput) {
    scheduleOne(filter: $filter) {
      project {
        name
        developerName
      }
      _id
    }
  }
`;

export default ScheduleOutlet;
