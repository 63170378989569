import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Logo from '../../assets/rds-logo-transparent.png';
import { withRouter } from '../../utils/WithRouter';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 'auto' }}>
      <Box
        display="flex"
        flexDirection="row"
        px={{ xs: 3, sm: 5 }}
        py={{ xs: 3, sm: 5 }}
        /* marginTop={5} */ bgcolor="#002044"
        color="white"
      >
        <Container maxWidth="lg">
          <Grid display="flex" direction="row" alignItems="center" justifyContent="center">
            <Box>
              <img src={Logo} width={80} height={80} alt="RDS" />
            </Box>

            <Grid display="flex" flexDirection="column" marginLeft="20px">
              <Box textAlign="center" pt={{ xs: 0, sm: 0 }} pb={{ xs: 0, sm: 0 }}>
                <span>&reg; 2022 </span>
                Residential Development Services
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                textAlign="left"
                pt={{ xs: 2, sm: 2 }}
                pb={{ xs: 0, sm: 0 }}
              >
                <a style={{ color: 'white', textDecoration: 'none' }} href="mailto:info@rdsre.ca">
                  info@rdsre.ca
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default withRouter(Footer);
