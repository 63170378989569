import { useState, useEffect } from 'react';
import { withRouter } from '../../utils/WithRouter';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import ResetPasswordScreen from './ResetPasswordScreen';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import bg from '../../assets/login_bg.png';

const LoginScreen = (props: any) => {
  const { history } = props;
  const [selector, setSelector] = useState<string>('login');
  const [toggle, setToggle] = useState(false);

  const transitions = useTransition(toggle, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (selector === 'signup') setToggle(false);
    else if (selector === 'login') setToggle(true);
  }, [selector]);

  return (
    <Background style={{ backgroundImage: `url(${bg})` }}>
      {(() => {
        if (selector === 'login' || selector === 'signup') {
          return transitions(({ opacity }, item) =>
            item ? (
              <animated.div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                }}
              >
                <LoginForm history={history} setSelector={setSelector} />;
              </animated.div>
            ) : (
              <animated.div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
                }}
              >
                <SignupForm setSelector={setSelector} history={history} />;
              </animated.div>
            )
          );
        } else if (selector === 'resetPassword') {
          return <ResetPasswordScreen setSelector={setSelector} forgotPassword />
        }
      })()}
    </Background>
  );
}

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 850px) {
    background-image: none !important;
  }
`;

export default withRouter(LoginScreen);
