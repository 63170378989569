import { useContext, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Button, Divider, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import ProfilePersonalInfo from './ProfilePersonalInfo';
import ProfileBrokerageInfo from './ProfileBrokerageInfo';
import ProfileResetPassword from './ProfileResetPassword';
import { IGetRealtor, IRealtor } from '../../types/realtor';
import { Title } from '../../utils/CommonStyles';
import { selectUser } from '../../features/auth/authSlice';
import { setUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const Profile = () => {
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [realtor, setRealtor] = useState<IRealtor>();

  useQuery<IGetRealtor>(GETREALTOR, {
    variables: user.realtor,
    onCompleted: (data) => {
      setRealtor(data.realtorById);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUserInfo] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Updating User Info'));
    },
  });

  const [createRealtor] = useMutation(CREATEREALTOR, {
    variables: {
      record: {
        email: realtor?.email ? realtor.email : ``,
        firstName: realtor?.firstName ? realtor.firstName : ``,
        lastName: realtor?.lastName ? realtor.lastName : ``,
        brokerage: realtor?.brokerage ? realtor.brokerage : ``,
        streetAddress: realtor?.streetAddress ? realtor.streetAddress : ``,
        city: realtor?.city ? realtor.city : ``,
        province: realtor?.province ? realtor.province : ``,
        country: realtor?.country ? realtor.country : ``,
        postalCode: realtor?.postalCode ? realtor.postalCode : ``,
        brokerageFax: realtor?.brokerageFax ? realtor.brokerageFax : ``,
        brokeragePhone: realtor?.brokeragePhone ? realtor.brokeragePhone : ``,
        directPhone: realtor?.directPhone ? realtor.directPhone : ``,
        recoNumber: realtor?.recoNumber ? realtor.recoNumber : ``,
      },
    },
    onCompleted: (data) => {
      storeDispatch(setUser({ ...user, realtor: data.realtorCreateOne.record._id }));
      updateUserInfo({ variables: { _id: user._id, record: { realtor: data.realtorCreateOne.record._id } } });
    },
    onError: (err) => {
      if (err.message.includes('Realtor validation failed')) {
        storeDispatch(showErrorSnackbar('Please fill in required fields'));
      }
    },
  });

  const [updateRealtorProfile] = useMutation(UPDATEREALTOR, {
    variables: {
      _id: user?.realtor._id,
      record: {
        firstName: realtor?.firstName ? realtor.firstName : ``,
        lastName: realtor?.lastName ? realtor.lastName : ``,
        brokerage: realtor?.brokerage ? realtor.brokerage : ``,
        streetAddress: realtor?.streetAddress ? realtor.streetAddress : ``,
        city: realtor?.city ? realtor.city : ``,
        province: realtor?.province ? realtor.province : ``,
        country: realtor?.country ? realtor.country : ``,
        postalCode: realtor?.postalCode ? realtor.postalCode : ``,
        brokerageFax: realtor?.brokerageFax ? realtor.brokerageFax : ``,
        brokeragePhone: realtor?.brokeragePhone ? realtor.brokeragePhone : ``,
        directPhone: realtor?.directPhone ? realtor.directPhone : ``,
        recoNumber: realtor?.recoNumber ? realtor.recoNumber : ``,
      },
    },
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Profile Saved'));
    },
    onError: (err) => {
      if (err.message.includes('Realtor validation failed')) {
        storeDispatch(showErrorSnackbar('Please fill in required fields'));
      }
    },
  });

  const handleTextInput = (e: any) => {
    if (realtor && e && e.target) {
      if (e.target.name) {
        setRealtor({
          ...realtor,
          [e.target.name]: e.target.value,
        });
      } else {
        setRealtor({
          ...realtor,
          [e.target.id]: e.target.value,
        });
      }
    }
  };

  const handleDetails = (city: string, province: string, postalCode: string, country: string, address: string) => {
    if (realtor) {
      setRealtor({
        ...realtor,
        streetAddress: address,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
      });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Title>Profile</Title>
      {user && realtor && (
        <>
          <ProfilePersonalInfo fields={realtor} handleTextInput={handleTextInput} />
          <ProfileBrokerageInfo
            fields={realtor}
            handleDetails={handleDetails}
            handleTextInput={handleTextInput}
            disableAutoComplete={false}
          />
        </>
      )}

      <SaveContainer>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            if (user.realtor._id === null) {
              createRealtor();
            } else {
              updateRealtorProfile();
            }
          }}
        >
          Save
        </Button>
      </SaveContainer>
      <Divider sx={{ marginTop: '50px' }} />
      <ProfileResetPassword updateUserInfo={updateUserInfo} />
    </Box>
  );
};

const SaveContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
`;

const UPDATEUSER = gql`
  mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
    userUpdateById(_id: $_id, record: $record) {
      record {
        firstName
      }
    }
  }
`;

const UPDATEREALTOR = gql`
  mutation realtorUpdateById($_id: MongoID!, $record: UpdateByIdRealtorInput!) {
    realtorUpdateById(_id: $_id, record: $record) {
      record {
        firstName
      }
    }
  }
`;

const CREATEREALTOR = gql`
  mutation realtorCreateOne($record: CreateOneRealtorInput!) {
    realtorCreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

const GETREALTOR = gql`
  query realtorById($_id: MongoID!) {
    realtorById(_id: $_id) {
      _id
      firstName
      lastName
      email
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
      recoNumber
    }
  }
`;

export default Profile;
