import React from 'react';
import { withRouter } from '../../utils/WithRouter';
import styled from 'styled-components';
import { smWidth, WorksheetTextField, InvertedStyledTextField, Heading } from '../../utils/CommonStyles';
import { IRealtor } from '../../types/realtor';
import { Grid } from '@mui/material';
import { sanitizePhone, validatePhone } from '../../utils/Validations';
import { formatPhoneNumber } from '../../utils/Functions';
import GooglePlaces from '../common/GooglePlaces';

const ProfileBrokerageInfo = (props: ChildProps) => {
  const { disabled, fields, handleTextInput, handleDetails, handleText, invert, disableAutoComplete } = props;
  const { brokerage, brokeragePhone, brokerageFax, streetAddress, postalCode, city, province, country } = fields;

  let gridColumn: any = invert ? 6 : 4;
  let gridContainer: any = invert ? 12 : 9;
  
  let TextComponent = invert ? InvertedStyledTextField : WorksheetTextField

  return (
    <div>
      <BrokerageInfoContainer container spacing={2}>
        {!invert ?
          <Heading item lg={3} md={gridColumn} sm={12} xs={12}>Brokerage Information</Heading>
        : null
        }
        <Grid item lg={gridContainer} md={gridContainer} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="brokerage-textarea"
                label="Brokerage Name"
                name="brokerage"
                required
                value={brokerage}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="brokeragePhone-textarea"
                label="Brokerage Phone Number (xxx-xxx-xxxx)"
                name="brokeragePhone"
                required
                error={!disabled && !validatePhone(brokeragePhone) && brokeragePhone !== ''}
                helperText={!disabled && !validatePhone(brokeragePhone) && brokeragePhone !== '' ? 'Please enter a valid phone number' : ''}
                value={formatPhoneNumber(brokeragePhone!)}
                onChange={(e) => {
                  if (handleText) handleText(e.target.name, sanitizePhone(e.target.value));
                }}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="brokerageFax-textarea"
                label="Brokerage Fax (xxx-xxx-xxxx)"
                name="brokerageFax"
                error={!disabled && !!brokerageFax && !validatePhone(brokerageFax) && brokerageFax !== ''}
                helperText={!disabled && !!brokerageFax && !validatePhone(brokerageFax) && brokerageFax !== '' ? 'Please enter a valid phone number' : ''}
                value={brokerageFax ? formatPhoneNumber(brokerageFax!) : ''}
                onChange={(e) => {
                  if (handleText) handleText(e.target.name, sanitizePhone(e.target.value));
                }}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              {disableAutoComplete ?
              <TextComponent
                id="street-textarea"
                label="Brokerage Street Address"
                name="streetAddress"
                required
                value={streetAddress}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
              : 
              <GooglePlaces disabled={disabled} name={'streetAddress'} handleDetails={handleDetails} handleTextInput={handleTextInput} address={streetAddress} />
              }
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="city-textarea"
                label="Brokerage City"
                name="city"
                required
                value={city}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="province-textarea"
                label="Brokerage Province"
                name="province"
                required
                value={province}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="postal-textarea"
                label="Brokerage Postal Code"
                name="postalCode"
                required
                value={postalCode}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="country-textarea"
                label="Brokerage Country"
                name="country"
                required
                value={country}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
          </Grid>
        </Grid>
      </BrokerageInfoContainer>
    </div>
  );
};

const BrokerageInfoContainer = styled(Grid)`
  display: flex;
  margin-top: 50px !important;
  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

interface ChildProps {
  disabled?: boolean;
  fields: IRealtor;
  handleTextInput?: (e: any) => void;
  handleDetails?: any;
  disableAutoComplete: boolean;
  invert?: boolean;
  handleText?: (name: string, value: string) => void;
}

export default withRouter(ProfileBrokerageInfo);
