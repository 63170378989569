import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Divider, useMediaQuery, Button, Grid } from '@mui/material';
import GooglePlaces from '../common/GooglePlaces';
import { WorksheetTextField, smWidth, xsWidth, Heading } from '../../utils/CommonStyles';
import { ICompletionStatus, IWorksheetPurchaserInfo } from '../../types/worksheet';
import { isAddressInfoFilled } from '../../utils/Validations';

const AddressDetails = (props: ChildProps) => {
  const { completionStatus, fields, handleTextInput, handleDetails, handleNext, disabled, style, asterisk } = props;
  const { streetAddress, city, province, postalCode, country } = fields;
  const isXS = useMediaQuery(`(max-width: ${xsWidth})`);

  const animationProps = useSpring({
    to: { opacity: completionStatus.personalInfo ? 1 : 0, y: completionStatus.personalInfo ? 0 : 100 },
    from: { opacity: 0, y: 100 },
    delay: 250,
  });

  const InputFields = (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <GooglePlaces
          disabled={disabled}
          name={'streetAddress'}
          style={style}
          asterisk={asterisk}
          handleDetails={handleDetails}
          handleTextInput={handleTextInput}
          address={streetAddress}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="city-textarea"
          label="City"
          name="city"
          sx={city === '' ? style : asterisk}
          value={city}
          onChange={handleTextInput}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="province-textarea"
          label="Province"
          name="province"
          sx={province === '' ? style : asterisk}
          value={province}
          onChange={handleTextInput}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="postal-textarea"
          label="Postal Code"
          name="postalCode"
          sx={postalCode === '' ? style : asterisk}
          value={postalCode}
          onChange={handleTextInput}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="country-textarea"
          label="Country"
          name="country"
          sx={country === '' ? style : asterisk}
          value={country}
          onChange={handleTextInput}
          disabled={disabled}
          required
        />
      </Grid>
    </Grid>
  );

  return (
    <div>
      {completionStatus.personalInfo && (
        <AddressDetailsContainer container spacing={2}>
          <Heading item lg={3} md={4} sm={12} xs={12}>
            <animated.div style={animationProps}>Address Details</animated.div>
          </Heading>

          <Grid item lg={9} md={8} sm={12} xs={12}>
            <animated.div style={animationProps}>{InputFields}</animated.div>
            {!completionStatus.addressDetails && completionStatus.personalInfo && (
              <animated.div style={animationProps}>
                <Button
                  disableElevation
                  disabled={!isAddressInfoFilled(fields)}
                  variant="contained"
                  name="addressDetails"
                  onClick={handleNext}
                  sx={{ marginTop: '10px' }}
                >
                  Next
                </Button>
              </animated.div>
            )}
          </Grid>
        </AddressDetailsContainer>
      )}
      {!isXS && completionStatus.addressDetails && <Divider sx={{ marginTop: '50px' }} />}
    </div>
  );
};

interface ChildProps {
  fields: IWorksheetPurchaserInfo;
  completionStatus: ICompletionStatus;
  handleTextInput: any;
  handleDetails: any;
  handleNext: any;
  disabled?: boolean;
  subheading?: string;
  style?: object;
  asterisk?: object;
}

const AddressDetailsContainer = styled(Grid)`
  display: flex;
  margin-top: 50px;
  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

export default AddressDetails;
