import { useState } from 'react';
import styled from 'styled-components';
import { Button, Link, Box } from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { withRouter } from '../../utils/WithRouter';
import { StyledTextField } from '../../utils/CommonStyles';
import { IUser } from '../../types/user';
import { IRealtor } from '../../types/realtor';
import ResetPasswordScreen from './ResetPasswordScreen';
import ProfileBrokerageInfo from '../profile/ProfileBrokerageInfo';
import ProfilePersonalInfo from '../profile/ProfilePersonalInfo';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const LoginPopup = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const navigate = useNavigate();
  const storeDispatch = useAppDispatch();
  const [selector, setSelector] = useState<string>('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [realtor, setRealtor] = useState<IRealtor>({
    email: '',
    firstName: '',
    lastName: '',
    brokerage: '',
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    brokeragePhone: '',
    brokerageFax: '',
    directPhone: '',
    recoNumber: '',
  });

  const [login] = useLazyQuery<ILogin>(LOGIN, {
    onCompleted: (data) => {
      if (data.userLogin) {
        window.location.reload();
      }
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [createUser] = useMutation(NEWUSER, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Account successfully created! Account needs to be verified to proceed.'));
      navigate('/thank-you', {
        state: {
          title: 'Thank you for signing up!',
          text: 'Your registration form is currently being reviewed by our team. You will receive a confirmation email once your account is ready.',
          link: '/',
        },
      });
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error creating account'));
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    login({ variables: { email: email.toLowerCase(), password: password } });
  };

  const handleCreate = () => {
    createUser({ variables: { realtor: realtor, project: project.project._id } });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleTextInput = (e: any) => {
    setRealtor({
      ...realtor,
      [e.target.name]: e.target.value,
    });
  };

  const handleText = (name: string, value: string) => {
    setRealtor({
      ...realtor,
      [name]: value,
    });
  };

  return (
    <div>
      <Login>
        <LogoImgContainer>
          <LogoImg src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png" />
        </LogoImgContainer>
        {selector === 'login' ? (
          <EmailPassword>
            <strong>If you are a Realtor, please log in to continue viewing this page.</strong>
            <EmailPasswordForm
              id="loginForm"
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(event);
              }}
            >
              <StyledTextField fullWidth label="Email" variant="outlined" required value={email} onChange={handleEmailChange} />
              <StyledTextField
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                required
                value={password}
                onChange={handlePasswordChange}
              />
            </EmailPasswordForm>
            <ForgotPassword>
              <Link
                component="button"
                underline="hover"
                variant="body2"
                onClick={() => {
                  setSelector('reset');
                }}
              >
                Forgot password?
              </Link>
            </ForgotPassword>

            <Button sx={{ marginTop: '10px' }} type="submit" variant="contained" id="logInBtn" form="loginForm" size="large">
              Log In
            </Button>
            <Box sx={{ marginTop: '10px' }}>
              <CreateAccountPrompt> Not registered yet? </CreateAccountPrompt>
              <Link
                component="button"
                underline="hover"
                variant="body2"
                onClick={() => {
                  setSelector('create');
                }}
              >
                <span style={{ fontWeight: 600 }}>Create an account</span>
              </Link>
            </Box>
          </EmailPassword>
        ) : selector === 'reset' ? (
          <ResetPasswordScreen setSelector={setSelector} forgotPassword />
        ) : (
          <Box sx={{ width: '70%', margin: '0 auto' }}>
            <SignupForm
              id="signUpForm"
              onSubmit={(event) => {
                event.preventDefault();
                handleCreate();
              }}
            >
              <Box>
                <ProfilePersonalInfo fields={realtor} handleTextInput={handleTextInput} invert={false} handleText={handleText} />
                <ProfileBrokerageInfo
                  fields={realtor}
                  handleTextInput={handleTextInput}
                  invert={false}
                  handleText={handleText}
                  disableAutoComplete={true}
                />
              </Box>
              <Button sx={{ marginTop: '20px' }} variant="contained" id="signUpBtn" type="submit" form="signUpForm" size="large">
                Sign Up
              </Button>
            </SignupForm>
            <div>
              <LoginPrompt> Have an account? </LoginPrompt>
              <Link
                component="button"
                style={{ margin: '10px 0' }}
                underline="hover"
                variant="body2"
                onClick={() => {
                  setSelector('login');
                }}
              >
                <span style={{ fontWeight: 600 }}>Log in</span>
              </Link>
            </div>
          </Box>
        )}
      </Login>
    </div>
  );
};

interface ChildProps {
  history: any;
  project: string;
}

const Login = styled.div`
  left: 0;
  right: 0;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 5%;

  @media (max-width: 850px) {
    width: 100% !important;
  }

  @media (max-height: 700px) {
    padding-top: 0;
  }
`;

const LoginPrompt = styled.p`
  display: inline;
  font-size: 14px;
`;

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EmailPassword = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 70%;

  max-height: 400px;

  @media (max-height: 600px) {
    height: 70%;
  }
`;

const EmailPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%;
  min-height: 130px;
  margin-top: 20px;
  @media (max-height: 600px) {
    min-height: 130px;
  }
`;

const ForgotPassword = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const LogoImgContainer = styled.div`
  height: 100px;
  width: 70%;
  margin: 10% 0 20px 0;

  display: flex;
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const CreateAccountPrompt = styled.p`
  display: inline;
  font-size: 14px;
`;

interface ILogin {
  userLogin: IUser;
}

const LOGIN = gql`
  query userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      email
      id
    }
  }
`;

const NEWUSER = gql`
  mutation createRealtorUser($realtor: NewUserRealtorInput!, $project: MongoID) {
    createRealtorUser(realtor: $realtor, project: $project) {
      firstName
      lastName
    }
  }
`;

export default withRouter(LoginPopup);
