import { subYears } from 'date-fns';
import { IPurchaserInfo, IWorksheetPurchaserInfo, ISigningOfficer, IWishlistChoices, IWorksheetRealtorInfo } from '../types/worksheet';

export function validateEmail(email: string) {
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}

export function legalDob(date: any, corp: boolean) {
  if (typeof date === 'string') date = new Date(date);

  if (!corp) {
    return date instanceof Date && date <= subYears(new Date(), 18) && date >= new Date(1900, 0, 1);
  } else return date instanceof Date && date <= new Date();
}

export function validateIdExpiry(date: any) {
  if (typeof date === 'string') date = new Date(date);
  return date instanceof Date && date >= new Date() && date <= new Date(2099, 11, 31);
}

export function validateText(name: string) {
  return /^[a-zA-Z]+$/.test(name);
}

export function validatePhone(phone: string) {
  return /^\d([0-9 -]{0,12}\d)?$/.test(phone);
}

export function validateNumber(num: string) {
  if (!num) {
    return;
  } else if (num.length === 0) {
    return true;
  } else {
    return /^[0-9]+$/.test(num);
  }
}

export function sanitizePhone(value: string) {
  let sanitizedPhone = '';
  for (let i = 0; i < value.length; ++i) {
    if (/^[0-9]|-/.test(value.charAt(i))) sanitizedPhone += value.charAt(i);
  }
  return sanitizedPhone;
}

export function sanitizeNumber(value: string) {
  let sanitizedNumber = '';
  for (let i = 0; i < value.length; ++i) {
    if (/^[0-9]/.test(value.charAt(i))) sanitizedNumber += value.charAt(i);
  }
  return sanitizedNumber ? parseInt(sanitizedNumber) : sanitizedNumber;
}

export function sanitizeSIN(value: string) {
  let sanitizedSIN = '';
  for (let i = 0; i < value.length; ++i) {
    let current = value.charAt(i);
    if (!/^[0-9]|\s/.test(current) || (current === ' ' && i !== 3 && i !== 7)) continue;
    if (current !== ' ' && (sanitizedSIN.length === 3 || sanitizedSIN.length === 7)) sanitizedSIN += ' ';
    sanitizedSIN += current;
  }
  return sanitizedSIN.substring(0, 11);
}

export function isPersonalInfoFilled(fields: any): boolean {
  const notEmpty =
    fields.firstName && fields.lastName && fields.email && fields.primaryPhone && fields.dob && fields.occupation && fields.employer;
  const isValid = validateEmail(fields.email) && validatePhone(fields.primaryPhone) && legalDob(fields.dob, fields.corp);
  return notEmpty && isValid;
}

export function isAddressInfoFilled(fields: IPurchaserInfo): boolean {
  return !!(fields.streetAddress && fields.city && fields.province && fields.postalCode && fields.country);
}

export function isSigningOfficerFilled(fields: any): boolean {
  const notEmpty = fields.fullName && fields.sin && fields.email && fields.primaryPhone && fields.dob && fields.streetAddress;
  const isValid = validateEmail(fields.email) && validatePhone(fields.primaryPhone) && legalDob(fields.dob, true);
  return notEmpty && isValid;
}

export function isIdentificationFilled(fields: any, showCheckbox: boolean, isChecked: boolean): boolean {
  const notEmpty = fields.idType && fields.idNumber && fields.idExpiry && fields.idJurisdiction && fields.purchaserType;
  const isValid = validateIdExpiry(fields.idExpiry);
  const isCheckboxValid = (showCheckbox && isChecked) || !showCheckbox;
  const isSINValid = !fields.sin || fields.sin.length === 11;

  return notEmpty && isValid && isCheckboxValid && isSINValid;
}

export const isWishlistFilled = (wishlistChoices: IWishlistChoices[]): boolean =>
  wishlistChoices.reduce(
    (prevChoicesFilled: boolean, { unitType, modelTypes, exposure, level }: any) =>
      prevChoicesFilled && unitType && exposure && level && modelTypes.length,
    true
  );

export const isPurchasersFilled = (purchasers: IWorksheetPurchaserInfo[]): boolean =>
  purchasers.reduce(
    (prevPurchasersFilled: boolean, purchaser: IWorksheetPurchaserInfo) =>
      prevPurchasersFilled &&
      purchaser.identifications.length > 0 &&
      isPersonalInfoFilled(purchaser) &&
      isAddressInfoFilled(purchaser) &&
      isIdentificationFilled(purchaser, true, purchaser.acknowledgement) &&
      (!purchaser.corp ||
        purchaser.signingOfficers.reduce(
          (prevOfficersFilled: boolean, officer: ISigningOfficer) => prevOfficersFilled && isSigningOfficerFilled(officer),
          true
        )),
    true
  );

export const isRealtorsFilled = (realtors: IWorksheetRealtorInfo[]): boolean =>
  realtors.reduce(
    (
      prevRealtorsFilled: boolean,
      { firstName, lastName, email, directPhone, streetAddress, city, province, country, postalCode, brokerage, brokeragePhone }: any
    ) =>
      prevRealtorsFilled &&
      firstName &&
      lastName &&
      email &&
      directPhone &&
      streetAddress &&
      city &&
      province &&
      country &&
      postalCode &&
      brokerage &&
      brokeragePhone,
    true
  );
