import React, { useState } from 'react';
import { withRouter } from '../../utils/WithRouter';
import { gql, useQuery } from '@apollo/client';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IDealRealtor, IDealRealtorData } from '../../types/deal';
import styled from 'styled-components';
import SortableTable from '../common/SortableTable';
import { dateFormatter, urlName } from '../../utils/Functions';
import { IPurchaserFullName } from '../../types/purchaser';
import { Title } from '../../utils/CommonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const Deals = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { project } = useSelector(selectProject);
  const [deals, setDeals] = useState<IDealTableData[]>();
  const [filter, setFilter] = useState<string>('active');

  let headers = ['suite', 'status', 'purchasers', 'signDate', 'executeDate', 'firmDate', 'createdAt', 'updatedAt'];
  let formattedHeaders = ['Suite', 'Status', 'Purchasers', 'Sign Date', 'Execute Date', 'Firm Date', 'Created At', 'Updated At'];

  if (filter === 'rescinded') {
    headers = ['suite', 'purchasers', 'signDate', 'executeDate', 'firmDate', 'createdAt', 'updatedAt'];
    formattedHeaders = ['Suite', 'Purchasers', 'Sign Date', 'Execute Date', 'Firm Date', 'Created At', 'Updated At'];
  }

  useQuery<IDealRealtorData>(GETDEALREALTORS, {
    variables: {
      filter: {
        realtor: user?.realtor._id,
        projects: project.combinedProjects.length
          ? [project._id, ...project.combinedProjects.map((project) => project._id)]
          : [project._id],
      },
    },
    onCompleted: (data) => {
      const deals = data.dealRealtorMany
        .filter((deals: any) => deals.deal)
        .map((dealRealtor: IDealRealtor) => ({
          _id: dealRealtor.deal ? dealRealtor.deal._id : '0',
          suite: dealRealtor.deal.unit.suite,
          status: dealRealtor.deal.unit.status,
          signDate: dateFormatter(dealRealtor.deal.signDate),
          executeDate: dateFormatter(dealRealtor.deal.executeDate),
          firmDate: dateFormatter(dealRealtor.deal.firmDate),
          createdAt: dateFormatter(dealRealtor.deal.createdAt),
          updatedAt: dateFormatter(dealRealtor.deal.updatedAt),
          purchasers: dealRealtor.deal.purchasers.map((purchaser: IPurchaserFullName) => `${purchaser.firstName} ${purchaser.lastName}`),
          cancelled: dealRealtor.deal.cancelled.dateCancelled !== null,
          rescission: dealRealtor.deal.rescission.dateRescinded !== null,
        }));

      setDeals(deals);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleFilter = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    setFilter(newFilter);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Title>Deals</Title>
      <ToggleButtonGroupContainer>
        <ToggleButtonGroup value={filter} exclusive onChange={handleFilter}>
          <ToggleButton value="active">Active</ToggleButton>
          <ToggleButton value="rescinded">Rescinded</ToggleButton>
        </ToggleButtonGroup>
      </ToggleButtonGroupContainer>
      {deals && (
        <>
          {deals.length !== 0 ? (
            <>
              <SortableTable
                headers={headers}
                formattedHeaders={formattedHeaders}
                rows={deals.filter((deal: IDealTableData) => {
                  if (filter === 'active') return !deal.cancelled && !deal.rescission;
                  else return deal.rescission;
                })}
                rowOnClick={(row: IDealTableData) => {
                  navigate(`/dashboard/${urlName(project.name)}/deals/${row._id}`);
                }}
              />
            </>
          ) : (
            <div>No current deals</div>
          )}
        </>
      )}
    </Box>
  );
};

export interface IDealParams {
  dealid: string;
  projectid: string;
}

interface IDealTableData {
  _id?: string;
  suite: string;
  status: string;
  purchasers: string[];
  cancelled: boolean;
  rescission: boolean;
  signDate: string;
  executeDate: string;
  firmDate: string;
  createdAt: string;
  updatedAt: string;
}

const ToggleButtonGroupContainer = styled.div`
  display: flex;
  margin-bottom: 2%;
`;

const GETDEALREALTORS = gql`
  query dealRealtorMany($filter: FilterFindManyDealRealtorInput) {
    dealRealtorMany(filter: $filter, limit: 10000) {
      deal {
        _id
        unit {
          suite
          status
          project {
            _id
          }
        }
        purchasers {
          firstName
          lastName
        }
        cancelled {
          dateCancelled
        }
        rescission {
          dateRescinded
        }
        signDate
        executeDate
        firmDate
        createdAt
        updatedAt
      }
    }
  }
`;

export default withRouter(Deals);
