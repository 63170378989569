import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { IMarketingFloorPlans, IUnit, IUnitDataGridCard } from '../../types/unit';
import { TextField } from '@mui/material';
import { mdWidth, smWidth, Title, xsWidth } from '../../utils/CommonStyles';
import SuiteOptions from './SuiteOptions';
import IconButton from '@mui/material/IconButton';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { numToCurrency } from '../../utils/Functions';
import { IProject, IOption } from '../../types/project';

const UnitInfoHeader = (props: ChildProps) => {
  const { dealOptions, notes, setNotes, unitid, unit } = props;
  const navigate = useNavigate();
  // show: suite, unit, level, modelType, basePrice,
  // extras: status, bathroom, exposure, outdoorSize, outdoorType, size, unityType, floorPlan, basePrice
  const [selectedUnit, setSelectedUnit] = useState<IUnit | null>(unit ?? null);
  const [floorPlan, setFloorPlan] = useState<IMarketingFloorPlans>();

  useQuery<IUnitDataGridCard>(GET_UNIT, {
    skip: !!unit,
    variables: { _id: unitid },
    onCompleted: (data) => {
      setSelectedUnit(data.unitById);
      props.setSelectedUnitType(data.unitById?.unitType);
    },
    onError: (error) => {
      navigate(-1);
    },
  });

  useQuery(MARKETINGFLOORPLANSONE, {
    skip: !selectedUnit?.modelType,
    variables: { filter: { project: props.project?._id, modelType: selectedUnit?.modelType } },
    onCompleted: (data) => {
      setFloorPlan(data.marketingFloorPlansOne);
    },
    onError: (error) => {
      navigate(-1);
    },
  });

  const handleDownload = () => {
    if (!floorPlan) return;
    const url = floorPlan.getUrl;
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file.pdf';
    a.click();
  };

  return (
    <MainContainer>
      {floorPlan?.getUrl ? (
        <FloorPlanContainer>
          <Zoom>
            <img width={'100%'} src={floorPlan?.getUrl} alt="fileImage" />
          </Zoom>
          <IconButton onClick={handleDownload} size="small">
            <FileDownloadIcon fontSize="small" />
          </IconButton>
        </FloorPlanContainer>
      ) : null}

      <SuiteInfoContainer>
        {selectedUnit && (
          <div style={{ width: '100%' }}>
            <Title>{`Suite ${selectedUnit?.suite} `}</Title>
            <UnitInfoContainer>
              <DataContainer>
                <h4>Level</h4>
                <h3>{selectedUnit?.level}</h3>
              </DataContainer>
              <DataContainer>
                <h4>Model</h4>
                <h3>{selectedUnit?.modelType}</h3>
              </DataContainer>
              <DataContainer>
                <h4>Type</h4>
                <h3>{selectedUnit?.unitType}</h3>
              </DataContainer>
              <DataContainer>
                <h4>Unit</h4>
                <h3>{selectedUnit?.unit}</h3>
              </DataContainer>
              <DataContainer>
                <h4>Unit Price</h4>
                <h3>{`${numToCurrency.format(selectedUnit?.basePrice)}`}</h3>
              </DataContainer>
            </UnitInfoContainer>
            {dealOptions ? (
              <SuiteOptions wishlist={false} dealOptions={dealOptions} displayPrice={true} />
            ) : (
              <SuiteOptions
                displayPrice={props.project?.portal.showOptionsPrice ? true : false}
                project={props.project}
                unitType={selectedUnit?.unitType}
                wishlist={false}
              />
            )}
          </div>
        )}

        {setNotes && (
          <TextField
            sx={{
              marginTop: '20px',
              width: '50%',
              '@media (max-width: 1024px)': {
                width: '100%',
              },
            }}
            id="outlined-textarea"
            label="Notes (optional)"
            placeholder="Enter additional notes about unit choices here"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={3}
          />
        )}
      </SuiteInfoContainer>
    </MainContainer>
  );
};

interface ChildProps {
  unitid: string;
  project?: IProject;
  unit?: IUnit | null;
  setSelectedUnitType: any;
  joinWaitlist?: boolean;
  setJoinWaitlist?: any;
  notes?: string;
  setNotes?: any;
  dealOptions?: IOption[];
}

const MainContainer = styled.div`
  display: flex;

  @media (max-width: ${smWidth}) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const SuiteInfoContainer = styled.div`
  width: 100%;
`;

const FloorPlanContainer = styled.div`
  width: 35%;
  margin-right: 20px;
  position: relative;

  .MuiIconButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #555;
    color: white;
    border-radius: 5px;
  }

  @media (max-width: ${smWidth}) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    .MuiIconButton-root {
      background-color: #555;
      color: white;
      border-radius: 5px;
    }
  }
`;

const UnitInfoContainer = styled.div`
  display: block;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  margin-top: 2vh;
  margin-bottom: 25px;
  @media (max-width: ${mdWidth}) {
    display: flex;
    width: 100%;
  }
  @media (max-width: ${xsWidth}) {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    width: 100%;
    margin-top: 0;
  }
`;

const DataContainer = styled.div`
  display: inline-block;
  border-right: 1px solid #e6e6e6;
  width: 20%;
  text-align: center;
  :last-of-type {
    border-right: none;
  }
  ,
  & h4 {
    font-size: 18px;
    color: #aaaaaa;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 5px;
  }
  ,
  & h3 {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: ${smWidth}) {
    border-right: none;
    & h4 {
      font-size: 12px;
      color: #aaaaaa;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 5px;
    }
    ,
    & h3 {
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: ${xsWidth}) {
    display: flex;
    width: 100%;
    & h4 {
      font-size: 24px;
      color: #aaaaaa;
      font-weight: 400;
      margin: 0;
      margin-right: 10px;
    }
    ,
    & h3 {
      font-size: 24px;
      color: #000000;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const GET_UNIT = gql`
  query unitById($_id: MongoID!) {
    unitById(_id: $_id) {
      project {
        _id
      }
      _id
      suite
      unit
      level
      modelType
      unitType
      basePrice
      getUrl
      floorPlan {
        _id
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
      }
      row
      col
    }
  }
`;

const MARKETINGFLOORPLANSONE = gql`
  query marketingFloorPlansOne($filter: FilterFindOnemarketingFloorPlansInput) {
    marketingFloorPlansOne(filter: $filter) {
      _id
      modelType
      getUrl
      project {
        _id
      }
    }
  }
`;

export default UnitInfoHeader;
