import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import SuiteOptionsCard from './SuiteOptionsCard';
import { StyledSubheading } from '../../utils/CommonStyles';
import { IWishlistChoices } from '../../types/worksheet';
import { findDifference } from '../../utils/Functions';
import TextField from '@mui/material/TextField';
import { IOption, IProject } from '../../types/project';

const SuiteOptions = (props: ChildProps) => {
  const { wishlist, wishlistChoices, notes, setNotes, project, dealOptions, displayPrice } = props;
  const unitTypes = wishlistChoices?.map((w: IWishlistChoices) => w.unitType);

  return (
    <MainContainer>
      {!wishlist && dealOptions?.length !== 0 && <Subheading>Suite Options</Subheading>}
      <OptionsContainer>
        {wishlist && dealOptions?.length !== 0 && <StyledSubheading>Suite Options</StyledSubheading>}
        <Grid container spacing={2}>
          {wishlist
            ? project?.options &&
              project?.options
                .filter((option: IOption) => option.type === 'residential')
                .map((option: IOption) => {
                  const unitsNotIncluded = findDifference(unitTypes, option.allowedUnits);
                  // update this is database has option amount limit
                  let amount = 1;
                  if (option.name === 'Standard Parking') {
                    amount = 2;
                  } else if (option.name === 'Standard Locker') {
                    amount = 2;
                  } else if (option.name === 'Kitchen Island') {
                    amount = 1;
                  }

                  return (
                    <SuiteOptionsCard
                      key={option.name}
                      title={option.name}
                      price={option.price!}
                      amount={amount}
                      buttonText="Option"
                      errorText={
                        unitsNotIncluded === null || unitsNotIncluded.length === 0 ? '' : `Not available for ${unitsNotIncluded.join(', ')}`
                      }
                      displayPrice={project?.portal.showOptionsPrice ? true : false}
                      displayOptions={project?.portal.showOptions ? true : false}
                    />
                  );
                })
            : project?.options &&
              project?.options
                .filter((option: IOption) => option.type === 'residential')
                .map((option: any) => {
                  let amount = 1;
                  if (option.name === 'Standard Parking') {
                    amount = 2;
                  } else if (option.name === 'Standard Locker') {
                    amount = 2;
                  } else if (option.name === 'Kitchen Island') {
                    amount = 1;
                  }

                  return (
                    <SuiteOptionsCard
                      displayOptions={project?.portal.showOptions && option.allowedUnits.includes(props.unitType) ? true : false}
                      displayPrice={project?.portal.showOptionsPrice ? true : false}
                      key={option.name}
                      title={option.name}
                      price={option.price}
                      amount={amount}
                      buttonText="Option"
                    />
                  );
                })}
          {dealOptions &&
            dealOptions.length > 0 &&
            dealOptions.map((option: IOption) => (
              <SuiteOptionsCard
                key={option.name}
                title={option.name}
                purchaseAmount={option.purchaseAmount}
                amount={option.amount}
                type={'deals'}
                displayPrice={displayPrice}
                displayOptions={project?.portal.showOptions ? true : false}
              />
            ))}
        </Grid>
      </OptionsContainer>
      {wishlist && (
        <NotesContainer>
          <TextField
            sx={{
              marginTop: '20px',
              width: '50%',
              '@media (max-width: 1024px)': {
                width: '100%',
              },
            }}
            id="outlined-textarea"
            label="Notes - Please include budget for more accurate allocation (optional) "
            placeholder="Enter additional notes about unit choices or price here"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={3}
          />
        </NotesContainer>
      )}
    </MainContainer>
  );
};

interface ChildProps {
  wishlist: boolean;
  project?: IProject;
  dealOptions?: IOption[];
  unitType?: string;
  wishlistChoices?: IWishlistChoices[];
  joinWaitlist?: boolean;
  setJoinWaitlist?: any;
  notes?: string;
  setNotes?: any;
  displayPrice?: boolean;
}

const MainContainer = styled.div`
  @media (max-width: 1024px) {
    display: block;
  }
`;

const OptionsContainer = styled.div`
  @media (max-width: 1024px) {
    display: block;
  }
`;

export const Subheading = styled.div`
  font-size: 1 rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
`;

const NotesContainer = styled.div`
  @media (max-width: 1024px) {
    margin-left: 0 !important;
  }
`;

export default SuiteOptions;
