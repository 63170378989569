import { createContext, useReducer } from 'react';
import { produce } from 'immer';

import { IAppointment, IAppointmentPurchaser } from '../types/appointment';

const AppointmentContext = createContext<any>(null);

const appointmentReducer = (draft: IAppointment, action: { type: string, payload: any }) => {
  switch (action.type) {
    case 'SET_LOCATION':
      draft.location = action.payload.value;
      return draft;
    case 'SET_DATE':
      draft.date = action.payload.value;
      return draft;
    case 'SET_PURCHASER_FIRST_NAME':
      draft.purchaserInfo.firstName = action.payload.value;
      return draft;
    case 'SET_PURCHASER_LAST_NAME':
      draft.purchaserInfo.lastName = action.payload.value;
      return draft;
    case 'SET_PURCHASER_EMAIL':
      draft.purchaserInfo.email = action.payload.value;
      return draft;
    case 'SET_PURCHASER_PHONE':
      draft.purchaserInfo.primaryPhone = action.payload.value;
      return draft;
    case 'SET_PURCHASER':
      draft.purchaserInfo = action.payload.value;
      return draft;
    case 'UPDATE_PURCHASER':
      draft.purchaserInfo[action.payload.key as keyof IAppointmentPurchaser] = action.payload.value;
      return draft;
    case 'UPDATE_NOTES' :
      draft.notes = action.payload.value;
      return draft;
    case 'ADD_ANSWER':
      if (!draft.questions) draft.questions = [];
      let answer = draft.questions[action.payload.index];
      if (!answer?.answer) answer = draft.questions[action.payload.index] = { questionId: action.payload.questionId, answer: [] };
      answer.answer?.push(action.payload.answer);
      return draft;
    case 'REMOVE_ANSWER':
      if (!draft.questions?.length) {
        draft.questions = [];
        return draft;
      }
      const a = draft.questions[action.payload.index];
      if (a?.answer?.length) a.answer = a?.answer?.filter(answer => answer !== action.payload.answer);
      if (!a?.answer?.length) delete draft.questions[action.payload.index];
      return draft;
    case 'SET_ANSWER':
      if (!draft.questions?.length) draft.questions = []
      let ans = draft.questions[action.payload.index];
      if (!ans) ans = draft.questions[action.payload.index] = { questionId: action.payload.questionId };
      ans.answer = [action.payload.answer];
      return draft;
    case 'REMOVE_QUESTION':
      if (draft.questions?.length) delete draft.questions[action.payload.index];
      return draft;
    case 'CLEAR_APPOINTMENT':
      draft = {...initialAppointment, purchaserInfo: {...initialAppointment.purchaserInfo}};
      return draft;
    case 'NEW_APPOINTMENT':
      draft = {
        ...initialAppointment, 
        purchaserInfo: {...initialAppointment.purchaserInfo},
      };
      draft.project = action.payload.project;
      draft.user = action.payload.user;
      draft.location = action.payload.location;
      return draft;
    default:
      return;
  }
};

const initialAppointment: IAppointment = {
  project: null,
  user: null,
  location: "",
  date: null,
  purchaserInfo: {
    firstName: "",
    lastName: "",
    email: "",
    primaryPhone: "",
    numberOfGuests: "",
  },
  questions: [],
  status: "Pending",
  type: "regular",
  confirmed: false,
};

const curriedAppointmentReducer = produce(appointmentReducer);

const AppointmentProvider = (props: ChildProps) => {
  const [appointment, dispatchAppointment] = useReducer(curriedAppointmentReducer, initialAppointment);

  return (
    <div>
      <AppointmentContext.Provider value={[appointment, dispatchAppointment]}>
        {props.children}
      </AppointmentContext.Provider>
    </div>
  );
};

interface ChildProps {
  children: any;
}

export { AppointmentContext, AppointmentProvider };