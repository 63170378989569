import { useState } from 'react';
import { Button, Modal, Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { IFormattedWorksheetInfo, IPurchaserInfo, IWishlistChoices, IWorksheet, IWorksheetInfo } from '../../types/worksheet';
import SortableTable from '../common/SortableTable';
import { IUnit } from '../../types/unit';
import { numberWithCommas } from '../../utils/Functions';
import { theme } from '../../theme/theme';
import LoadingWrapper from '../common/LoadingWrapper';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { IProject } from '../../types/project';

const WorksheetDropdown = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { unit, index, update, initialSelectedWorksheet, project, userId } = props;
  const [selectedWorksheet, setSelectedWorksheet] = useState<string>('');
  const [worksheetInfo, setWorksheetInfo] = useState<IFormattedWorksheetInfo[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const [getWorksheets, { loading: worksheetLoading }] = useLazyQuery(GETWORKSHEETS, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      const formattedData = data.getRealtorWorksheets.map((worksheetInfo: IWorksheetInfo) => {
        const formattedWorksheetInfo = {
          _id: worksheetInfo._id,
          purchasers: worksheetInfo.purchasers.map((purchaser: IPurchaserInfo) => purchaser.fullName),
          exposure: worksheetInfo.wishlistChoices.map((wishlist: IWishlistChoices) => wishlist.exposure),
          level: worksheetInfo.wishlistChoices.map((wishlist: IWishlistChoices) => wishlist.level),
          modelTypes: worksheetInfo.wishlistChoices.map((wishlist: IWishlistChoices) => wishlist.modelTypes.join(', ')),
          unitType: worksheetInfo.wishlistChoices.map((wishlist: IWishlistChoices) => wishlist.unitType),
          unit: worksheetInfo.unit ? { _id: worksheetInfo.unit._id } : null
        };
        if (worksheetInfo.unit?._id === unit._id) {
          setSelectedWorksheet(formattedWorksheetInfo._id);
        }

        return formattedWorksheetInfo;
      }).filter((worksheet: any) => ((worksheet.unit && worksheet.unit._id === unit._id) || (!worksheet.unit)));

      setWorksheetInfo(formattedData);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [allocateWorksheet] = useMutation(ALLOCATEWORKSHEET, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Allocation Updated Successfully'))
      update(data.allocateWorksheet, index);
    },
    onError: (error) => {
      console.log(error);
      storeDispatch(showErrorSnackbar(error.message))
    },
  })

  const handleWorksheetSelected = () => {
    allocateWorksheet({ variables: { 
      unit: unit._id,
      user: userId,
      oldWorksheet: initialSelectedWorksheet?._id,
      newWorksheet: selectedWorksheet === '' ? null : selectedWorksheet,
     } })
  };

  return (
    <>
      <Button
        onClick={() => {
          getWorksheets({variables: { projects: project.combinedProjects.length ? [project._id, ...project.combinedProjects.map((project) => project._id)] : [project._id], type: 'wishlist', selectedWorksheet: initialSelectedWorksheet ? initialSelectedWorksheet._id : null } });
          setOpenModal(true);
          initialSelectedWorksheet?._id && setSelectedWorksheet(initialSelectedWorksheet._id);
        }}
        variant={'contained'}
      >
        Allocate Worksheets
      </Button>
      <WorksheetModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <WorksheetModalBody>
          <UnitDetailsContainer container>
            <Grid item lg={2} md={4} sm={6} xs={12}>{`Unit: ${unit.suite}`}</Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>{`Price: $${numberWithCommas(unit.basePrice)}`}</Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>{`Model Type: ${unit.modelType}`}</Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>{`Unit Type: ${unit.unitType}`}</Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>{`Level: ${unit.level}`}</Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>{`Exposure: ${unit.exposure}`}</Grid>
          </UnitDetailsContainer>
          {worksheetLoading ? <LoadingWrapper modal={false} title={"Loading"} /> :
          <>
          <Box>
            <SortableTable
              formattedHeaders={['Purchaser', 'Exposure', 'Level', 'Model Type', 'Unit Type']}
              headers={['purchasers', 'exposure', 'level', 'modelTypes', 'unitType']}
              rows={worksheetInfo}
              checked={selectedWorksheet}
              setChecked={(selected) => {
                if (selected._id === selectedWorksheet) setSelectedWorksheet('');
                else setSelectedWorksheet(selected._id);
              }}
            />
          </Box>
          {worksheetInfo.length > 0 ?
            <Button
              sx={{ width: '300px', margin: '10px auto'}}
              onClick={() => {
                handleWorksheetSelected();
                setOpenModal(false);
              }}
              color="primary"
              variant="contained"
            >
              Confirm Changes to Allocation
            </Button>
          : <Box sx={{ textAlign: 'center', marginTop: '10px'}}><em>No available worksheets to allocate</em></Box>
          }
          </>
          }
        </WorksheetModalBody>
      </WorksheetModal>
    </>
  );
};

const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2vw;
  width: 80%;
  height: 80%;
  overflow-y: scroll;
  background: white;
`;

const UnitDetailsContainer = styled(Grid)({
  background: `${theme.palette.primary.main}`,
  padding: '30px 20px',
  color: '#fff'
})

const WorksheetModal = styled(Modal)({
  padding: 0
})

const WorksheetModalBody = styled(ModalBody)({
  padding: 0,
  justifyContent: 'start'
})

interface ChildProps {
  unit: IUnit;
  realtorId: string;
  update: (unit: IUnit, index: number) => void;
  index: number;
  initialSelectedWorksheet: IWorksheet | null;
  project: IProject;
  userId: string;
}

const ALLOCATEWORKSHEET = gql`
  mutation allocateWorksheet($user: MongoID!, $unit: MongoID!, $oldWorksheet: MongoID, $newWorksheet: MongoID) {
    allocateWorksheet(user: $user, unit: $unit, oldWorksheet: $oldWorksheet, newWorksheet: $newWorksheet) {
      _id
      suite
      unit
      unitType
      modelType
      size
      level
      outdoorSize
      outdoorType
      bathroom
      exposure
      basePrice
      worksheets {
        _id
        realtor {
          _id
        }
        purchasers {
          fullName
        }
        status
      }
    }
  }
`;

const GETWORKSHEETS = gql`
  query getRealtorWorksheets($projects: [MongoID], $type: String!, $selectedWorksheet: MongoID) {
    getRealtorWorksheets(projects: $projects, type: $type, selectedWorksheet: $selectedWorksheet) {
      _id
      purchasers {
        fullName
      }
      wishlistChoices {
        unitType
        modelTypes
        level
        exposure
      }
      unit {
        _id
      }
      project {
        _id
      }
    }
  }
`;

export default WorksheetDropdown;
