import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Collapse,
  Select,
} from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styled from 'styled-components';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IDeposits } from '../../types/project';
import { numberWithCommas } from '../../utils/Functions';
import { IStatusColours } from './DepositStructure';

const DepositStructureRow = (props: ChildProps) => {
  const { deposit, statusColours } = props;
  const [open, setOpen] = useState(false);

  const handleCollapseOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ background: deposit?.chequeType ? statusColours[deposit.chequeType] : '#fff' }}>
        <TableCell>
          <FormControl required variant="outlined">
            <OutlinedInput id="deposit-name" disabled value={deposit.name} />
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl required variant="outlined">
            <OutlinedInput
              id="deposit-amount"
              disabled
              value={numberWithCommas(deposit.amount)}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker value={deposit.dueDate} disabled onChange={() => {}} renderInput={(params: any) => <TextField {...params} />} />
          </LocalizationProvider>
        </TableCell>
        <TableCell>
          <IconButton onClick={handleCollapseOpen}>{open ? <ExpandLessIcon /> : <ExpandMoreOutlinedIcon />}</IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ChequeInfoContainer>
              <ChequeInfoTitleContainer>
                <ChequeInfoTitle>Cheque Info</ChequeInfoTitle>
              </ChequeInfoTitleContainer>
              <ChequeInfo>
                <TextField id="account-number" label="Account Number" disabled value={deposit.accountNumber} />
                <TextField id="cheque-number" label="Cheque Number" disabled value={deposit.chequeNumber} />
                <FormControl disabled>
                  <InputLabel htmlFor="cheque-amount">Cheque Amount</InputLabel>
                  <OutlinedInput
                    id="cheque-amount"
                    value={numberWithCommas(deposit.amount)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Cheque Amount"
                  />
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={deposit.chequeDate}
                    label="Cheque Date"
                    disabled
                    onChange={() => {}}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <FormControl disabled sx={{ minWidth: '200px' }}>
                  <InputLabel htmlFor="cheque-type">Cheque Type</InputLabel>
                  <Select id="cheque-type" value={deposit.chequeType} label="Cheque Type"></Select>
                </FormControl>
              </ChequeInfo>
            </ChequeInfoContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ChequeInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 2% 0;
`;

const ChequeInfoTitleContainer = styled.div`
  width: 15%;
`;

const ChequeInfoTitle = styled.h3`
  padding: 0;
  margin: 0;
`;

const ChequeInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  gap: 20px;
`;

interface ChildProps {
  deposit: IDeposits;
  statusColours: IStatusColours;
}

export default DepositStructureRow;
