import { createContext, useReducer, useState } from 'react';
import { IWorksheetPurchaserInfo, IWishlistChoices, IWorksheetRealtorInfo, IMedia } from '../types/worksheet';
import { IRealtor } from '../types/realtor';
import { IOption } from '../types/project';
import { produce } from 'immer';

const WorksheetContext = createContext<any>(null);

const purchasersReducer = (
  draft: IWorksheetPurchaserInfo[],
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case 'ADD_PURCHASER':
      //delete initial state
      if (draft[0].firstName === '') {
        draft.pop();
      }
      draft.push(action.payload);
      return;
    case 'ADD_NEW_PURCHASER':
      //makes the previous purchaser complete so it gets condensed using an accordion
      draft.push(action.payload);
      draft[action.payload.purchaserNumber - 1].purchaserComplete = true;
      return;
    case 'REMOVE_PURCHASER':
      //give purchaser number back to the next purchaser
      const purchaserToDelete = action.payload.purchaserNumber;

      draft.splice(purchaserToDelete, 1);
      return;

    case 'SET_PROJECT_ID':
      draft[action.payload.purchaserNumber].project = action.payload.value;
      return;

    case 'ADD_AFTER_SAVING_PURCHASER':
      draft[action.payload.purchaserNumber]._id = action.payload.id;
      draft[action.payload.purchaserNumber].getUrl = action.payload.getUrl;
      draft[action.payload.purchaserNumber].putUrl = action.payload.putUrl;
      return;

    case 'TOGGLE_CHANGE':
      let bool = false;
      if (action.payload.value === 'personal') {
        draft[action.payload.purchaserNumber].signingOfficers = [];
      }
      action.payload.value === 'personal' ? (bool = false) : (bool = true);
      draft[action.payload.purchaserNumber][action.payload.field] = bool;
      return;

    case 'TEXT_CHANGE':
      draft[action.payload.purchaserNumber][action.payload.field] = action.payload.value;
      return;

    case 'ADD_SIGNING_OFFICER':
      draft[action.payload.purchaserNumber].signingOfficers.push(action.payload.value);
      draft[action.payload.purchaserNumber].signingOfficerComplete.push(true);
      return;
    case 'EDIT_SIGNING_OFFICER':
      if (action.payload.field === 'fullName') {
        draft[action.payload.purchaserNumber].signingOfficers[action.payload.index].fullName = action.payload.value;
      }
      if (action.payload.field === 'streetAddress') {
        draft[action.payload.purchaserNumber].signingOfficers[action.payload.index].streetAddress = action.payload.value;
      }
      if (action.payload.field === 'sin') {
        draft[action.payload.purchaserNumber].signingOfficers[action.payload.index].sin = action.payload.value;
      }
      if (action.payload.field === 'primaryPhone') {
        draft[action.payload.purchaserNumber].signingOfficers[action.payload.index].primaryPhone = action.payload.value;
      }
      if (action.payload.field === 'email') {
        draft[action.payload.purchaserNumber].signingOfficers[action.payload.index].email = action.payload.value;
      }

      return;
    case 'EDIT_DATE_SIGNING_OFFICER':
      draft[action.payload.purchaserNumber].signingOfficers[action.payload.index].dob = action.payload.value;
      return;
    case 'REMOVE_SIGNING_OFFICER':
      //give purchaser number back to the next purchaser
      const signingOfficerToDelete = action.payload.index;
      draft[action.payload.purchaserNumber].signingOfficers.splice(signingOfficerToDelete, 1);
      return;

    case 'PROOF_CHANGE':
      draft[action.payload.purchaserNumber].proof = action.payload.proof;
      draft[action.payload.purchaserNumber].proofFileImage = action.payload.proofFileImage;
      return;

    case 'ID_TYPE_CHANGE':
      draft[action.payload.purchaserNumber].idType = action.payload.value;
      return;

    case 'PURCHASER_TYPE_CHANGE':
      draft[action.payload.purchaserNumber].purchaserType = action.payload.value;
      return;

    case 'DATE_CHANGE':
      draft[action.payload.purchaserNumber][action.payload.field] = action.payload.value;
      return;

    case 'ID_EXPIRY_CHANGE':
      const pNum: number = action.payload.purchaserNumber;
      draft[pNum].idExpiry = action.payload.value;
      return;

    case 'ADD_ID_FILE':
      draft[action.payload.purchaserNumber].identifications.push(action.payload.value);
      return;
    case 'REMOVE_ID_FILE':
      draft[action.payload.purchaserNumber].identifications.splice(action.payload.index, 1);
      return;

    case 'EDIT_ID_FILE':
      draft[action.payload.purchaserNumber].identifications[action.payload.index].name = action.payload.value.name;
      draft[action.payload.purchaserNumber].identifications[action.payload.index].file = action.payload.value.file;
      draft[action.payload.purchaserNumber].identifications[action.payload.index].url = action.payload.value.url;
      draft[action.payload.purchaserNumber].identifications.push({
        url: '',
        file: null,
        name: '',
        putUrl: '',
        getUrl: '',
      });
      return;
    case 'STREET_ADDRESS':
      draft[action.payload.purchaserNumber].streetAddress = action.payload.streetAddress;
      draft[action.payload.purchaserNumber].province = action.payload.province;
      draft[action.payload.purchaserNumber].postalCode = action.payload.postalCode;
      draft[action.payload.purchaserNumber].country = action.payload.country;
      draft[action.payload.purchaserNumber].city = action.payload.city;
      return;
    case 'CLEAR_PURCHASERS':
      draft.splice(0, draft.length);
      draft.push(initialState[0]);
      return;
    case 'TOGGLE_ACKNOWLEDGEMENT':
      draft[action.payload.purchaserNumber].acknowledgement = !draft[action.payload.purchaserNumber].acknowledgement;
      return;
    default:
      return;
  }
};

const curriedPurchasersReducer = produce(purchasersReducer);

const initialState: IWorksheetPurchaserInfo[] = [
  {
    purchaserNumber: 0,
    project: '',
    corp: false,
    proof: null,
    proofFileImage: '',
    acknowledgement: false,
    purchaserComplete: false,
    signingOfficerComplete: [],
    fullName: '',
    firstName: '',
    lastName: '',
    email: '',
    identifications: [],
    primaryPhone: '',
    dob: null,
    occupation: '',
    employer: '',
    streetAddress: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    idType: '',
    idNumber: '',
    signingOfficers: [
      {
        fullName: '',
        dob: null,
        email: '',
        primaryPhone: '',
        sin: '',
        streetAddress: '',
      },
    ],
    idExpiry: null,
    idJurisdiction: 'Ontario',
    sin: '',
    purchaserType: '',
  },
];

const realtorReducer = (
  draft: IWorksheetRealtorInfo[],
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case 'TEXT_CHANGE': {
      draft[0][action.payload.field] = action.payload.value;
      return;
    }
    case 'STREET_ADDRESS':
      draft[0].streetAddress = action.payload.streetAddress;
      draft[0].province = action.payload.province;
      draft[0].postalCode = action.payload.postalCode;
      draft[0].country = action.payload.country;
      draft[0].city = action.payload.city;
      return;
    default:
      return;
  }
};

const initialRealtorState: IRealtor[] = [
  {
    email: '',
    firstName: '',
    lastName: '',
    brokerage: '',
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    brokeragePhone: '',
    brokerageFax: '',
    directPhone: '',
    recoNumber: '',
  },
];
const curriedRealtorReducer = produce(realtorReducer);

const wishlistChoicesReducer = (draft: IWishlistChoices[], action: { type: string; payload: any }) => {
  switch (action.type) {
    case 'SET_WISHLIST_CHOICES': {
      if (draft[0].unitType === '') {
        draft.pop();
      }
      draft.push(...action.payload);
      return;
    }
    case 'ADD_UNIT':
      draft.push(action.payload);
      return;
    case 'REMOVE_UNIT':
      draft.splice(action.payload.index, 1);
      return;
    case 'SET_UNIT_TYPE':
      draft[action.payload.index] = { ...initialWishlistChoices[0] };
      draft[action.payload.index].unitType = action.payload.value;
      return;
    case 'SET_MODEL_TYPE':
      draft[action.payload.index].modelTypes = action.payload.value;
      return;
    case 'SET_EXPOSURE_TYPE':
      draft[action.payload.index].exposure = action.payload.value;
      return;
    case 'SET_LEVEL':
      draft[action.payload.index].level = action.payload.value;
      return;
    case 'CLEAR_CHOICES':
      draft.splice(0, draft.length);
      draft.push(initialWishlistChoices[0]);
      return;
    default:
      return;
  }
};

const initialWishlistChoices: IWishlistChoices[] = [
  {
    unitType: '',
    modelTypes: ['Any'],
    exposure: 'Any',
    level: 'Any',
  },
];

const curriedWishlistChoicesReducer = produce(wishlistChoicesReducer);

const WorksheetProvider = (props: ChildProps) => {
  const [purchasers, dispatchPurchasers] = useReducer(curriedPurchasersReducer, initialState);
  const [realtor, dispatchRealtor] = useReducer(curriedRealtorReducer, initialRealtorState);
  const [wishlistChoices, dispatchChoices] = useReducer(curriedWishlistChoicesReducer, initialWishlistChoices);
  const [options, setOptions] = useState<IOption[]>([]);
  const [chequeImages, setChequeImages] = useState<IMedia[]>([]);
  const [joinWaitlist, setJoinWaitlist] = useState<string[]>([]);

  return (
    <div>
      <WorksheetContext.Provider
        value={{
          purchasersValue: [purchasers, dispatchPurchasers],
          realtorValue: [realtor, dispatchRealtor],
          optionsValue: [options, setOptions],
          chequeImagesValue: [chequeImages, setChequeImages],
          wishlistValue: [wishlistChoices, dispatchChoices],
          joinWaitlistValue: [joinWaitlist, setJoinWaitlist],
        }}
      >
        {props.children}
      </WorksheetContext.Provider>
    </div>
  );
};

interface ChildProps {
  children: any;
}

export { WorksheetContext, WorksheetProvider };
