import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup, Button, Box } from '@mui/material';
import { Title } from '../../utils/CommonStyles';
import { IWorksheetArray } from '../../types/worksheet';
import SortableTable from '../common/SortableTable';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../common/CustomDialog';
import { capitalizeFirstLetter } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const WorksheetList = () => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { project } = useSelector(selectProject);
  const [worksheetTable, setWorksheetTable] = useState<IWorksheetTable>({
    wishlist: [],
    units: [],
    archived: [],
  });
  const [alignment, setAlignment] = useState('wishlist');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [worksheetId, setWorksheetId] = useState<string | null>(null);
  const [worksheetStatus, setWorksheetStatus] = useState<string | null>(null);

  // const that extends the interface IObjectKeys

  const formattedHeaders: IHeaders = {
    wishlist: ['Unit Types', 'Status', 'Primary Purchaser', 'Email', 'Notes', 'View', 'Archive'],
    units: ['Suite', 'Unit', 'Unit Type', 'Model Type', 'Primary Purchaser', 'Email', 'Notes', 'Status', 'View', 'Archive'],
    archived: ['Suite', 'Unit', 'Unit Types', 'Status', 'Primary Purchaser', 'Email', 'Notes'],
  };
  const headers: IHeaders = {
    wishlist: ['unitTypes', 'status', 'primaryPurchaser', 'email', 'notes', 'view', 'archive'],
    units: ['suite', 'unit', 'unitType', 'modelType', 'primaryPurchaser', 'email', 'notes', 'status', 'view', 'archive'],
    archived: ['suite', 'unit', 'unitTypes', 'status', 'primaryPurchaser', 'email', 'notes', 'view', 'archive'],
  };

  const [restoreWorksheet] = useMutation(UPDATEWORKSHEET, {
    onCompleted: (data) => {
      if (data.restoreWorksheet.status !== 'archived') {
        let newWishlist = {
          _id: data.restoreWorksheet._id!,
          primaryPurchaser: data.restoreWorksheet.purchasers[0].fullName ? data.restoreWorksheet.purchasers[0].fullName : '',
          email: data.restoreWorksheet.purchasers[0].email,
          status: capitalizeFirstLetter(data.restoreWorksheet.status),
          unitTypes: data.restoreWorksheet.wishlistChoices!.map((choice: any) => choice.unitType).join(', '),
          notes: data.restoreWorksheet.notes,
          view:
            data.restoreWorksheet.status !== 'accepted' ? (
              <Button
                sx={{ zIndex: 1000 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}/worksheets/${data.restoreWorksheet._id!}`)
                }
              >
                Details
              </Button>
            ) : null,
          archive: (
            <Button
              sx={{ zIndex: 1000 }}
              variant="contained"
              color="primary"
              onClick={() => handleDialog(data.restoreWorksheet._id!, 'archived')}
            >
              Archive
            </Button>
          ),
        };

        let removeFromArchive = worksheetTable.archived.filter((archive: any) => archive._id !== data.restoreWorksheet._id);
        setWorksheetTable({ ...worksheetTable, archived: removeFromArchive, wishlist: [newWishlist, ...worksheetTable.wishlist] });
        storeDispatch(showSuccessSnackbar('Worksheet Restored'));
      } else {
        let searchWishlist = worksheetTable.wishlist.find((wishlist: any) => wishlist._id === data.restoreWorksheet._id);
        let searchUnit = worksheetTable.units.find((unit: any) => unit._id === data.restoreWorksheet._id);

        let archived = {
          _id: data.restoreWorksheet._id!,
          suite: data.restoreWorksheet.unit ? data.restoreWorksheet.unit.suite : '',
          unit: data.restoreWorksheet.unit ? data.restoreWorksheet.unit.unit : '',
          unitTypes: data.restoreWorksheet.wishlistChoices!.map((choice: any) => choice.unitType).join(', '),
          primaryPurchaser: data.restoreWorksheet.purchasers[0].fullName ? data.restoreWorksheet.purchasers[0].fullName : '',
          email: data.restoreWorksheet.purchasers[0].email,
          notes: data.restoreWorksheet.notes,
          status: capitalizeFirstLetter(data.restoreWorksheet.status),
          view: (
            <Button
              sx={{ zIndex: 1000 }}
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}/worksheets/${data.restoreWorksheet._id!}`)
              }
            >
              Details
            </Button>
          ),
          archive: (
            <Button
              sx={{ zIndex: 1000 }}
              variant="contained"
              color="primary"
              onClick={() => handleDialog(data.restoreWorksheet._id!, 'pending')}
            >
              Restore
            </Button>
          ),
        };

        if (searchWishlist) {
          let removeFromWishlists = worksheetTable.wishlist.filter((wishlist: any) => wishlist._id !== data.restoreWorksheet._id);
          setWorksheetTable({ ...worksheetTable, archived: [archived, ...worksheetTable.archived], wishlist: removeFromWishlists });
        } else if (searchUnit) {
          let removeFromUnits = worksheetTable.units.filter((unit: any) => unit._id !== data.restoreWorksheet._id);
          setWorksheetTable({ ...worksheetTable, archived: [archived, ...worksheetTable.archived], units: removeFromUnits });
        }

        storeDispatch(showSuccessSnackbar('Worksheet Archived'));
      }
      setDialogOpen(false);
      setWorksheetId(null);
    },
    onError: (error) => {
      console.log(error, 'error');
      if (error.message.includes('Path `unitType` is required')) {
        storeDispatch(showErrorSnackbar('Please select an unit type'));
      }
    },
  });

  const [getWorksheets] = useLazyQuery<IWorksheetArray>(GETWORKSHEETS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setWorksheetTable({
        wishlist: [],
        units: [],
        archived: [],
      });
      //filter through the worksheet many and put them in table data
      data.getRealtorWorksheets.forEach((worksheet) => {
        if (alignment === 'archived') {
          setWorksheetTable((worksheetTable) => ({
            ...worksheetTable,
            archived: [
              ...worksheetTable.archived,
              {
                _id: worksheet._id!,
                suite: worksheet.unit ? worksheet.unit.suite : '',
                unit: worksheet.unit ? worksheet.unit.unit : '',
                unitTypes: worksheet.wishlistChoices!.map((choice) => choice.unitType).join(', '),
                primaryPurchaser: worksheet.purchasers[0].fullName ? worksheet.purchasers[0].fullName : '',
                email: worksheet.purchasers[0].email,
                notes: worksheet.notes,
                status: capitalizeFirstLetter(worksheet.status),
                view: (
                  <Button
                    sx={{ zIndex: 1000 }}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}/worksheets/${worksheet._id!}`)}
                  >
                    Details
                  </Button>
                ),
                archive: (
                  <Button sx={{ zIndex: 1000 }} variant="contained" color="primary" onClick={() => handleDialog(worksheet._id!, 'pending')}>
                    Restore
                  </Button>
                ),
              },
            ],
          }));
        } else if (alignment === 'wishlist') {
          setWorksheetTable((worksheetTable) => ({
            ...worksheetTable,
            wishlist: [
              ...worksheetTable.wishlist,
              {
                _id: worksheet._id!,
                primaryPurchaser: worksheet.purchasers[0].fullName ? worksheet.purchasers[0].fullName : '',
                email: worksheet.purchasers[0].email,
                status: capitalizeFirstLetter(worksheet.status),
                unitTypes: worksheet.wishlistChoices!.map((choice) => choice.unitType).join(', '),
                notes: worksheet.notes,
                view:
                  worksheet.status !== 'accepted' ? (
                    <Button
                      sx={{ zIndex: 1000 }}
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}/worksheets/${worksheet._id!}`)}
                    >
                      Details
                    </Button>
                  ) : null,
                archive: (
                  <Button
                    sx={{ zIndex: 1000 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleDialog(worksheet._id!, 'archived')}
                  >
                    Archive
                  </Button>
                ),
              },
            ],
          }));
        } else if (alignment === 'units') {
          setWorksheetTable((worksheetTable: any) => ({
            ...worksheetTable,
            units: [
              ...worksheetTable.units,
              {
                _id: worksheet._id!,
                suite: worksheet.unit ? worksheet.unit.suite : '',
                unit: worksheet.unit ? worksheet.unit!.unit : '',
                unitType: worksheet.unit ? worksheet.unit.unitType : '',
                modelType: worksheet.unit ? worksheet.unit!.modelType : '',
                primaryPurchaser: worksheet.purchasers[0].fullName ? worksheet.purchasers[0].fullName : '',
                email: worksheet.purchasers[0].email,
                notes: worksheet.notes,
                status: capitalizeFirstLetter(worksheet.status),
                view:
                  worksheet.status !== 'accepted' ? (
                    <Button
                      sx={{ zIndex: 1000 }}
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}/worksheets/${worksheet._id!}`)}
                    >
                      Details
                    </Button>
                  ) : null,
                archive: (
                  <Button
                    sx={{ zIndex: 1000 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleDialog(worksheet._id!, 'archived')}
                  >
                    Archive
                  </Button>
                ),
              },
            ],
          }));
        }
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    getWorksheets({
      variables: {
        projects: project.combinedProjects.length ? [project._id, ...project.combinedProjects.map((project) => project._id)] : [project._id],
        type: alignment,
      },
    });
  }, [alignment]);

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  const worksheetMessage = (alignment: string) => {
    if (alignment === 'wishlist') {
      return 'No wishlist worksheet has been created.';
    } else if (alignment === 'units') {
      return 'No worksheet for a unit has been created.';
    } else if (alignment === 'archived') {
      return 'No archived worksheets.';
    }
  };

  const handleCloseSuccess = () => {
    if (worksheetId && worksheetStatus) {
      restoreWorksheet({ variables: { _id: worksheetId, type: worksheetStatus } }).then((res) => {
        setWorksheetId(null);
        setWorksheetStatus(null);
      });
    }
  };

  const handleDialog = (worksheetId: string, status: string) => {
    setWorksheetStatus(status);
    setWorksheetId(worksheetId);
    setDialogOpen(true);
  };

  return (
    <Box sx={{ minHeight: '58.5vh', p: 3 }}>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => handleCloseSuccess()}
        open={dialogOpen}
        removeButton={'No'}
        successButton={'Yes'}
        dialogContent={
          `${worksheetStatus}` === 'archived'
            ? 'Are you sure you want to archive worksheet?'
            : 'Are you sure you want to restore worksheet?'
        }
        dialogTitle={`${worksheetStatus}` === 'archived' ? 'Archive Worksheet' : 'Restore Worksheet'}
      />
      <Title>Worksheets</Title>
      <ToggleButtonGroupContainer>
        <ToggleButtonGroup value={alignment} exclusive onChange={handleToggle} color="primary">
          <ToggleButton value={'wishlist'}>Wishlist</ToggleButton>
          <ToggleButton value={'units'}>Units</ToggleButton>
          <ToggleButton value={'archived'}>Archived</ToggleButton>
        </ToggleButtonGroup>
      </ToggleButtonGroupContainer>
      {worksheetTable[alignment]?.length !== undefined && worksheetTable[alignment].length !== 0 ? (
        <>
          <SortableTable
            formattedHeaders={formattedHeaders[alignment]}
            headers={headers[alignment]}
            defaultSort={headers[0]}
            rows={worksheetTable[alignment]}
          />
        </>
      ) : (
        <p>
          <em>{worksheetMessage(alignment)}</em>
        </p>
      )}
    </Box>
  );
};

const ToggleButtonGroupContainer = styled.div`
  display: flex;
  margin-bottom: 2%;
`;

interface IObjectKeys {
  [key: string]: any;
}

interface IHeaders extends IObjectKeys {
  wishlist: string[];
  units: string[];
  archived: string[];
}
interface IWorksheetTable extends IObjectKeys {
  wishlist: IWishlistTable[];
  units: IUnitsTable[];
  archived: IArchivedTable[];
}

interface IWishlistTable {
  _id: string;
  primaryPurchaser: string;
  email: string;
  status: string;
  unitTypes: string;
  notes: string;
}

interface IUnitsTable {
  _id: string;
  unit: string;
  unitType: string;
  modelType: string;
  primaryPurchaser: string;
  email: string;
  notes: string;
  status: string;
}
//TODO: add archived table
interface IArchivedTable {
  _id: string;
  unit: string;
}

const GETWORKSHEETS = gql`
  query getRealtorWorksheets($projects: [MongoID], $type: String!, $selectedWorksheet: MongoID) {
    getRealtorWorksheets(projects: $projects, type: $type, selectedWorksheet: $selectedWorksheet) {
      _id
      project {
        _id
        name
        combinedProjects {
          _id
        }
      }
      unit {
        suite
        unit
        unitType
        modelType
      }
      purchasers {
        fullName
        email
      }
      wishlist
      wishlistChoices {
        unitType
      }
      status
      createdAt
      notes
    }
  }
`;

const UPDATEWORKSHEET = gql`
  mutation restoreWorksheet($_id: MongoID!, $type: String!) {
    restoreWorksheet(_id: $_id, type: $type) {
      _id
      project {
        _id
        name
      }
      unit {
        unit
        unitType
        modelType
      }
      purchasers {
        fullName
        email
      }
      wishlist
      wishlistChoices {
        unitType
      }
      status
      createdAt
      notes
    }
  }
`;

export default WorksheetList;
