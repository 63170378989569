import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Box, Button } from '@mui/material';

import { IProject } from '../../types/project';
import { ISchedule } from '../../types/appointment';
import { urlName } from '../../utils/Functions';

const Main = ({ project }: { project: IProject }) => {
  const [schedule, setSchedule] = useState<ISchedule | null>(null);
  const navigate = useNavigate();

  useQuery(GETSCHEDULEONE, {
    variables: {
      filter: {
        project: project._id,
        public: true,
      },
    },
    onCompleted: (data) => {
      setSchedule(data.scheduleOne);
    },
  });

  return (
    <Box sx={{ height: 'calc(100vh - 48px)', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', p: 2 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ fontSize: '72px' }}>{project.name}</Box>
        <Box sx={{ textAlign: 'center', fontSize: '16px' }}>{project.tagLine}</Box>
        {project.salesOffice ? (
          <Box sx={{ textAlign: 'center', mt: 2, fontSize: '16px' }}>Presentation Gallery: {project.salesOffice}</Box>
        ) : null}
        {schedule && schedule.project ? (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: project.portal.primaryColor,
                ':hover': {
                  backgroundColor: project.portal.primaryColor,
                  color: '#fff',
                },
              }}
              onClick={() =>
                navigate(
                  schedule.shortUrl
                    ? `/book/${schedule.shortUrl}`
                    : `/${urlName(schedule?.project?.developerName!)}/${urlName(schedule?.project?.name!)}/public/book-an-appointment/${
                        schedule._id
                      }`
                )
              }
            >
              Book an Appointment
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const GETSCHEDULEONE = gql`
  query scheduleOne($filter: FilterFindOneScheduleInput) {
    scheduleOne(filter: $filter) {
      project {
        name
        developerName
      }
      shortUrl
      _id
    }
  }
`;

export default Main;
