import React, { useState } from 'react';
import { ICompletionStatus, IWorksheetPurchaserInfo } from '../../types/worksheet';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { MenuItem, FormControl, InputLabel, Button, Checkbox, FormControlLabel, Tooltip, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { WorksheetTextField, smWidth, Heading } from '../../utils/CommonStyles';
import { sanitizeSIN } from '../../utils/Validations';
import { formatSin } from '../../utils/Functions';

const Identification = (props: ChildProps) => {
  const {
    completionStatus,
    fields,
    purchaserCount,
    purchaserNumber,
    disabled,
    handleTextInput,
    handleDateChange,
    handleAddPurchaser,
    handlePurchaserTypeChange,
    handleIdTypeChange,
    handleAcknowledgement,
    handleText,
    noCheckbox,
    style,
    asterisk,
  } = props;
  const { idType, idNumber, idExpiry, idJurisdiction, sin, purchaserType, acknowledgement, corp } = fields;
  const [dateError, setDateError] = useState<string | null>(null);

  const selectStyle = {
    width: '100%',
    '@media (max-width: 480px)': {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        width: '100%',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { border: '1px solid #f54394' },
      '&:hover fieldset': { borderColor: '#f54394' },
      '&.Mui-focused fieldset': { borderColor: '#f54394' },
    },
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  };

  const showButtons = completionStatus.addressDetails && purchaserNumber + 1 === purchaserCount;

  const animationProps = useSpring({
    to: { opacity: completionStatus.addressDetails ? 1 : 0, y: completionStatus.addressDetails ? 0 : 100 },
    from: { opacity: 0, y: 100 },
    delay: 250,
  });

  const locations = [
    'Quebec',
    'Saskatchewan',
    'Manitoba',
    'Alberta',
    'British Columbia',
    'Prince Edward Island',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nunavut',
    'Northwest Territories',
    'Yukon Territory',
    'Nova Scotia',
    'International Government',
    '',
  ];

  const IdTypes = [
    "Driver's License",
    'Photo Card',
    'Passport',
    'Permanent Resident Card',
    'Citizenship Card',
    'Identity Card',
    'Articles of Incorporation',
  ];

  const InputFields = (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="idnumber-textarea"
          label="ID Number"
          name="idNumber"
          sx={idNumber === '' ? style : asterisk}
          value={idNumber}
          onChange={handleTextInput}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Expiry Date"
            value={idExpiry}
            onChange={(newValue: any) => {
              handleDateChange(newValue, 'idExpiry');
            }}
            minDate={new Date()}
            onError={(reason: any, value: any) => {
              switch (reason) {
                case 'invalidDate':
                  setDateError('Invalid Date');
                  break;
                case 'disablePast':
                  setDateError('Invalid Date');
                  break;
                case 'disableFuture':
                  setDateError('Purchaser cannot born in the future!');
                  break;
                case 'maxDate':
                  setDateError('Invalid Date');
                  break;
                case 'minDate':
                  setDateError('ID has expired!');
                  break;
                case 'shouldDisableDate':
                  // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
                  setDateError('Invalid Date');
                  break;
                default:
                  if (idExpiry) {
                    setDateError(null);
                  } else {
                    setDateError('Date cannot be empty');
                  }
              }
            }}
            renderInput={(params: any) => <WorksheetTextField sx={!idExpiry ? style : asterisk} {...params} />}
            disabled={disabled}
          />
          {dateError ? <DateError>{dateError}</DateError> : null}
        </LocalizationProvider>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <FormControl
          sx={
            idType === ''
              ? selectStyle
              : {
                  width: '100%',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    '.MuiInputBase-root': {
                      width: '100%',
                    },
                  },
                  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                }
          }
          disabled={disabled}
        >
          <InputLabel id="demo-simple-select-label">ID Type</InputLabel>
          <Select labelId="demo-simple-select-label" id="idType" value={idType} label="ID Type" onChange={handleIdTypeChange} required>
            {IdTypes.map((IdType: string) => {
              return <MenuItem value={`${IdType}`}>{IdType}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item lg={4} md={4} sm={4} xs={12}>
        <FormControl
          sx={{
            width: '100%',
            '@media (max-width: 480px)': {
              width: '100%',
              '.MuiInputBase-root': {
                width: '100%',
              },
            },
          }}
          disabled={disabled}
        >
          <InputLabel id="demo-simple-select-label">ID Issued By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="idJurisdiction"
            id="idissuedby-textarea"
            label="ID Issued By"
            value={idJurisdiction}
            onChange={handleTextInput}
            disabled={disabled}
            required
          >
            <MenuItem value={'Ontario'} selected>
              Ontario
            </MenuItem>
            {locations.map((location: string) => {
              return <MenuItem value={`${location}`}>{location}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Tooltip title="Non-mandatory">
          <WorksheetTextField
            id="sinnumber-textarea"
            label="SIN Number (xxx xxx xxx)"
            name="sin"
            error={!disabled && sin.length !== 11 && sin !== ''}
            helperText={!disabled && sin.length ? 'Please enter a valid SIN number' : ''}
            onChange={(e) => {
              handleText(e.target.name, sanitizeSIN(e.target.value));
            }}
            value={formatSin(sin)}
            disabled={disabled}
          />
        </Tooltip>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <FormControl
          sx={
            idType === ''
              ? selectStyle
              : {
                  width: '100%',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    '.MuiInputBase-root': {
                      width: '100%',
                    },
                  },
                  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                }
          }
          disabled={disabled}
        >
          <InputLabel id="demo-simple-select-label">Purchaser Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="purchaserType"
            value={purchaserType}
            label="Purchaser Type"
            onChange={handlePurchaserTypeChange}
          >
            <MenuItem value={'Investor'}>Investor</MenuItem>
            <MenuItem value={'End User'}>End User</MenuItem>
            <MenuItem value={'Both'}>Both</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <div>
      {corp
        ? completionStatus.signingOfficer && (
            <IdentificationContainer container spacing={2}>
              <Heading item lg={3} md={4} sm={12} xs={12}>
                <animated.div style={animationProps}>Identification</animated.div>
              </Heading>

              <Grid item lg={9} md={8} sm={12} xs={12}>
                <animated.div style={animationProps}>{InputFields}</animated.div>
                {noCheckbox ? null : (
                  <FormControlLabel
                    sx={{
                      marginTop: '10px',
                      marginBottom: '15px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      '.MuiTypography-root': {
                        marginTop: '8px',
                      },
                    }}
                    control={
                      <Checkbox
                        checked={acknowledgement}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAcknowledgement(event)}
                      />
                    }
                    label="I acknowledge that the information submitted above is complete and correct. For identification that does not include an address, an official document stating the purchaser's address (also known as proof of address) has been included in this worksheet or will be included within 48 hours."
                  />
                )}

                <div style={{ marginTop: '5px' }}>
                  {showButtons && (
                    <Button
                      sx={{
                        '@media (max-width: 480px)': {
                          width: '100%',
                          marginBottom: '10px',
                        },
                      }}
                      disableElevation
                      onClick={handleAddPurchaser}
                      variant="contained"
                    >
                      Add Another Purchaser
                    </Button>
                  )}
                </div>
              </Grid>
            </IdentificationContainer>
          )
        : completionStatus.addressDetails && (
            <IdentificationContainer container spacing={2}>
              <Heading item lg={3} md={4} sm={12} xs={12}>
                <animated.div style={animationProps}>Identification</animated.div>
              </Heading>

              <Grid item lg={9} md={8} sm={12} xs={12}>
                <animated.div style={animationProps}>{InputFields}</animated.div>
                {noCheckbox ? null : (
                  <FormControlLabel
                    sx={{
                      marginTop: '10px',
                      marginBottom: '15px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      '.MuiTypography-root': {
                        marginTop: '8px',
                      },
                    }}
                    control={
                      <Checkbox
                        checked={acknowledgement}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAcknowledgement(event)}
                      />
                    }
                    label="I acknowledge that the information submitted above is complete and correct. For identification that does not include an address, an official document stating the purchaser's address (also known as proof of address) has been included in this worksheet or will be included within 48 hours."
                  />
                )}

                <div style={{ marginTop: '5px' }}>
                  {showButtons && (
                    <Button
                      sx={{
                        '@media (max-width: 480px)': {
                          width: '100%',
                          marginBottom: '10px',
                        },
                      }}
                      disableElevation
                      onClick={handleAddPurchaser}
                      variant="contained"
                    >
                      Add Another Purchaser
                    </Button>
                  )}
                </div>
              </Grid>
            </IdentificationContainer>
          )}
    </div>
  );
};

interface ChildProps {
  fields: IWorksheetPurchaserInfo;
  completionStatus: ICompletionStatus;
  worksheetId?: string;
  purchaserCount: number;
  purchaserNumber: number;
  setFileImage?: any;
  handleTextInput: any;
  handleDateChange: any;
  handlePurchaserTypeChange: any;
  handleIdTypeChange: any;
  handleAcknowledgement: any;
  handleAddPurchaser: any;
  disabled?: boolean;
  subheading?: string;
  handleText: (name: string, value: string) => void;
  noCheckbox?: boolean;
  style?: object;
  asterisk?: object;
}

const IdentificationContainer = styled(Grid)`
  display: flex;
  margin-top: 50px !important;

  & .MuiIconButton-root {
    margin-top: 0 !important;
    border: none !important;
    width: auto !important;
  }

  @media (max-width: ${smWidth}) {
    display: block;
    width: 100%;
  }
`;

const DateError = styled.p`
  font-size: 0.75rem;
  color: #f54394;
  margin: 3px 14px 0 14px;
`;

export default Identification;
