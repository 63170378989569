import { useState } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import { Box } from '@mui/material';
import styled from 'styled-components';

import { Title } from '../../utils/CommonStyles';
import { getVideoId } from '../../utils/Functions';
import { IPortal } from '../../types/portal';
import { IMedia } from '../../types/portal';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const Videos = (props: ChildProps) => {
  const { portal } = props;
  const [video, setVideo] = useState<number | null>(window.innerWidth < 768 ? null : 0);

  const YoutubeSlide = ({ url, index }: { url?: string; isSelected?: boolean; index?: number }) => (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      playing={index === video ? true : false}
      controls
      light={false}
    />
  );

  const customRenderItem = (item: any, props: any) => {
    return (
      <div>
        <h1>{item.props.name}</h1> <item.type {...item.props} {...props} />
      </div>
    );
  };

  const customRenderThumb = (children: any[]) =>
    children.map((item, index) => {
      if (item.props.children[1].props.children.props.url.indexOf('youtu') !== -1) {
        let videoThumbnail = getVideoId(item.props.children[1].props.children.props.url, 'youtube');
        if (videoThumbnail.length > 0) {
          videoThumbnail = videoThumbnail[2];
        } else videoThumbnail = '';
        return <img key={index} src={`https://img.youtube.com/vi/${videoThumbnail}/default.jpg`} alt="video" />;
      } else {
        let videoThumbnail = getVideoId(item.props.children[1].props.children.props.url, 'vimeo');
        if (videoThumbnail.length > 0) {
          videoThumbnail = videoThumbnail[1];
        } else videoThumbnail = '';
        return <img style={{ height: '54px' }} key={index} src={`https://vumbnail.com/${videoThumbnail}.jpg`} alt="video" />;
      }
    });

  const handleClick = (index: number, item: any) => {
    setVideo(index);
  };

  return (
    <section>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%'
        }}
      >
        <StyledCarousel
          secondarycolor={portal.secondaryColor}
          renderItem={customRenderItem}
          renderThumbs={customRenderThumb}
          onClickThumb={handleClick}
        >
          {portal.videos.map((video: IMedia, index: number) => {
            return (
              <Box sx={{ color: '#fff' }} key={index}>
                <Title>{video.name}</Title>
                <Box
                  sx={{
                    position: 'relative',
                    height: {
                      sm: 300,
                      md: 400,
                      desktop: 600,
                    },
                    maxWidth: '100%',
                    '@media (max-width: 600px)': {
                      width: '600px',
                      height: '300px',
                    },
                  }}
                >
                  <YoutubeSlide key={index} url={video.getUrl} index={index} />
                </Box>
              </Box>
            );
          })}
        </StyledCarousel>
      </Box>
    </section>
  );
};

interface ChildProps {
  portal: IPortal;
}

const StyledCarousel = styled(Carousel)<{ secondarycolor?: string }>`
  .thumbs.animated {
    padding-left: 20px;
    text-align: center;
  }
  .carousel .selected {
    border: none;
  }
  .thumb:hover {
    border: 2px solid ${(props) => (props.secondarycolor ? props.secondarycolor : '#000')};
    border-radius: 5px;
  },
  .control-dots {
    bottom: -45px;
  },
  height: 400px;
`;

export default Videos;
