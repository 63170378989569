import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Card, CardMedia, Grid } from '@mui/material';
import { IProject } from '../types/project';
import { urlName } from '../utils/Functions';
import { IUser } from '../types/user';

const ProjectCard = (props: ChildProps) => {
  const { project, params, user } = props;

  const getLink = () => {
    if (params.developerName === "project-list") {
      if (user) {
        return `${`/dashboard/${urlName(project.name)}`}`
      }
    }

    if (project.portal.vipActive) {
      return `${`/${urlName(project.developerName)}/${urlName(project.name)}/vip`}`
    } 

    if (project.portal.active) {
      return `${`/${urlName(project.developerName)}/${urlName(project.name)}/public`}`
    } 
    
  }

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Link to={getLink()!}>
        <CardContainer>
          {project.imageGetUrl ? <Media image={project.imageGetUrl} src={project.imageGetUrl} title={project.name} /> : null}
          <CardTitle>
            <Title>{project.name}</Title>
            {project.portal.subText ?
            <SubText>{project.portal.subText}</SubText>
            : null}
          </CardTitle>
        </CardContainer>
      </Link>
    </Grid>
  );
};

interface ChildProps {
  project: IProject;
  user: IUser | null;
  params: any;
}

const CardContainer = styled(Card)({
  height: 250,
  width: '100%',
  position: 'relative',
  transition: 'transform 0.5s ease-in-out',
  ':hover': {
    transform: 'scale(1.03)',
  },
  border: '1px solid #000',
});

const Media = styled(CardMedia)({
  height: '100%',
  width: '100%',
});

const CardTitle = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffbc00;
  padding: 10px;
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
`;

const SubText = styled.div`
  font-size: 10px;
  margin-top: 10px;
`;

export default ProjectCard;
