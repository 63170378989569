import React from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { smWidth, WorksheetTextField, DealsPageLine, Heading } from '../../utils/CommonStyles';
import { IMortgage } from '../../types/deal';
import { Document, Page } from 'react-pdf';

const MortgagePreapproval = (props: ChildProps) => {
  const { mortgage } = props;

  return (
    <div>
      <DealsPageLine />
      <h1 style={{textAlign: "center"}}>Mortgage Preapproval</h1>
      <MortgagePreapprovalContainer container spacing={2}>
        <Heading item lg={3} md={4} sm={12} xs={12}>Mortgage Information</Heading>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <WorksheetTextField id="mortgage-lender" label="Lender" required value={mortgage.lender} disabled />
            </Grid>
            <Grid item xs={12}>
              <WorksheetTextField id="mortgage-amount" label="Amount" required value={mortgage.amount} disabled />
            </Grid>
            <Grid item xs={12}>
              {mortgage.getUrl ?
                <Document file={mortgage.getUrl} onLoadError={console.error}>
                  <MortgagePage pageNumber={1} />
                </Document>
              : <div>Mortgage has not been received</div>
              }
            </Grid>
          </Grid>
        </Grid>
      </MortgagePreapprovalContainer>
    </div>
  );
};

const MortgagePreapprovalContainer = styled(Grid)`
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

const MortgagePage = styled(Page)`
  box-shadow: 0 15px 15px rgb(0 0 0/6%);
  margin: 1em;
  canvas {
    max-width: 100%;
    height: auto !important;
  }
`;

interface ChildProps {
  mortgage: IMortgage;
}

export default MortgagePreapproval;
