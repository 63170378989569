import React from 'react';
import { Buffer } from 'buffer';
import { gql, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { DealsPageLine } from '../../utils/CommonStyles';
import { IDocument } from '../../types/document';
import { Button, Divider, CircularProgress, Typography } from '@mui/material';

const DealDocuments = (props: ChildProps) => {
  const { documents } = props;

  const [getPreviewData, { loading: previewLoading }] = useLazyQuery(GETPREVIEWDATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const binaryData = Buffer.from(data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);

      const element = document.createElement('a');
      element.href = previewUrl;
      element.target = '_blank';
      element.click();
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const previewDocument = (doc: any) => {
    getPreviewData({
      variables: {
        _id: doc._id,
      },
    });
  };

  return (
    <div>
      <DealsPageLine />
      <h1 style={{ textAlign: 'center' }}>Documents</h1>
      {documents.length > 0 ? (
        <DealDocumentsContainer>
          {documents.map((dealDocument: IDocument, i: number) => (
            <DocumentContainer key={i}>
              <Typography gutterBottom variant="h4">
                {dealDocument.name}
              </Typography>
              <DocumentDivider variant="middle" flexItem />
              <Button color="primary" variant="contained" onClick={() => previewDocument(dealDocument)} sx={{ marginTop: '10px' }}>
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
            </DocumentContainer>
          ))}
        </DealDocumentsContainer>
      ) : (
        <div>There are no documents for this deal.</div>
      )}
    </div>
  );
};

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

const DealDocumentsContainer = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 30px;
  height: 100%;
`;

const DocumentDivider = styled(Divider)`
  margin-bottom: 15px;
`;

const DocumentContainer = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 1rem;
  margin-right: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
  }
`;

interface ChildProps {
  documents: IDocument[];
}

export default DealDocuments;
