import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { IFloorplan, IFloorplanMany } from '../../types/floorplan';
import { Grid, Button, Box, Typography } from '@mui/material';
import Floorplan from './Floorplan';
import { IUnit, IUnitData } from '../../types/unit';
import LoadingWrapper from '../common/LoadingWrapper';
import { blobToBase64, downloadImages, imageUrlsToBlob } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { FlexBetween } from '../../utils/CommonStyles';

const FloorplansGrid = () => {
  const [floorplans, setFloorplans] = useState<IFloorplan[]>();
  const [floorplanURLs, setFloorplanURLs] = useState<any>([]);
  const [availableUnits, setAvailableUnits] = useState<Set<string>>(new Set());
  const { project } = useSelector(selectProject);

  useQuery<IUnitData>(GETUNITS, {
    variables: { project: project._id },
    onCompleted: (data) => {
      const unitSet = new Set<string>();
      data.getAvailableUnits.forEach((unit: IUnit) => {
        unitSet.add(unit.modelType);
      });
      setAvailableUnits(unitSet);
    },
  });

  const { loading } = useQuery<IFloorplanMany>(GETFLOORPLANS, {
    variables: { filter: { project: project._id } },
    onCompleted: async (data) => {
      let filteredFloorPlans: IFloorplan[] = [];
      let filteredFloorPlanURLs: string[] = [];
      data.marketingFloorPlansMany.map((floorplan: IFloorplan) => {
        if (availableUnits.has(floorplan.modelType)) {
          filteredFloorPlans.push(floorplan);
          filteredFloorPlanURLs.push(floorplan.getUrl);
        }
        return null;
      });
      setFloorplans(filteredFloorPlans);
      let renderingUrl = await data.marketingFloorPlansMany.map((floorplan: IFloorplan) => floorplan.getUrl);
      let renderingsBlobs = await imageUrlsToBlob(renderingUrl, floorplanURLs);
      let renderingsBase64 = await blobToBase64(renderingsBlobs);
      let allBlobs = renderingsBase64.map((renderings: any) => {
        return {
          blob: renderings,
        };
      });
      setFloorplanURLs(allBlobs);
    },
    onError: (error) => {
      console.log(error, 'err');
    },
  });

  return loading ? (
    <LoadingWrapper title={'Floor plans are loading...'} modal={false} />
  ) : (
    <Box sx={{ mb: 2 }}>
      <FlexBetween sx={{ backgroundColor: 'secondary.main', p: 3 }}>
        <Typography sx={{ alignItems: 'center', mb: 0 }} gutterBottom variant="h2">
          Floor Plans
        </Typography>
        {floorplans && floorplans.length > 0
          ? floorplanURLs && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => downloadImages(floorplanURLs, `${project.name} Floorplans`, project)}
              >
                Download Floor Plans
              </Button>
            )
          : null}
      </FlexBetween>
      <Box sx={{ px: 3, mt: 2 }}>
        {floorplans && floorplans.length > 0 ? (
          <>
            <Grid container spacing={3}>
              {floorplans.map((floorplan: IFloorplan, i: number) => (
                <Grid key={i} item lg={4} md={6} sm={6} xs={12}>
                  <Floorplan floorplan={floorplan} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <em>There are currently no floorplans available.</em>
        )}
      </Box>
    </Box>
  );
};

const GETFLOORPLANS = gql`
  query marketingFloorPlansMany($filter: FilterFindManymarketingFloorPlansInput!) {
    marketingFloorPlansMany(filter: $filter) {
      project {
        name
      }
      modelName
      modelType
      unitType
      size
      _id
      getUrl
      putUrl
    }
  }
`;

const GETUNITS = gql`
  query getAvailableUnits($project: MongoID!) {
    getAvailableUnits(project: $project) {
      modelType
    }
  }
`;

export default FloorplansGrid;
