import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useProjectQuery } from '../../features/project/projectHooks';
import { usePortalColorQuery } from '../../features/portal/portalHooks';
import Header from '../menus/Header';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import Footer from '../menus/Footer';

const DashboardOutlet = () => {
  const { projectName } = useParams();
  const { project } = useSelector(selectProject);

  const { loading } = useProjectQuery(projectName!);
  usePortalColorQuery(project._id!);

  return loading ? null : project ? (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header authenticated={true} />
      <Box sx={{ mt: 8 }}>
        <Outlet />
      </Box>
    </Box>
  ) : null;
};

export default DashboardOutlet;
