import { useState } from 'react';
import { StyledTextField } from '../../utils/CommonStyles';
import styled from 'styled-components';
import { Button, Link } from '@mui/material';
import { useLogin } from '../../features/auth/authHooks';

const LoginForm = (props: ChildProps) => {
  const { setSelector } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordHasValue, setPasswordHasValue] = useState(false);
  const [emailHasValue, setEmailHasValue] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const login = useLogin();
  const hasValue = (value: string) => value !== "";

  const handleSubmit = () => {
    login(email, password);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailHasValue(hasValue(event.target.value));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordHasValue(hasValue(event.target.value));
  };

  const handleRedirect = (selector: string) => {
    setSelector(selector);
  };

  const makeAnimationStartHandler = (stateSetter: any) => (e: any) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill') {
      stateSetter(autofilled);
    }

    if (e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(autofilled);
    }
  };

  return (
    <div>
      <Login>
        <LogoImgContainer>
          <LogoImg src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png" alt="Logo" />
        </LogoImgContainer>
        <EmailPassword>
          <TitleContainer>
            <BrokerTitle>Broker Portal</BrokerTitle>
            <LoginTitle>
              <LoginLongText>Log in to see the dashboard</LoginLongText>
              <LoginShortText>Log in</LoginShortText>
            </LoginTitle>
          </TitleContainer>

          <EmailPasswordForm
            id="loginForm"
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <StyledTextField
              inputProps={{
                onAnimationStart: makeAnimationStartHandler(setEmailHasValue),
              }}
              InputLabelProps={{
                shrink: emailHasValue || isEmailFocused
              }}
              fullWidth
              label={'Email'}
              variant="outlined"
              required
              value={email}
              onChange={handleEmailChange}
              onFocus={() => setIsEmailFocused(true)}
              onBlur={() => setIsEmailFocused(false)}
            />
            <StyledTextField
              fullWidth
              inputProps={{
                onAnimationStart: makeAnimationStartHandler(setPasswordHasValue),
              }}
              InputLabelProps={{
                shrink: passwordHasValue || isPasswordFocused
              }}
              label={"Password"}
              type="password"
              autoComplete="current-password"
              variant="outlined"
              required
              value={password}
              onChange={handlePasswordChange}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
            />
          </EmailPasswordForm>
          <ForgotPassword>
            <Link
              component="button"
              underline="hover"
              variant="body2"
              onClick={() => {
                handleRedirect('resetPassword');
              }}
            >
              Forgot password?
            </Link>
          </ForgotPassword>
          <Button type="submit" variant="contained" id="logInBtn" form="loginForm" size="large">
            Log In
          </Button>
          <div>
            <CreateAccountPrompt> Not registered yet? </CreateAccountPrompt>
            <Link
              component="button"
              underline="hover"
              variant="body2"
              onClick={() => {
                handleRedirect('signup');
              }}
            >
              <span style={{ fontWeight: 600 }}>Create an account</span>
            </Link>
          </div>
        </EmailPassword>
      </Login>
    </div>
  );
};

const Login = styled.div`
  position: absolute;
  left: 0;
  background: white;

  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  padding-top: 5%;
  padding-bottom: 5%;

  @media (max-width: 850px) {
    width: 100% !important;
  }

  @media (max-height: 700px) {
    padding-top: 0;
  }
`;

const TitleContainer = styled.div`
  width: 70%;
  height: 20%;
`;

const BrokerTitle = styled.h1`
  margin: 0;
  white-space: nowrap;
  @media (max-height: 600px) {
    display: none;
  }
`;

const LoginTitle = styled.h5`
  margin: 0;
  @media (max-height: 600px) {
    font-size: 20px;
  }
`;

const LoginLongText = styled.span`
  display: block;
  @media (max-height: 600px) {
    display: none;
  }
`;

const LoginShortText = styled.span`
  display: none;
  @media (max-height: 600px) {
    display: block;
  }
`;

const EmailPassword = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 70%;

  max-height: 400px;
  max-width: 500px;

  @media (max-height: 600px) {
    height: 70%;
  }
`;

const EmailPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%;
  min-height: 130px;
  @media (max-height: 600px) {
    min-height: 130px;
  }
`;

const ForgotPassword = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogoImgContainer = styled.div`
  height: 15%;
  width: 70%;
  margin-top: 5%;
  max-width: 500px;

  display: flex;
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const CreateAccountPrompt = styled.p`
  display: inline;
  font-size: 14px;
`;

interface ChildProps {
  setSelector: (selector: string) => void;
  history: any;
}

export default LoginForm;
