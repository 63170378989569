import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, Menu, MenuItem, Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withRouter } from '../../utils/WithRouter';
import styled from 'styled-components';
import { IProject, IActiveProjects } from '../../types/project';
import Logo from '../../assets/rds-logo-transparent.png';
import SideMenu from './SideMenu';
import { theme } from '../../theme/theme';
import { urlName } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useLogout } from '../../features/auth/authHooks';

const Header = (props: any) => {
  const { authenticated } = props;
  const { project } = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [menuType, setMenuType] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const logout = useLogout();

  const projectId = project._id;
  const openMenu = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:600px)');

  useQuery<IActiveProjects>(ACTIVEPROJECTS, {
    skip: !authenticated,
    onCompleted: (data) => {
      setProjects(data.getAllActiveProjects);
    },
  });

  const handleMenuOpen = (event: any) => {
    setMenuType(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = () => {
    console.log('open mobile menu');
  };

  return (
    <div>
      <AppBar style={{ zIndex: 1300 }} color="primary" position="fixed">
        <ToolBar color="primary">
          {!user ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <img src={Logo} width={50} height={50} alt="RDS" />
              <h2>{project.name}</h2>
              <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/${urlName(project.name)}`)}>
                {isMobile ? <ArrowBackIcon /> : 'Back to Portal'}
              </div>
            </Box>
          ) : (
            <>
              <LeftSideHeader>
                {user && project ? (
                  <IconButton
                    style={{ margin: '0 16px 0 -8px' }}
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpenDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : null}
                <DrawerMenu sx={{ zIndex: 2001 }} anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                  <SideMenu project={project} handleClose={() => setOpenDrawer(false)} />
                </DrawerMenu>
                <Link to={`/dashboard/${urlName(project.name)}`}>
                  <img src={Logo} width={50} height={50} alt="RDS" />
                </Link>
              </LeftSideHeader>
              <RightSideHeader>
                {user && project ? (
                  <ProjectName id="project" onClick={handleMenuOpen}>
                    {project.name}
                  </ProjectName>
                ) : null}
                <div id="user" onClick={handleMenuOpen}>
                  {user.firstName} {user.lastName}
                </div>
              </RightSideHeader>
              <MobileMenu>
                <IconButton
                  aria-label="show more"
                  aria-controls="primary-search-account-menu-mobile"
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </MobileMenu>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openMenu}
                onClick={handleClose}
                onClose={handleClose}
              >
                {menuType === 'project' ? (
                  projects.map((project: IProject, index: number) => {
                    if (project._id !== projectId) {
                      return (
                        <MenuItem
                          key={index}
                          sx={{
                            color: theme.palette.primary.main,
                            ':hover': {
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff',
                            },
                            ':hover .MuiListItemIcon-root': {
                              color: '#fff',
                            },
                          }}
                          component={Link}
                          to={`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}`}
                          onClick={() => setAnchorEl(null)}
                        >
                          {project.name}
                        </MenuItem>
                      );
                    } else return null;
                  })
                ) : (
                  <div>
                    <MenuItem
                      sx={{
                        color: theme.palette.primary.main,
                        ':hover': {
                          backgroundColor: theme.palette.primary.main,
                          color: '#fff',
                        },
                        ':hover .MuiListItemIcon-root': {
                          color: '#fff',
                        },
                      }}
                      component={Link}
                      to={`/dashboard/${urlName(project.name)}/profile`}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: theme.palette.primary.main,
                        ':hover': {
                          backgroundColor: theme.palette.primary.main,
                          color: '#fff',
                        },
                        ':hover .MuiListItemIcon-root': {
                          color: '#fff',
                        },
                      }}
                      onClick={() => logout()}
                    >
                      Log Out
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </>
          )}
        </ToolBar>
      </AppBar>
    </div>
  );
};

interface ChildProps {
  authenticated: boolean;
  projects?: IProject[];
  project?: IProject;
  match: {
    url: string;
    isExact: boolean;
    path: string;
    params: {
      projectid: string;
      developerName: string;
    };
  };
  title?: string;
}

const ToolBar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const DrawerMenu = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: 250,
  },
});

const MobileMenu = styled.div`
  display: none;
`;

const RightSideHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const ProjectName = styled.div`
  margin-right: 10px;
`;

const LeftSideHeader = styled.div`
  display: flex;
`;

const ACTIVEPROJECTS = gql`
  query getAllActiveProjects($developer: String) {
    getAllActiveProjects(developer: $developer) {
      _id
      name
    }
  }
`;

export default withRouter(Header);
