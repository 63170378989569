import { useQuery, gql } from "@apollo/client";

import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import { setPortal, setPortalColor } from "./portalSlice";

export const usePortalQuery = (id: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPORTAL, {
    skip: !id,
    variables: { filter: { project: id } },
    onCompleted: (data) => {
      dispatch(setPortal(data.portalOne));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const usePortalColorQuery = (id: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPORTALCOLOR, {
    skip: !id,
    variables: { filter: { project: id } },
    onCompleted: (data) => {
      dispatch(setPortalColor(data.portalOne.primaryColor));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETPORTALCOLOR = gql`
  query portalOne($filter: FilterFindOnePortalInput) {
    portalOne(filter: $filter) {
      primaryColor
    }
  }
`;

const GETPORTAL = gql`
  query portalOne($filter: FilterFindOnePortalInput) {
    portalOne(filter: $filter) {
      _id
      project {
        _id
      }
      active
      vipActive
      showHotlist
      sortHotlist
      showCustom
      createWorksheets
      showOptionsPrice
      showUnitTypes
      showOptions
      password
      information
      promo
      renderings {
        _id
        getUrl
        name
      }
      photos {
        _id
        getUrl
        name
      }
      pdfs {
        _id
        getUrl
        name
      }
      logos {
        _id
        name
        getUrl
      }
      aerialPhotos {
        _id
        name
        getUrl
      }
      bRoll {
        _id
        name
        getUrl
      }
      officialSite
      instaUrl
      primaryColor
      secondaryColor
      brochure
      brochureGetUrl
      brochurePutUrl
      subText
      videos {
        name
        getUrl
      }
    }
  }
`;
