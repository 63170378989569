import { Snackbar as MuiSnackbar, Alert } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectOpen, selectMessage, selectSeverity, setOpen } from "./snackbarSlice";

export function Snackbar() {
  const open = useAppSelector(selectOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);
  const dispatch = useAppDispatch();

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={() => dispatch(setOpen(false))}
    >
      <Alert severity={severity} onClose={() => dispatch(setOpen(false))} sx={{ height: "100%", width: "100%" }}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
