/* Dependency Imports */
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';

/* Project Imports */
import { useProjectQuery } from '../../features/project/projectHooks';
import { useUserQuery } from '../../features/auth/authHooks';
import { selectUser } from '../../features/auth/authSlice';
import { selectProject } from '../../features/project/projectSlice';
import LoginPopup from '../login/LoginPopup';
import { urlName, hyphenToTitle } from '../../utils/Functions';

const RequireAuth = () => {
  /* Redux */
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { project } = useSelector(selectProject);
  const { projectName, scheduleid } = useParams();
  const { loading, data } = useProjectQuery(projectName!);
  useUserQuery();

  const checkPortalAccess = () => {
    if (!data.projectOne) {
      navigate('/');
    }
    if (
      hyphenToTitle(location.pathname.split('/')[1]).toLocaleLowerCase() === project.name.toLocaleLowerCase() &&
      project.portal.vipActive &&
      !project.portal.active
    ) {
      return false;
    }
    if (hyphenToTitle(location.pathname.split('/')[1]).toLocaleLowerCase() === project.name.toLocaleLowerCase() && project.portal.active) {
      return true;
    }
    if (location.pathname.split('/')[3] === 'vip' && project.portal.vipActive) {
      if (!user) {
        return false;
      } else {
        return true;
      }
    }
    if (location.pathname.split('/')[3] === 'vip' && !project.portal.vipActive) {
      if (project.portal.active) {
        navigate(`/${urlName(project.developerName)}/${urlName(project.name)}/public`);
        return;
      }
      navigate('/');
    }
    if (location.pathname.split('/')[3] === 'public' && project.portal.active) {
      return true;
    }
    if (location.pathname.split('/')[3] === 'public' && !project.portal.active && scheduleid) {
      return true;
    }
    if (location.pathname.split('/')[3] === 'public' && !project.portal.active) {
      navigate('/');
    }
    return true;
  };

  return loading || (project && !project._id) ? null : checkPortalAccess() ? (
    <Outlet />
  ) : (
    <Box sx={{ backgroundColor: '#002044' }}>
      <LoginPopup project={project._id} />
    </Box>
  );
};

export default RequireAuth;
