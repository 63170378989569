import React from 'react';
import styled from 'styled-components';
import { IFloorplan } from '../../types/floorplan';
import Zoom from 'react-medium-image-zoom';
import { Button } from '@mui/material';
import { theme } from '../../theme/theme';
import { urlName } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { useNavigate } from 'react-router-dom';

const Floorplan = (props: ChildProps) => {
  const { floorplan } = props;
  const navigate = useNavigate();
  const { project } = useSelector(selectProject);

  return (
    <FloorplanContainer>
      <InfoWrapper>
        {floorplan.modelName && (
          <InfoContainer>
            <ContentHeader>Model Name</ContentHeader>
            <ContentInfo>{floorplan.modelName}</ContentInfo>
          </InfoContainer>
        )}
        <InfoContainer style={{ textAlign: 'left' }}>
          <ContentHeader>Model Type</ContentHeader>
          <ContentInfo>{floorplan.modelType}</ContentInfo>
        </InfoContainer>
        <InfoContainer style={{ textAlign: 'center' }}>
          <ContentHeader>Unit Type</ContentHeader>
          <ContentInfo>{floorplan.unitType}</ContentInfo>
        </InfoContainer>
        <InfoContainer style={{ textAlign: 'right' }}>
          <ContentHeader>Size</ContentHeader>
          <ContentInfo>{floorplan.size}</ContentInfo>
        </InfoContainer>
      </InfoWrapper>
      <Zoom>
        <FloorplanImg width={'100%'} height={'100%'} src={floorplan.getUrl} alt={`${floorplan.modelName}Floorplan`} />
      </Zoom>
      {project.portal.createWorksheets ? (
        <WorksheetButton
          disableElevation
          variant="contained"
          style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }}
          onClick={() => {
            navigate(`/dashboard/${urlName(project.name)}/create-worksheets`, {
              state: {
                unitType: floorplan.unitType,
                modelType: floorplan.modelType,
              },
            });
          }}
        >
          Create Worksheet
        </WorksheetButton>
      ) : null}
    </FloorplanContainer>
  );
};

const InfoWrapper = styled.div`
  background-color: ${theme.palette.primary.main};
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  filter: drop-shadow(0px 0px 2px #c4c4c4);
`;

const FloorplanImg = styled.img`
  filter: drop-shadow(0px 0px 2px #c4c4c4);
`;

const ContentHeader = styled.h4`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 5px;
  color: ${theme.palette.secondary.main};
`;

const ContentInfo = styled.h4`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 5px;
`;

const WorksheetButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  filter: drop-shadow(0px 0px 2px #c4c4c4);
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const FloorplanContainer = styled.div`
  // border: 1px solid #c4c4c4;
  // border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 5%;
  padding-bottom: 0px;
  padding-top: 0px;
`;

interface ChildProps {
  floorplan: IFloorplan;
}

export default Floorplan;
