import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CustomDialog = (props: IDialog) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={props.maxWidth}
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>{props.dialogContent}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.handleCloseRemove} color="primary">
            {props.removeButton}
          </Button>
          {props.secondOption ? (
            <Button variant="contained" onClick={props.handleCloseSecond} color="primary" autoFocus>
              {props.secondOption}
            </Button>
          ) : null}
          <Button variant="contained" onClick={props.handleCloseSuccess} color="primary" autoFocus>
            {props.successButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface IDialog {
  handleClose: () => void;
  handleCloseRemove: () => void;
  handleCloseSuccess: () => void;
  handleCloseSecond?: () => void;
  open: boolean;
  successButton: string;
  removeButton: string;
  dialogContent: any;
  dialogTitle: string;
  checked?: boolean;
  secondOption?: string;
  maxWidth?: any;
}

//   padding: {
//     '& .MuiDialog-paper': {
//       padding: '20px',
//     },

export default CustomDialog;
