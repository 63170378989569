import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

type alertSeverity = "error" | "warning" | "info" | "success";

interface SnackbarState {
  open: boolean;
  message: string;
  severity: alertSeverity;
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: "error",
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    showErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
      state.severity = "error";
    },
    showWarningSnackbar: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
      state.severity = "warning";
    },
    showInfoSnackbar: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
      state.severity = "info";
    },
    showSuccessSnackbar: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
      state.severity = "success";
    },
  },
});

export const { setOpen, showErrorSnackbar, showWarningSnackbar, showInfoSnackbar, showSuccessSnackbar } = snackbarSlice.actions;

export const selectOpen = (state: RootState) => state.snackbar.open;
export const selectMessage = (state: RootState) => state.snackbar.message;
export const selectSeverity = (state: RootState) => state.snackbar.severity;

export default snackbarSlice.reducer;
