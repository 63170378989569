import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Button, Link } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { Background, ResetPasswordContainer, LogoImgContainer, ResetPasswordForm } from '../../utils/CommonStyles';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const ResetPasswordScreen = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { setSelector, forgotPassword } = props;
  const [email, setEmail] = useState('');

  const [emailPasswordReset] = useMutation(EMAILPASSWORDRESET, {
    variables: { email: email },
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Email sent'))
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleRedirect = (selector: string) => {
    setSelector(selector);
  };

  const handleSubmit = () => {
    emailPasswordReset();
  };

  return (
    <Background>
      <ResetPasswordContainer>
        <LogoImgContainer>
          <LogoImg src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png" />
        </LogoImgContainer>
        <Title>{forgotPassword ? `Forgot Password` : `Change Password`}</Title>
        <ResetPasswordForm
          id="resetPasswordForm"
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <TextField
            name="email"
            fullWidth
            required
            label="Email"
            variant="standard"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(e)}
          />
          <ResetPasswordBtnContainer>
            <Button variant="contained" disableElevation fullWidth type="submit">
              Send Email
            </Button>
          </ResetPasswordBtnContainer>
        </ResetPasswordForm>
        {forgotPassword && (
          <Link
            component="button"
            underline="hover"
            variant="body2"
            onClick={() => {
              handleRedirect('login');
            }}
          >
            Back to log in
          </Link>
        )}
      </ResetPasswordContainer>
    </Background>
  );
};

interface ChildProps {
  forgotPassword?: boolean;
  setSelector: any;
}

const LogoImg = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

const Title = styled.p`
  font-size: 24px;
`;

const ResetPasswordBtnContainer = styled.div`
  margin-top: 5%;
`;

const EMAILPASSWORDRESET = gql`
  mutation userEmailResetPassword($email: String!) {
    userEmailResetPassword(email: $email)
  }
`;

export default ResetPasswordScreen;
