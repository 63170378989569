import { useEffect, useMemo, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import throttle from 'lodash/throttle';

function extractFromAddress(components: any, type: string) {
  for (var i = 0; i < components.length; i++)
    for (var j = 0; j < components[i].types.length; j++) if (components[i].types[j] === type) return components[i].long_name;
  return '';
}

const GooglePlaces = (props: ChildProps) => {
  const { handleDetails, handleTextInput, address, type, style, asterisk } = props;
  const [value, setValue] = useState<any>(address);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  let map = new google.maps.Map(document.createElement('div'));

  let service = new google.maps.places.PlacesService(map);
  const autocompleteService = { current: null };

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
      // eslint-disable-next-line
    []
  );

  useEffect(() => {
    let active = true;

    if (value.place_id) {
      service.getDetails(
        {
          placeId: value.place_id,
        },
        function (place, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (place && place.address_components && place.address_components.length > 0) {
              let postalCode = extractFromAddress(place.address_components, 'postal_code');
              let city = extractFromAddress(place.address_components, 'locality');
              let province = extractFromAddress(place.address_components, 'administrative_area_level_1');
              let country = extractFromAddress(place.address_components, 'country');
              let streetAddress = `${place.address_components[0].long_name!} ${place.address_components[1].long_name!}`;

              if (place.address_components[0].types.includes('subpremise')) {
                streetAddress = `${place.address_components[0].long_name!}-${place.address_components[1].long_name!} ${place
                  .address_components[2].long_name!}`;
              }

              handleDetails(city, province, postalCode, country, streetAddress, type);
            }
          }
        }
      );
    }

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id={'streetAddress'}
      openText={props.name}
      disableClearable
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      freeSolo
      disabled={props.disabled}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={address ? address : value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setValue('');
        setInputValue(newInputValue);
        handleTextInput(event, type);
      }}
      renderInput={(params) => <TextField {...params} sx={address === '' && style ? style : asterisk} label="Address" fullWidth />}
    />
  );
};

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
  place_id: any;
}

interface ChildProps {
  id?: string;
  name?: string;
  handleDetails: any;
  handleTextInput: any;
  address: string;
  type?: number;
  disabled?: boolean;
  style?: object;
  asterisk?: object;
}

export default GooglePlaces;
