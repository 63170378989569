import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { IProject } from '../../types/project';
import { FlexBetween } from '../../utils/CommonStyles';

import 'photoswipe/dist/photoswipe.css';
import './photoswipe.css';
import LoadingWrapper from '../common/LoadingWrapper';

const FloorPlans = (props: ChildProps) => {
  const { project } = props;
  const [images, setImages] = useState<IMarketing[]>([]);

  const { loading } = useQuery(GETMARKETINGFLOORPLANS, {
    variables: { project: project._id, units: [], status: ['HL'] },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      let sortedFloorPlans: any = [
        ...new Map(
          data.getMarketingFloorPlans
            .filter((marketing: IMarketing) => marketing.marketingFloorPlan)
            .map((item: IMarketing) => [item['modelType'], item])
        ).values(),
      ].sort((a: any, b: any) => a.size - b.size);
      setImages(sortedFloorPlans);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  return !loading ? (
    <div>
      <FlexBetween
        sx={{
          '@media (max-width: 768px)': {
            mt: 4,
            p: 2,
            mb: 0,
            backgroundColor: project.portal.primaryColor,
          },
          my: 2,
        }}
      >
        <Typography
          variant="h3"
          color="#fff"
          sx={{
            fontFamily: 'Abadi-Bold !important',
            mt: 2,
            '@media (max-width: 768px)': {
              color: '#fff',
              mt: 0,
            },
          }}
        >
          Floor Plans
        </Typography>
      </FlexBetween>
      {images.length ? (
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            '@media (max-width: 768px)': {
              p: 2,
            },
          }}
        >
          <Gallery withDownloadButton={true} withCaption={true}>
            {images.map((floorPlan: any, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4} xl={2} sx={{ cursor: 'pointer', height: '300px', flexGrow: 1, mb: 5 }}>
                  <Box sx={{ color: '#fff', backgroundColor: project.portal.primaryColor, p: 0.5, textAlign: 'center' }}>
                    {floorPlan.modelType}
                  </Box>
                  <Paper elevation={12} sx={{ height: '100%', borderRadius: 0 }}>
                    <Item original={floorPlan.marketingFloorPlan} caption={`${floorPlan.modelType}`}>
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          alt={floorPlan.modelType}
                          src={floorPlan.marketingFloorPlan}
                          style={{ maxWidth: '100%', objectFit: 'contain', height: '100%', width: '100%' }}
                          loading="lazy"
                        />
                      )}
                    </Item>
                  </Paper>
                </Grid>
              );
            })}
          </Gallery>
        </Grid>
      ) : (
        <Box sx={{ color: '#fff' }}>There are currently no floorplans available. Please check back again!</Box>
      )}
    </div>
  ) : (
    <LoadingWrapper title="Loading Floor Plans" modal={false} color="#fff" />
  );
};

interface IMarketing {
  basePrice: number;
  marketingFloorPlan: string | null;
  modelType: string;
  rental: number;
  size: number;
  suite: string;
  unitType: string;
  _id: string;
}

const GETMARKETINGFLOORPLANS = gql`
  query getMarketingFloorPlans($project: MongoID!, $units: [NewMarketingInput!], $status: [String]) {
    getMarketingFloorPlans(project: $project, units: $units, status: $status) {
      _id
      suite
      size
      modelType
      rental
      unitType
      marketingFloorPlan
      basePrice
    }
  }
`;

interface ChildProps {
  project: IProject;
}

export default FloorPlans;
