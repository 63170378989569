import { Navigate, useLocation } from 'react-router';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Background, ResetPasswordContainer } from '../utils/CommonStyles';

const ThankYou = () => {
  const location = useLocation();

  return (
    location && location.state ?
    <Background>
      <ResetPasswordContainer>
        <LogoImgContainer>
          <LogoImg src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png" alt='Logo' />
        </LogoImgContainer>
        <Title>{location.state.title}</Title> 
        <ThankYouText>{location.state.text}</ThankYouText>
        <Button style={{ display: 'block', marginRight: '10px' }} href={location.state.link} variant="contained" color="primary">
          Back to Main Page
        </Button>
      </ResetPasswordContainer>
    </Background>
    : <Navigate to="/" />
  );
};

interface ChildProps {
  location: any;
}

const LogoImgContainer = styled.div`
  height: 15%;
  width: 70%;
  margin-top: 5%;
  max-width: 500px;
  text-align: center;
  @media (max-width: 600px) {
    height: 10%;
  }
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const ThankYouText = styled.div`
  text-align: center;
  height: 30%;
  width: 60%;
  margin-bottom: 2%;
  @media (max-width: 600px) {
    width: 80%;
    height: 20%;
  }
`;

export default ThankYou;
