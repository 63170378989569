export const menuItems = [
  {
    title: 'Main Page',
    icon: 'HomeIcon',
    link: '',
    subMenu: false,
    permission: '',
  },
  {
    title: 'Floor Plans',
    icon: 'GridOnIcon',
    link: 'floorplans',
    subMenu: false,
    permission: 'floorplans',
  },
  {
    title: 'Create Worksheet',
    icon: 'GridOnIcon',
    link: 'create-worksheets',
    subMenu: false,
    permission: 'createWorksheets',
  },
  {
    title: 'Worksheets',
    icon: 'ReceiptIcon',
    link: 'worksheets',
    subMenu: false,
    permission: 'worksheets',
  },
  {
    title: 'Allocations',
    icon: 'CommentIcon',
    link: 'allocations',
    subMenu: false,
    permission: 'allocations',
  },
  {
    title: 'Deals',
    icon: 'CommentIcon',
    link: 'deals',
    subMenu: false,
    permission: 'deals',
  },
  {
    title: 'Appointments',
    icon: 'AppointmentIcon',
    link: 'appointments',
    subMenu: false,
    permission: 'appointments',
  },
  {
    title: 'Book Appointment',
    icon: 'AppointmentIcon',
    link: 'book-an-appointment',
    subMenu: false,
    permission: 'bookAppointment',
  },
  {
    title: 'Outstandings',
    icon: 'ReceiptIcon',
    link: 'outstandings',
    subMenu: false,
    permission: 'viewOutstandings',
  },
];

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const chequeTypes: { [name: string]: string } = {
  received: '#92ff92',
  distributed: '#b4b4f3',
  replacement: '#ffff99',
  cleared: '#FF8C00',
  nsf: '#ff8888',
  wire: '#5eb0f7'
};

export const chequeDescriptions: { [name: string]: string } = {
  received: 'We have received the cheque',
  distributed: 'The cheque has been distributed to the lawyers',
  replacement: 'The cheque needs to be replaced',
  cleared: 'Cheque has been successfully deposited',
  nsf: 'The cheque has gone NSF',
  wire: 'Provided/Providing wire transfer'
};

export const locations = [
  'Quebec',
  'Saskatchewan',
  'Manitoba',
  'Alberta',
  'British Columbia',
  'Prince Edward Island',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nunavut',
  'Northwest Territories',
  'Yukon Territory',
  'Nova Scotia',
  'International Government',
  '',
];

export const IdTypes = [
  "Driver's License",
  'Photo Card',
  'Passport',
  'Permanent Resident Card',
  'Citizenship Card',
  'Identity Card',
  'Articles of Incorporation',
];

export const holidays = [
  "05/23/2022",
  "07/01/2022",
  "08/01/2022",
  "09/05/2022",
  "10/10/2022",
  "12/25/2022",
  "12/31/2022",
]

export const hotlistHeaders = [
  'suite',
  'marketingFloorPlan',
  'unit',
  'unitType',
  'modelType',
  'size',
  'outdoorSize',
  'bathroom',
  'exposure',
  'custom',
  'basePrice'
];

export const hotlistFormattedHeaders = [
  'Suite',
  'Floorplan',
  'Unit',
  'Type',
  'Model',
  'Interior Size',
  'Outdoor Size',
  'Bathrooms',
  'View',
  'Ceiling',
  'Price'
];

export const pdfColumnWidths = {
  suite: 50,
  level: 50,
  unitType: 50,
  modelType: 50,
  size: 70,
  outdoorSize: 70,
  outdoorType: 70,
  bathroom: 60,
  exposure: 70,
  package: 80,
  custom: 80,
  rental: 50,
  tier: 50,
  basePrice: 80,
};

