import React from 'react';
import { MutationFunction } from '@apollo/client';
import styled from 'styled-components';
import { withRouter } from '../../utils/WithRouter';
import { smWidth, WorksheetTextField, InvertedStyledTextField, Heading } from '../../utils/CommonStyles';
import { Grid } from '@mui/material';
import { IRealtor } from '../../types/realtor';
import { sanitizePhone, validateEmail, validatePhone } from '../../utils/Validations';
import { formatPhoneNumber } from '../../utils/Functions';

const ProfilePersonalInfo = (props: ChildProps) => {
  const { fields, disabled, handleTextInput, handleText, invert } = props;
  const { firstName, lastName, email, directPhone, recoNumber } = fields;

  let gridColumn: any = invert ? 6 : 4;
  let gridContainer: any = invert ? 12 : 9;
  
  let TextComponent = invert ? InvertedStyledTextField :WorksheetTextField

  return (
    <div>
      <PersonalInfoContainer container spacing={2}>
        {!invert ?
          <Heading item lg={3} md={gridColumn} sm={12} xs={12}>Realtor Information</Heading>
        : null
        }
        <Grid item lg={gridContainer} md={gridContainer} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="firstname-textarea"
                label="Realtor First Name"
                name="firstName"
                required
                value={firstName}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="lastname-textarea"
                label="Realtor Last Name"
                name="lastName"
                required
                value={lastName}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="email-textarea"
                label="Realtor Email"
                name="email"
                error={!disabled && !validateEmail(email) && email !== ''}
                helperText={!disabled && !validateEmail(email) && email !== '' ? 'Please enter a valid email' : ''}
                required
                value={email}
                defaultValue={email}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="phone-textarea"
                label="Realtor Cell Phone Number (xxx-xxx-xxxx)"
                name="directPhone"
                error={!disabled && !validatePhone(directPhone) && directPhone !== ''}
                helperText={!disabled && !validatePhone(directPhone) && directPhone !== '' ? 'Please enter a valid phone number' : ''}
                required
                value={formatPhoneNumber(directPhone!)}
                onChange={(e) => {
                  if (handleText) handleText(e.target.name, sanitizePhone(e.target.value));
                }}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
            <Grid item lg={gridColumn} md={gridColumn} sm={gridColumn} xs={12}>
              <TextComponent
                id="reco-textarea"
                label="Realtor RECO Number"
                name="recoNumber"
                value={recoNumber}
                onChange={handleTextInput}
                disabled={disabled}
                size={invert ? 'small' : 'medium'}
              />
            </Grid>
          </Grid>
        </Grid>
      </PersonalInfoContainer>
    </div>
  );
};

const PersonalInfoContainer = styled(Grid)`
  display: flex;
  margin-top: 50px;
  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

interface ChildProps {
  updateUserInfo?: MutationFunction;
  disabled?: boolean;
  fields: IRealtor;
  handleTextInput?: (e: any) => void;
  invert?: boolean;
  handleText?: (name: string, value: string) => void;
}

export default withRouter(ProfilePersonalInfo);