import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { ICoopRate } from '../../types/deal';
import ProfilePersonalInfo from '../profile/ProfilePersonalInfo';
import ProfileBrokerageInfo from '../profile/ProfileBrokerageInfo';
import { IDealRealtor, IDealRealtorOne } from '../../types/dealRealtor';
import { StyledSubheading, DealsPageLine } from '../../utils/CommonStyles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';

const DealRealtorInfo = (props: ChildProps) => {
  const { dealid } = props;
  const [dealRealtor, setDealRealtor] = useState<IDealRealtor>();
  const user = useSelector(selectUser);

  useQuery<IDealRealtorOne>(GETDEALREALTOR, {
    variables: { filter: { deal: dealid, email: user.realtor.email } },
    onCompleted: (data) => {
      setDealRealtor(data.dealRealtorOne);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  return (
    <>
      <DealsPageLine />
      <h1 style={{ textAlign: 'center' }}>Realtor</h1>
      {dealRealtor && (
        <>
          <ProfilePersonalInfo disabled={true} fields={dealRealtor} />
          <BrokerageInfoContainer>
            <ProfileBrokerageInfo disableAutoComplete={true} disabled={true} fields={dealRealtor} />
            <Divider sx={{ marginTop: '50px' }} />
            <CoopContainer>
              <StyledSubheading size={'small'}>Co-op Commission</StyledSubheading>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Co-op Breakdown</TableCell>
                      <TableCell>Percentage</TableCell>
                      <TableCell>Fixed Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dealRealtor.coopRates.map((coopRate: ICoopRate, i: number) => (
                      <TableRow key={i}>
                        <TableCell>{coopRate.type}</TableCell>
                        <TableCell>{`${coopRate.percentage}%`}</TableCell>
                        <TableCell>{coopRate.fixedAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CoopContainer>
          </BrokerageInfoContainer>
        </>
      )}
    </>
  );
};

const CoopContainer = styled.div`
  margin-top: 50px;
`;

const BrokerageInfoContainer = styled.div`
  margin-top: 50px;
`;

const GETDEALREALTOR = gql`
  query dealRealtorOne($filter: FilterFindOneDealRealtorInput) {
    dealRealtorOne(filter: $filter) {
      email
      firstName
      lastName
      directPhone
      recoNumber
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      coopRates {
        type
        days
        date
        description
        percentage
        fixedAmount
      }
    }
  }
`;

interface ChildProps {
  dealid: string;
}

export default DealRealtorInfo;
