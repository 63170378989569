import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const Toggle = (props: ChildProps) => {
  const { alignment, handleToggle, choice1, choice2 } = props;

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    handleToggle(event, newAlignment);
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: 'none',
          flexWrap: 'wrap',
          width: '235px',
        }}
      >
        <StyledToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
          <ToggleButton value={choice1}>
            <span>{choice1.toLocaleUpperCase()}</span>
          </ToggleButton>
          <ToggleButton value={choice2}>
            <span>{choice2.toLocaleUpperCase()}</span>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};

interface ChildProps {
  alignment: string;
  handleToggle: any;
  choice1: string;
  choice2: string;
}

export default Toggle;
