import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const VerticalStepper = (props: ChildProps) => {
  const { currentIndex, headers, handleStepNavigation } = props;
  return (
    <Box sx={{ maxWidth: 120 }}>
      <Stepper activeStep={currentIndex} orientation="vertical" nonLinear>
        {headers.map((step, index) => (
          <Step
            key={step}
            onClick={(e) => {
              handleStepNavigation(index);
            }}
          >
            <StepLabel sx={{ cursor: 'pointer' }}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

interface ChildProps {
  handleStepNavigation: (currentIndex: number) => void;
  headers: string[];
  currentIndex: number;
}

export default VerticalStepper;
