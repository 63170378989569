import { useContext, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { gql, useLazyQuery } from '@apollo/client';
import SortableTable from '../common/SortableTable';
import { IUnit } from '../../types/unit';
import { numberWithCommas, capitalizeFirstLetter } from '../../utils/Functions';
import WorksheetSelect from './WorksheetSelect';
import {
  IWorksheet,
  IPurchaserInfo
} from '../../types/worksheet';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { selectProject } from '../../features/project/projectSlice';

const Allocations = () => {
  const { project } = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [allocatedUnits, setAllocatedUnits] = useState<IFormattedUnit[]>();

  const [allocatedQuery] = useLazyQuery(GETUNITS, {
    variables: { projects: project.combinedProjects.length
      ? [project._id, ...project.combinedProjects.map((project) => project._id)]
      : [project._id] },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const allAllocatedUnits = data.getAllocations.filter((unit: IUnit) => unit.status === "D" || unit.status === "W" || unit.status === "WA").map((unit: IUnit, index: number) => {
        return getAllocatedUnit(unit, index);
      });

      setAllocatedUnits(allAllocatedUnits);
    },
    onError: (err) => {
      console.log(err);
    },
  });
  
  useEffect(() => {
    if (project?._id) allocatedQuery();
  }, [project, allocatedQuery]);


  const getAllocatedUnit = (unit: IUnit, index: number) => {
    const worksheets = unit.worksheets.find((worksheet: IWorksheet) => {
      return worksheet.realtor?._id === user?.realtor._id && worksheet.status !== "archived";
    });
  
    return {
      ...unit,
      basePrice: unit.basePrice,
      outdoorSize: unit.outdoorSize ? numberWithCommas(unit.outdoorSize) : '0',
      outdoorType: unit.outdoorType ? unit.outdoorType : 'N/A',
      size: numberWithCommas(unit.size),
      worksheetStatus: worksheets?.status ? <strong>{capitalizeFirstLetter(worksheets.status)}</strong> : 'No Worksheet Allocated',
      selectedWorksheet: worksheets ? worksheets.purchasers.map((purchaser: IPurchaserInfo) => purchaser.fullName) : [],
      worksheetDropdown: 
        worksheets?.status !== "accepted" && unit.status !== "O" && unit.status !== "C" && unit.status !== "F" ?
        <WorksheetSelect
          initialSelectedWorksheet={worksheets ? worksheets : null}
          unit={unit}
          index={index}
          realtorId={user?.realtor._id}
          project={project}
          userId={user?._id}
          update={(unit: IUnit, index: number) => updateAllocatedUnits(unit, index)}
        />
        : null
    };
  }

  const updateAllocatedUnits = (unit: IUnit, index: number) => {
    setAllocatedUnits(units => units?.map((currentUnit, unitIndex) => unitIndex === index ? getAllocatedUnit(unit, index) : currentUnit) || []); 
  }

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <div>
        <h1>Allocations</h1>

        {allocatedUnits && (
          <>
            {allocatedUnits.length !== 0 ? (
              <SortableTable
                formattedHeaders={[
                  'Suite',
                  'Unit',
                  'Type',
                  'Model',
                  'Interior Size',
                  'Outdoor Size',
                  'Outdoor Type',
                  'Bathrooms',
                  'View',
                  'Price',
                  'Allocated Worksheet Status',
                  'Allocated Worksheet',
                  'Change Worksheet',
                ]}
                headers={[
                  'suite',
                  'unit',
                  'unitType',
                  'modelType',
                  'size',
                  'outdoorSize',
                  'outdoorType',
                  'bathroom',
                  'exposure',
                  'basePrice',
                  'worksheetStatus',
                  'selectedWorksheet',
                  'worksheetDropdown',
                ]}
                rows={allocatedUnits}
              />
            ) : (
              <div>No current allocations</div>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

interface IFormattedUnit extends Omit<IUnit, 'size' | 'outdoorSize' | 'basePrice'> {
  size: string;
  outdoorSize: string;
  basePrice: string;
  worksheetDropdown: any; //update type
}

const GETUNITS = gql`
  query getAllocations($projects: [MongoID!]) {
    getAllocations(projects: $projects) {
      _id
      suite
      unit
      unitType
      modelType
      size
      level
      outdoorSize
      outdoorType
      bathroom
      exposure
      status
      basePrice
      worksheets {
        _id
        realtor {
          _id
        }
        purchasers {
          fullName
        }
        status
      }
    }
  }
`;

export default Allocations;
