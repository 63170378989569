import { useApolloClient, useQuery, useLazyQuery, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout as logoutAction, selectUser, setUser } from './authSlice';
import { showErrorSnackbar } from '../snackbar/snackbarSlice';

export const useSelectUser = () => useAppSelector(selectUser);

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();

  const [login] = useLazyQuery(USERLOGIN, {
    onCompleted: (data) => {
      dispatch(setUser(data.userLogin.user));
      localStorage.setItem('userID', data.userLogin.id);
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(location.state?.from?.pathname || '/project-list', { replace: true });
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });

  return (email: string, password: string) => login({ variables: { email: email.toLowerCase(), password: password } });
};

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const client = useApolloClient();

  const [logout] = useLazyQuery(LOGOUT, {
    onCompleted: () => {
      localStorage.clear();
      client.cache.reset();
      client.clearStore().then(() => {
        navigate('/');
        dispatch(logoutAction());
      });
    },
    onError: (err) => {
      // dispatch(showErrorSnackbar(err.message));
    },
  });

  return logout;
};

export const useUserQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery(GETUSER, {
    onCompleted: (data) => {
      dispatch(setUser(data.getUser));
    },
    onError: (err) => {
      // dispatch(showErrorSnackbar(err.message));
    },
  });
};

const USERLOGIN = gql`
  query userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      user {
        _id
        firstName
        lastName
        email
        fullName
        type
        fullAccess
        realtor {
          _id
          email
          firstName
          lastName
          brokerage
          streetAddress
          city
          province
          country
          postalCode
          brokeragePhone
          directPhone
        }
        projectAccess {
          project {
            _id
            name
            addresses {
              streetAddress
              city
              province
              postalCode
            }
          }
          access
        }
      }
    }
  }
`;

const LOGOUT = gql`
  query userLogout {
    userLogout {
      message
    }
  }
`;

const GETUSER = gql`
  query getUser {
    getUser {
      _id
      firstName
      lastName
      email
      fullName
      type
      fullAccess
      realtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        directPhone
      }
      projectAccess {
        project {
          _id
          name
          addresses {
            streetAddress
            city
            province
            postalCode
          }
        }
        access
      }
    }
  }
`;
