import { useEffect, useState, useContext } from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { WorksheetContext } from '../../context/WorksheetContext';
import { Accordion, AccordionSummary, AccordionDetails, Divider, Grid, Button, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { IWorksheetPurchaserInfo, IWishlistChoices, IPurchaserInfo, IWorksheet, IWorksheetById, IMedia } from '../../types/worksheet';
import { IUnitDataGridCard, IUnit } from '../../types/unit';
import UnitInfoHeader from './UnitInfoHeader';
import PurchaserInfo from './PurchaserInfo';
import WishlistChoices from './WishlistChoices';
import SuiteOptions from './SuiteOptions';
import LicenceUpload from './LicenceUpload';
import { Title, mdWidth, smWidth, Heading } from '../../utils/CommonStyles';
import { urlName } from '../../utils/Functions';
import CustomDialog from '../common/CustomDialog';
import Header from '../menus/Header';
import { GreenButton } from '../../utils/CommonStyles';
import LoadingWrapper from '../common/LoadingWrapper';
import ProfilePersonalInfo from '../profile/ProfilePersonalInfo';
import Toggle from './Toggle';
import ProfileBrokerageInfo from '../profile/ProfileBrokerageInfo';
import { isWishlistFilled, isPurchasersFilled, isRealtorsFilled } from '../../utils/Validations';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { selectProject } from '../../features/project/projectSlice';

const CreateWorksheet = (props: any) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { project } = useSelector(selectProject);
  const navigate = useNavigate();
  const location = useLocation();
  const { purchasersValue, optionsValue, wishlistValue, realtorValue, chequeImagesValue, joinWaitlistValue } = useContext(WorksheetContext);
  const [options, setOptions] = optionsValue;
  const [joinWaitlist, setJoinWaitlist] = joinWaitlistValue;
  const [chequeImages, setChequeImages] = chequeImagesValue;
  const [purchasers, dispatchPurchasers] = purchasersValue;
  const [wishlistChoices, dispatchChoices] = wishlistValue;
  const [realtor, dispatchRealtor] = realtorValue;
  const [selectedPurchaser, setSelectedPurchaser] = useState<IWorksheetPurchaserInfo | null>(null);
  const [notes, setNotes] = useState('');
  const { unitid, worksheetid } = useParams();
  const path = location.pathname;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [unitId, setUnitId] = useState<string | undefined>(unitid);
  const [unit, setUnit] = useState<IUnit | null>(null);
  const [worksheetId, setWorksheetId] = useState<string | undefined>(worksheetid);
  const [wishlist, setWishlist] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [selectedUnitType, setSelectedUnitType] = useState('');
  const [worksheetStatus, setWorksheetStatus] = useState('');
  const [alignment, setAlignment] = useState('no');
  const [toggle, setToggle] = useState<boolean>(false);
  const pathChoices = {
    wishlist: `/dashboard/${urlName(project.name)}/create-worksheets`,
    unitBased: `/dashboard/${urlName(project.name)}/${unitid}/create-worksheets`,
    public: `/${urlName(project.developerName)}/${urlName(project.name)}/public/${unitid}/create-worksheets`,
  };

  const maxFileSize = 5242880;

  const style = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': { border: '1px solid #f54394' },
      '&:hover fieldset': { borderColor: '#f54394' },
      '&.Mui-focused fieldset': { borderColor: '#f54394' },
    },
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  };

  const asterisk = {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  };

  const { loading: worksheetLoading } = useQuery<IUnitDataGridCard>(UNITFINDBYID, {
    skip: !!!unitid,
    variables: { id: unitid },
    onCompleted: (data) => {
      if (data?.unitById) {
        const worksheet = data.unitById.worksheets.find(
          (worksheet) => user && user.realtor._id === worksheet.realtor?._id && worksheet.unit?._id === unitid
        );
        if (worksheet) navigate(`/dashboard/${urlName(project.name)}/worksheets/${worksheet._id}`);
        else if (!user && data.unitById.status !== 'HL') navigate(`/${urlName(project.developerName)}/${urlName(project.name)}/public`);
        setUnit(data.unitById);
        setSelectedUnitType(data.unitById.unitType);
        return;
      }
      redirectToHome(false);
    },
    onError: (error) => {
      console.log(error);
      redirectToHome(false);
    },
  });

  const { loading } = useQuery<IWorksheetById>(WORKSHEETONE, {
    skip: !worksheetId ? true : false,
    variables: { _id: worksheetId },
    onCompleted: (data) => {
      //get purchasers array then map through the array, push with adding purchaser number and purchaser complete
      data.viewWorksheet.purchasers.forEach((purchaser: IPurchaserInfo, index: number) => {
        const newPurchaser: IWorksheetPurchaserInfo = {
          ...purchaser,
          purchaserNumber: index,
          purchaserComplete: data.viewWorksheet.purchasers.length !== index,
          signingOfficerComplete: [],
          identifications: [...purchaser.identifications],
          signingOfficers: [...purchaser.signingOfficers],
          proofFileImage: purchaser.getUrl,
          proof: null,
          acknowledgement: true,
        };
        dispatchPurchasers({ type: 'ADD_PURCHASER', payload: newPurchaser });
      });

      if (data.viewWorksheet.wishlistChoices?.length) {
        dispatchChoices({
          type: 'SET_WISHLIST_CHOICES',
          payload: data.viewWorksheet.wishlistChoices,
        });
      }
      if (data.viewWorksheet.unit) {
        setUnitId(data.viewWorksheet.unit._id);
      }
      setWorksheetStatus(data.viewWorksheet.status);
      setChequeImages(data.viewWorksheet.chequeImages);
      setWishlist(data.viewWorksheet.wishlist);
      setOptions(data.viewWorksheet.options);
      setWorksheetId(data.viewWorksheet._id);
      setNotes(data.viewWorksheet.notes);
      setJoinWaitlist(data.viewWorksheet.joinWaitlist);
    },
    onError: (error) => {
      redirectToHome(false);
    },
  });

  const worksheetInfoForMutation: IWorksheet = {
    isPublic: isPublic,
    wishlist: wishlist,
    project: project ? project._id! : null,
    unit: wishlist && !unitid ? null : unitId,
    purchasers: purchasers.map((purchaser: IPurchaserInfo) => purchaser._id),
    realtor: user && user.realtor ? user.realtor._id : null,
    user: user ? user._id : null,
    options: options,
    status: worksheetStatus,
    joinWaitlist: joinWaitlist,
    notes: notes,
    wishlistChoices: wishlist ? wishlistChoices : [],
    chequeImages: chequeImages.map((chequeImages: IMedia) => {
      return {
        name: chequeImages.name,
      };
    }),
  };

  const animationProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 100 },
    delay: 250,
  });

  //clear all states
  useEffect(() => {
    setNotes('');
    setJoinWaitlist([]);
    setOptions([]);
    setChequeImages([]);
    dispatchPurchasers({ type: 'CLEAR_PURCHASERS' });
    // Autofill unit and model type
    if (location?.state) {
      const historyState: StateAutofill = location.state as StateAutofill;
      dispatchChoices({
        type: 'SET_UNIT_TYPE',
        payload: {
          index: 0,
          value: historyState.unitType,
        },
      });
      dispatchChoices({
        type: 'SET_MODEL_TYPE',
        payload: {
          index: 0,
          value: [historyState.modelType],
        },
      });
    } else {
      dispatchChoices({ type: 'CLEAR_CHOICES' });
    }
    setSelectedUnitType('');
    setIsPublic(false);

    if (path === pathChoices.wishlist) {
      setWorksheetId(undefined);
      setWishlist(true);
    } else if (path === pathChoices.public) {
      setWorksheetId(undefined);
      setWishlist(false);
      setIsPublic(true);
    }
    // eslint-disable-next-line
  }, [
    dispatchChoices,
    dispatchPurchasers,
    path,
    pathChoices.public,
    pathChoices.wishlist,
    setOptions,
    setChequeImages,
    worksheetId,
    location.state,
  ]);

  const [createWorksheet, { loading: createLoading }] = useMutation(CREATEWORKSHEET, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Worksheet created successfully'));
      redirectToHome(true);
    },
    onError: (error) => {
      console.log(error);
      storeDispatch(showErrorSnackbar(error.message));
    },
  });

  const [uploadID] = useMutation(UPLOADID, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(error);
    },
  });

  const [uploadCheque] = useMutation(UPLOADCHEQUE, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(error);
    },
  });

  const [updatePurchaser] = useMutation(UPDATEPURCHASER, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(error, 'error');
    },
  });

  const [updateWorksheet] = useMutation(UPDATEWORKSHEET, {
    onCompleted: () => {
      storeDispatch(showSuccessSnackbar('Worksheet updated'));
    },
    onError: (error) => {
      if (error.message.includes('Path `unitType` is required')) {
        storeDispatch(showErrorSnackbar('Please select an unit type'));
      } else {
        storeDispatch(showErrorSnackbar(error.message));
      }
    },
  });

  // FUNCTIONS

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    if (file.size > maxFileSize) {
      storeDispatch(showErrorSnackbar('This file is too large'));
      return;
    }
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setChequeImages([
        ...chequeImages,
        {
          name: file.name,
          file: file,
          url: fileReader.result,
        },
      ]);
    };
  };

  const handleRemoveId = (event: any, numIndex: number) => {
    let filterChequeImages = chequeImages.filter((chequeImage: IMedia, index: number) => numIndex !== index);
    setChequeImages(filterChequeImages);
  };

  const redirectToHome = (createdWorksheet: boolean) => {
    if (user) {
      navigate('/dashboard/' + urlName(project.name) + (createdWorksheet ? '/worksheets' : ''));
      return;
    }
    if (isPublic) {
      navigate('/thank-you', {
        state: {
          title: 'Thank you for submitting your worksheet!',
          text: `Our team has been notified of your request, and you will be notified by email if accepted. A copy of the worksheet details has been sent to ${purchasers[0].email}.`,
          link: `/${urlName(project.developerName)}/${urlName(project.name)}/public`,
        },
      });
    }
  };

  const handleTextInput = (event: any) => {
    if (event && event.target) {
      dispatchRealtor({
        type: 'TEXT_CHANGE',
        payload: {
          field: event.target.name ? event.target.name : 'streetAddress',
          value: event.target.value,
        },
      });
    }
  };

  const handleDetails = (city: string, province: string, postalCode: string, country: string, address: string) => {
    dispatchRealtor({
      type: 'STREET_ADDRESS',
      payload: {
        streetAddress: address,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
      },
    });
  };

  const handleText = (name: string, value: string) => {
    dispatchRealtor({
      type: 'TEXT_CHANGE',
      payload: {
        field: name,
        value: value,
      },
    });
  };

  const handleSaveWorksheet = async (worksheetPurchasers: any) => {
    if (chequeImages.length > 0 && worksheetId) {
      await chequeImages.forEach(async (file: any) => {
        if (file.file) {
          await uploadCheque({ variables: { id: worksheetId, name: file.file.name, file: file.file } });
        }
      });
    }

    if (worksheetId) {
      updateWorksheet({ variables: { _id: worksheetId, record: worksheetInfoForMutation, purchasers: worksheetPurchasers } });
    } else if (!worksheetId) {
      let filteredPurchasers = purchasers.map((purchaser: IWorksheetPurchaserInfo) => {
        const {
          purchaserNumber,
          purchaserComplete,
          signingOfficerComplete,
          identifications,
          signingOfficers,
          proof,
          proofFileImage,
          fullName,
          acknowledgement,
          ...pInfoForGQLCreate
        } = purchaser;
        const idsForCreate = identifications.filter((id) => id.name !== '');
        const idNames = idsForCreate.map((identification) => {
          return { name: identification.name };
        });
        const idFiles = idsForCreate.map((identification) => identification.file);
        const { _id, getUrl, putUrl, ...rest } = pInfoForGQLCreate;
        pInfoForGQLCreate.project = project._id!;
        return {
          ...pInfoForGQLCreate,
          identifications: idNames,
          signingOfficers: signingOfficers,
          proof: proof,
          files: idFiles,
        };
      });

      let chequeNames: any = [];
      let chequeFiles: any = [];

      if (chequeImages.length > 0) {
        chequeNames = chequeImages.map((cheque: IMedia) => {
          return {
            name: cheque.name,
          };
        });
        chequeFiles = chequeImages.map((cheque: IMedia) => {
          return cheque.file;
        });
      }

      createWorksheet({
        variables: {
          isPublic: isPublic,
          inPerson: false,
          wishlist: wishlist,
          project: unit ? unit?.project._id : project ? project._id : null,
          unit: wishlist ? null : unitId,
          purchasers: filteredPurchasers,
          realtor: user && user.realtor ? convertUserRealtor() : alignment === 'yes' ? realtor[0] : null,
          user: user ? user._id : null,
          options: options,
          joinWaitlist: joinWaitlist,
          notes: notes,
          wishlistChoices: wishlist ? wishlistChoices : [],
          worksheetBasePrice: unit?.basePrice,
          chequeImages: chequeNames,
          chequeFiles: chequeFiles,
        },
      });
    }
  };

  const convertUserRealtor = () => {
    return {
      email: user.realtor.email,
      firstName: user.realtor.firstName,
      lastName: user.realtor.lastName,
      brokerage: user.realtor.brokerage,
      streetAddress: user.realtor.streetAddress,
      city: user.realtor.city,
      province: user.realtor.province,
      country: user.realtor.country,
      postalCode: user.realtor.postalCode,
      brokeragePhone: user.realtor.brokeragePhone,
      directPhone: user.realtor.directPhone,
    };
  };

  const handleCloseSuccess = () => {
    if (selectedPurchaser) {
      dispatchPurchasers({ type: 'REMOVE_PURCHASER', payload: { purchaserNumber: selectedPurchaser.purchaserNumber } });
    }
    setDialogOpen(false);
  };

  const handleSelectedPurchaser = (purchaser: IWorksheetPurchaserInfo) => {
    setSelectedPurchaser(purchaser);
    setDialogOpen(true);
  };

  const handleToggle = () => {
    setAlignment(toggle ? 'no' : 'yes');
    setToggle(!toggle);
  };

  const WishlistChoicesOutput = wishlistChoices.map((choice: IWishlistChoices, index: number) => {
    return <WishlistChoices project={project!} choice={choice} key={index} index={index} style={style} />;
  });

  const PersonalInfoOutput = purchasers.map((purchaser: IWorksheetPurchaserInfo, index: number) => {
    if (purchaser.purchaserComplete && purchasers.length > 1) {
      return (
        <div key={index} style={{ margin: '20px 0' }}>
          <StyledAccordion square={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
              <CompactPurchaserInfo>
                <SubHeading>Purchaser {index + 1}</SubHeading>
                {purchasers.length > 1 && (
                  <IconButton disableRipple size="small" onClick={() => handleSelectedPurchaser(purchaser)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </CompactPurchaserInfo>
            </AccordionSummary>
            <AccordionDetails>
              <PurchaserInfo
                project={unit ? unit.project : project}
                purchaserCount={purchasers.length}
                worksheetId={worksheetId}
                purchaserDetails={purchaser}
                dispatchPurchasers={dispatchPurchasers}
                key={purchaser.purchaserNumber}
                allExpand={true}
                style={style}
                asterisk={asterisk}
              />
            </AccordionDetails>
          </StyledAccordion>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <CustomDialog
            handleClose={() => setDialogOpen(false)}
            handleCloseRemove={() => setDialogOpen(false)}
            handleCloseSuccess={() => handleCloseSuccess()}
            open={dialogOpen}
            removeButton={'No'}
            successButton={'Yes'}
            dialogContent={`Are you sure?`}
            dialogTitle={'Remove Purchaser'}
          />
          <div style={{ marginTop: '20px' }}>
            <CompactPurchaserInfo>
              <SubHeading>Purchaser {index + 1}</SubHeading>
              {purchasers.length > 1 && (
                <IconButton disableRipple size="small" onClick={() => handleSelectedPurchaser(purchaser)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </CompactPurchaserInfo>

            <PurchaserInfo
              key={purchaser.purchaserNumber}
              worksheetId={worksheetId}
              project={project}
              purchaserCount={purchasers.length}
              purchaserDetails={purchaser}
              dispatchPurchasers={dispatchPurchasers}
              allExpand={!!worksheetid || purchaser.purchaserComplete}
              style={style}
              asterisk={asterisk}
            />
          </div>
        </div>
      );
    }
  });

  const handleSavePurchaser = async (e: any) => {
    e.preventDefault();

    // Error
    if (purchasers.length > 1) {
      const uniqueValues = new Set(purchasers.map((purchaser: any) => purchaser.email));
      if (uniqueValues.size < purchasers.length) {
        storeDispatch(showErrorSnackbar('Cannot have duplicate emails!'));
        return;
      }
    }

    let worksheetPurchasers = await Promise.all(
      purchasers.map(async (purchaser: IWorksheetPurchaserInfo) => {
        if (purchaser._id) {
          const {
            _id,
            getUrl,
            putUrl,
            purchaserNumber,
            purchaserComplete,
            signingOfficerComplete,
            identifications,
            signingOfficers,
            project,
            proof,
            proofFileImage,
            acknowledgement,
            ...pInfoForGQLUpdate
          } = purchaser;
          const idsForUpdate = identifications.filter((id) => id.name !== '');
          const idNames = idsForUpdate.map((identification) => {
            return { name: identification.name };
          });

          //go through ids and upload them
          await idsForUpdate.forEach(async (file: any) => {
            if (file.file) {
              await uploadID({ variables: { id: purchaser._id, name: file.file.name, file: file.file } });
            }
          });

          // Proof of Address
          if (proof && proofFileImage) {
            let buffer: any;
            if (proofFileImage!.indexOf('application/pdf') !== -1) {
              buffer = Buffer.from(proofFileImage.replace(/^data:application\/\w+;base64,/, ''), 'base64');
            } else {
              buffer = Buffer.from(proofFileImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
            }

            const options = {
              headers: { 'Content-Type': `${proof.type}` },
              maxContentLength: Infinity,
              maxBodyLength: Infinity,
            };
            if (!putUrl) return;
            await axios.put(putUrl, buffer, options);
          }
          await updatePurchaser({
            variables: { _id: _id, record: { ...pInfoForGQLUpdate, identifications: idNames, signingOfficers: signingOfficers } },
          });

          pInfoForGQLUpdate.identifications = idNames;
          return pInfoForGQLUpdate;
        } else {
          const {
            _id,
            getUrl,
            putUrl,
            purchaserNumber,
            purchaserComplete,
            signingOfficerComplete,
            identifications,
            signingOfficers,
            proof,
            proofFileImage,
            acknowledgement,
            ...purchaserInfo
          } = purchaser;
          let newId = purchaser.identifications.map((identification: any) => identification.file);
          let identificationName = purchaser.identifications.map((identification: any) => {
            return {
              name: identification.name,
            };
          });
          purchaserInfo.identifications = identificationName;
          purchaserInfo.files = newId;
          purchaserInfo.project = project._id!;

          return purchaserInfo;
        }
      })
    );
    await handleSaveWorksheet(worksheetPurchasers);
  };

  const isWorksheetFilled = (realtorInfo: boolean) =>
    (!wishlist || isWishlistFilled(wishlistChoices)) &&
    isPurchasersFilled(purchasers) &&
    (!realtorInfo || alignment !== 'yes' || user || isRealtorsFilled(realtor));

  const handleAddChoice = () => {
    dispatchChoices({
      type: 'ADD_UNIT',
      payload: {
        unitType: '',
        modelTypes: ['Any'],
        exposure: 'Any',
        level: 'Any',
      },
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      {isPublic && <Header authenticated={false} title={'Create a Worksheet'} />}
      {loading || worksheetLoading || createLoading ? (
        <LoadingWrapper title={loading || worksheetLoading ? 'Worksheet is loading...' : 'Worksheet is being created...'} modal={false} />
      ) : (
        <div style={{ marginTop: isPublic ? '75px' : '0', padding: isPublic ? '24px' : '0' }}>
          <CustomDialog
            handleClose={() => setDialogOpen(false)}
            handleCloseRemove={() => setDialogOpen(false)}
            handleCloseSuccess={() => handleCloseSuccess()}
            open={dialogOpen}
            removeButton={'No'}
            successButton={'Yes'}
            dialogContent={`Are you sure?`}
            dialogTitle={'Remove Purchaser?'}
          />
          <div>
            {wishlist && (
              <div>
                <Title>Wishlist</Title>
                <Box sx={{ mb: 2 }}>{WishlistChoicesOutput}</Box>
                {wishlistChoices.length < 5 && (
                  <Box sx={{ mb: 2 }}>
                    <Button
                      sx={{
                        minWidth: '50px',
                        display: 'block',
                      }}
                      variant="contained"
                      disableElevation
                      onClick={handleAddChoice}
                    >
                      Add Another Choice
                    </Button>
                  </Box>
                )}
                <SuiteOptions
                  project={project}
                  wishlist={wishlist}
                  unitType={selectedUnitType}
                  wishlistChoices={wishlistChoices}
                  joinWaitlist={joinWaitlist}
                  setJoinWaitlist={setJoinWaitlist}
                  notes={notes}
                  setNotes={setNotes}
                />
              </div>
            )}
            {!wishlist && unitId && (
              <div>
                <UnitInfoHeader
                  project={project}
                  unitid={unitId}
                  setSelectedUnitType={setSelectedUnitType}
                  joinWaitlist={joinWaitlist}
                  setJoinWaitlist={setJoinWaitlist}
                  notes={notes}
                  setNotes={setNotes}
                />
              </div>
            )}
            <Divider sx={{ marginTop: '25px', marginBottom: '25px' }} />
            <form onSubmit={handleSavePurchaser}>
              <div>
                <div style={{ fontSize: '1.25rem', fontWeight: 600 }}>Upload Bank Drafts and Cheques (Optional)</div>
                <Flex container spacing={2}>
                  <DropzoneContainer item lg={3} md={4} sm={6} xs={12}>
                    <div style={{ padding: '20px', border: '1px solid #c4c4c4', height: '100%' }}>
                      <Dropzone
                        onDrop={handleDrop}
                        accept={{ 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'], 'image/png': ['.png'], 'application/pdf': ['.pdf'] }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div style={{ height: '100%' }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <InsideText>
                              Drag and drop your file
                              <Divider sx={{ margin: '10px 0' }}>or</Divider>
                              <Button disableElevation variant="contained">
                                Browse
                              </Button>
                            </InsideText>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </DropzoneContainer>
                  {chequeImages.map((chequeImage: IMedia, index: number) => {
                    return <LicenceUpload identification={chequeImage} index={index} handleRemoveId={handleRemoveId} key={index} />;
                  })}
                </Flex>
              </div>

              {PersonalInfoOutput}
              {!user ? (
                isWorksheetFilled(false) ? (
                  <div style={{ marginTop: '30px' }}>
                    <Grid container spacing={2}>
                      <Heading item lg={3} md={4} sm={12} xs={12}>
                        <animated.div style={animationProps}>Are you a realtor or working with a realtor?</animated.div>
                      </Heading>
                      <Grid item lg={9} md={8} sm={12} xs={12}>
                        <animated.div style={animationProps}>
                          <ToggleContainer>
                            <Toggle alignment={alignment} handleToggle={() => handleToggle()} choice1="no" choice2="yes" />
                          </ToggleContainer>
                        </animated.div>
                      </Grid>
                    </Grid>
                    {alignment === 'yes' ? (
                      <>
                        <ProfilePersonalInfo fields={realtor[0]} handleTextInput={handleTextInput} handleText={handleText} />
                        <ProfileBrokerageInfo
                          fields={realtor[0]}
                          handleTextInput={handleTextInput}
                          handleDetails={handleDetails}
                          handleText={handleText}
                          disableAutoComplete={false}
                        />
                      </>
                    ) : null}
                  </div>
                ) : null
              ) : null}
              <ButtonContainer>
                <GreenButton disabled={!isWorksheetFilled(true)} disableElevation variant="contained" type="submit">
                  Save Information and Update Worksheet
                </GreenButton>
              </ButtonContainer>
            </form>
          </div>
        </div>
      )}
    </Box>
  );
};

interface StateAutofill {
  unitType: string;
  modelType: string;
}

const SubHeading = styled.h2`
  margin: 0;
`;

const CompactPurchaserInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 20px;
`;

const StyledAccordion = styled(Accordion)({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    '&: first-of-type': {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    '&: before': {
      display: 'none',
    },
  },
  '.MuiIconButton-root': {
    right: '-1px',
    width: '26px',
    height: '26px',
    marginRight: '10px',
    border: '1px solid #c4c4c4',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '0px',
    '@media (max-width: 768px)': {
      marginTop: '11px',
    },
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    border: '1px solid #c4c4c4',
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
    '&.Mui-expanded': {
      borderBottomRightRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '4px',
      borderTopLeftRadius: '4px',
    },
    '&: hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
});

const ToggleContainer = styled.div`
  margin-bottom: 25px;
  @media (max-width: ${mdWidth}) {
    width: 50%;
    margin-right: 0;
  }
  @media (max-width: ${smWidth}) {
    margin-right: 0;
  }
`;

const InsideText = styled.div`
  text-align: center;
  color: #666666;
  padding-top: 10px;
`;

const DropzoneContainer = styled(Grid)`
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const Flex = styled(Grid)`
  margin-top: 15px;
`;

const UNITFINDBYID = gql`
  query UnitById($id: MongoID!) {
    unitById(_id: $id) {
      project {
        _id
      }
      _id
      suite
      unit
      level
      modelType
      unitType
      basePrice
      getUrl
      status
      floorPlan {
        _id
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
      }
      worksheets {
        _id
        realtor {
          _id
        }
        unit {
          _id
        }
      }
      row
      col
    }
  }
`;

const UPLOADID = gql`
  mutation uploadID($id: MongoID!, $name: String!, $file: Upload!) {
    uploadID(id: $id, name: $name, file: $file) {
      identifications {
        _id
        name
        getUrl
      }
    }
  }
`;

const UPLOADCHEQUE = gql`
  mutation uploadCheque($id: MongoID!, $name: String!, $file: Upload!) {
    uploadCheque(id: $id, name: $name, file: $file) {
      chequeImages {
        _id
        name
        getUrl
      }
    }
  }
`;

const UPDATEWORKSHEET = gql`
  mutation updateWorksheet($_id: MongoID!, $record: UpdateByIdWorksheetInput!, $purchasers: [WorksheetPurchaserInput]!) {
    updateWorksheet(_id: $_id, record: $record, purchasers: $purchasers) {
      _id
    }
  }
`;

const WORKSHEETONE = gql`
  query viewWorksheet($_id: MongoID!) {
    viewWorksheet(_id: $_id) {
      _id
      wishlist
      unit {
        _id
      }
      project {
        _id
      }
      wishlistChoices {
        unitType
        modelTypes
        exposure
        level
      }
      purchasers {
        _id
        project {
          name
          _id
        }
        corp
        firstName
        lastName
        email
        identifications {
          _id
          name
          getUrl
          putUrl
        }
        primaryPhone
        dob
        occupation
        employer
        streetAddress
        city
        province
        postalCode
        country
        idType
        idNumber
        idExpiry
        idJurisdiction
        sin
        purchaserType
        getUrl
        putUrl
        signingOfficers {
          dob
          fullName
          sin
          streetAddress
          primaryPhone
          email
        }
      }
      options {
        name
        purchaseAmount
        amount
      }
      chequeImages {
        _id
        name
        getUrl
      }
      realtor {
        _id
      }
      status
      notes
      joinWaitlist
    }
  }
`;

const UPDATEPURCHASER = gql`
  mutation purchaserUpdateById($_id: MongoID!, $record: UpdateByIdPurchaserInput!) {
    purchaserUpdateById(_id: $_id, record: $record) {
      record {
        email
        identifications {
          name
          getUrl
        }
        signingOfficers {
          dob
          fullName
          sin
          streetAddress
          primaryPhone
          email
        }
        firstName
        lastName
        fullName
        sin
        dob
        streetAddress
        city
        province
        postalCode
        occupation
        employer
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
    }
  }
`;

const CREATEWORKSHEET = gql`
  mutation worksheetCreateOne(
    $isPublic: Boolean!
    $inPerson: Boolean!
    $wishlist: Boolean!
    $project: MongoID!
    $unit: MongoID
    $purchasers: [NewPurchaserInput]!
    $realtor: NewRealtorInput
    $user: MongoID
    $options: [NewOptionInput]
    $joinWaitlist: [String]
    $notes: String
    $wishlistChoices: [NewWishlistInput]
    $worksheetBasePrice: Float
    $chequeImages: [NewIdentificationField]
    $chequeFiles: [Upload]
  ) {
    worksheetCreateOne(
      isPublic: $isPublic
      inPerson: $inPerson
      wishlist: $wishlist
      project: $project
      unit: $unit
      purchasers: $purchasers
      realtor: $realtor
      user: $user
      options: $options
      joinWaitlist: $joinWaitlist
      notes: $notes
      wishlistChoices: $wishlistChoices
      worksheetBasePrice: $worksheetBasePrice
      chequeImages: $chequeImages
      chequeFiles: $chequeFiles
    ) {
      _id
    }
  }
`;

export default CreateWorksheet;
