/* Dependency Imports */
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
import { Button, Box } from '@mui/material';

/* Project Imports */
import SortableTable from '../common/SortableTable';
import LoadingWrapper from '../common/LoadingWrapper';
import { Title } from '../../utils/CommonStyles';
import { IMortgage } from '../../types/deal';
import { capitalizeFirstLetter, downloadExcel, numberWithCommas, downloadPDF } from '../../utils/Functions';
import { chequeTypes, chequeDescriptions } from '../../utils/Constants';
import { IDocument } from '../../types/document';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';

const headers: string[] = ['suiteNumber', 'status', 'purchaser', 'mortgage', 'amendments'];
const formattedHeaders: string[] = ['Suite Number', 'Status', 'Purchaser', 'Mortgage', 'Amendments'];
const columnWidths: any = {
  status: 15,
  suiteNumber: 15,
  amendments: 30,
  purchaser: 30,
  mortgage: 13,
  solicitorInfo: 34,
};
const pdfColumnWidths: any = {
  status: 50,
  suiteNumber: 90,
  amendments: 200,
  purchaser: 200,
  mortgage: 80,
  solicitorInfo: 200,
};

const red = chequeTypes.nsf;
const green = chequeTypes.received;
const yellow = chequeTypes.replacement;

const Outstandings = () => {
  const storeDispatch = useAppDispatch();
  const { project } = useSelector(selectProject);
  const user = useSelector(selectUser);

  /* States */
  const [outstandings, setOutstandings] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [cellBackgrounds, setCellBackground] = useState<any[]>([]);
  const [shownHeaders, setShownHeaders] = useState<string[]>(headers);
  const [shownFormattedHeaders, setShownFormattedHeaders] = useState<string[]>(formattedHeaders);

  /* Queries */
  const { loading } = useQuery<IDealRealtorMany>(DEALMANY, {
    skip: !project._id || !user?.realtor?._id,
    variables: {
      filter: {
        realtor: user?.realtor._id,
        projects: project.combinedProjects.length
          ? [project._id, ...project.combinedProjects.map((project) => project._id)]
          : [project._id]
      },
    },
    onCompleted: (data) => {
      let maxDeposits = 0;
      let newCellBackgrounds: any[] = [];
      let newExcelData: any[] = [];
      let newOutstandings: any[] = [];
      data.dealRealtorMany.forEach(({ deal }) => {
        if (deal.cancelled.dateCancelled || deal.rescission.dateRescinded) return;
        const statusDate = getStatusDate(deal);
        let outstanding: any = {
          status: (
            <div>
              <NoWrap>{deal.unit.status}</NoWrap>
              <NoWrap>({statusDate})</NoWrap>
            </div>
          ),
          suiteNumber: deal.unit.suite,
          purchaser: getPurchaserInfo(deal.purchasers[0]),
          amendments: getAmendmentInfo(deal.documents),
        };
        let cellBackground: any = {
          amendments: getAmendmentBackground(deal.documents),
        };
        let excelData: any = {
          status: deal.unit.status + `\r\n(${statusDate})`,
          suiteNumber: deal.unit.suite,
          purchaser: getExcelPurchaserInfo(deal.purchasers[0]),
          amendments: getExcelAmendmentInfo(deal.documents),
        };

        if (deal.solicitor?.solicitor && deal.solicitor.primaryPhone && deal.solicitor.email) {
          outstanding.solicitorInfo = getSolicitorCell(deal.solicitor);
          excelData.solicitorInfo = getExcelSolicitorInfo(deal.solicitor);
          cellBackground.solicitorInfo = green;
        } else {
          outstanding.solicitorInfo = <b>Not Received</b>;
          excelData.solicitorInfo = 'Not Received';
          cellBackground.solicitorInfo = red;
        }

        let totalDeposits = 0;
        deal.deposit.forEach((deposit, index) => {
          let i = index + 1;
          let depositKey = 'deposit' + i;
          outstanding[depositKey] = getCheckTypeCell(deposit);
          excelData[depositKey] = getExcelCheckType(deposit);
          cellBackground[depositKey] = chequeTypes[deposit.chequeType] ?? red;
          totalDeposits += deposit.amount;
          if (i > maxDeposits) {
            headers.push(depositKey);
            columnWidths[depositKey] = 31;
            pdfColumnWidths[depositKey] = 190;
            formattedHeaders.push(`Deposit #${i}`);
            maxDeposits = i;
          }
        });

        if (deal.mortgage?.amount && !deal.mortgage.getUrl && deal.mortgage.lender) {
          outstanding.mortgage = <CheckIcon style={{ width: '100%' }} />;
          excelData.mortgage = 'Received';
          cellBackground.mortgage = green;
        } else {
          outstanding.mortgage = '$' + numberWithCommas(deal.basePrice - totalDeposits);
          excelData.mortgage = '$' + numberWithCommas(deal.basePrice - totalDeposits);
          cellBackground.mortgage = red;
        }

        newCellBackgrounds.push(cellBackground);
        newExcelData.push(excelData);
        newOutstandings.push(outstanding);
      });

      const headerLength = headers.length;
      newOutstandings.forEach((outstanding, index) => {
        let emptyDeposits = headerLength - Object.keys(outstanding).length + 1;
        if (!emptyDeposits) return;
        for (let i = 1; i < emptyDeposits + 1; ++i) {
          outstanding[headers[headerLength - i]] = 'N/A';
          newExcelData[index][headers[headerLength - i]] = 'N/A';
        }
      });

      headers.push('solicitorInfo');
      formattedHeaders.push('Solicitor Info');

      setExcelData(newExcelData);
      setOutstandings(newOutstandings);
      setCellBackground(newCellBackgrounds);
      setShownHeaders([...headers]);
      setShownFormattedHeaders([...formattedHeaders]);
    },
    onError: (error) => {
      storeDispatch(showErrorSnackbar('Error finding outstandings'));
    },
  });

  /* Functions */
  const getCheckTypeCell = (deposit: IDeposit) => {
    return (
      <div>
        <NoWrap>Status: {deposit.chequeType ? capitalizeFirstLetter(deposit.chequeType) : <b>Not Received</b>}</NoWrap>
        <NoWrap>Amount: ${numberWithCommas(deposit.amount)}</NoWrap>
        <NoWrap>
          {deposit.chequeType ? 'Cheque Date' : 'Due Date'}:{' '}
          {deposit.chequeType
            ? deposit.chequeDate
              ? new Date(deposit.chequeDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })
              : 'Not Received'
            : new Date(deposit.dueDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })}
        </NoWrap>
      </div>
    );
  };

  const getExcelCheckType = (deposit: IDeposit) => {
    let content = `Status: ${
      deposit.chequeType ? capitalizeFirstLetter(deposit.chequeType) : 'Not Received'
    }\r\nAmount: $${numberWithCommas(deposit.amount)}`;
    content += `\r\n${deposit.chequeType ? 'Cheque Date' : 'Due Date'}: ${
      deposit.chequeType
        ? deposit.chequeDate
          ? new Date(deposit.chequeDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })
          : 'Not Received'
        : new Date(deposit.dueDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })
    }`;
    return content;
  };

  const getSolicitorCell = (solicitor: ISolicitor) => {
    return (
      <div>
        <NoWrap>Full Name: {solicitor.solicitor}</NoWrap>
        <NoWrap>Email: {solicitor.email}</NoWrap>
        <NoWrap>Phone: {solicitor.primaryPhone}</NoWrap>
      </div>
    );
  };

  const getExcelSolicitorInfo = (solicitor: ISolicitor) => {
    return `Full Name: ${solicitor.solicitor}\r\nEmail: ${solicitor.email}\r\nPhone: ${solicitor.primaryPhone}`;
  };

  const getPurchaserInfo = (purchaser: IPurchaser) => {
    return (
      <div>
        <NoWrap>
          Full Name: {purchaser.firstName} {purchaser.lastName}
        </NoWrap>
        <NoWrap>Email: {purchaser.email}</NoWrap>
        <NoWrap>Phone: {purchaser.primaryPhone}</NoWrap>
      </div>
    );
  };

  const getExcelPurchaserInfo = (purchaser: IPurchaser) => {
    return `Full Name: ${purchaser.firstName} ${purchaser.lastName}\r\nEmail: ${purchaser.email}\r\nPhone: ${purchaser.primaryPhone}`;
  };

  const getStatusDate = (deal: IDeal) => {
    let date;
    switch (deal.unit.status) {
      case 'F':
        date = deal.firmDate;
        break;
      case 'S':
        date = deal.signDate;
        break;
      case 'C':
        date = deal.executeDate;
        break;
      default:
        date = deal.createdAt;
        break;
    }
    return new Date(date).toLocaleDateString('en-us', { month: 'long', year: 'numeric', day: 'numeric' });
  };

  const getAmendmentInfo = (amendments: IDocument[]) => {
    return (
      <div>
        {amendments.length ? (
          amendments.map((amendment) => (
            <NoWrap>
              {amendment.type} - {amendment.status}
            </NoWrap>
          ))
        ) : (
          <NoWrap>None</NoWrap>
        )}
      </div>
    );
  };

  const getExcelAmendmentInfo = (amendments: IDocument[]) => {
    if (!amendments.length) return 'None';
    let info = '';
    for (let i = 0; i < amendments.length; ++i) {
      info += amendments[i].type + ' - ' + amendments[i].status + '\r\n';
    }
    return info;
  };

  const getAmendmentBackground = (amendments: IDocument[]) => {
    if (!amendments.length) return null;
    let allCompleted = true;
    for (const amendment of amendments) {
      if (amendment.status === 'Sent') return red;
      if (amendment.status === 'Signed') return yellow;
      if (amendment.status !== 'Completed') allCompleted = false;
    }
    return allCompleted ? green : red;
  };

  const showHeader = (index: number) => {
    let i = 0;
    while (i < shownHeaders.length) {
      if (headers.indexOf(shownHeaders[i]) > index) break;
      ++i;
    }
    let newShownHeaders = [...shownHeaders];
    let newFormattedShownHeaders = [...shownFormattedHeaders];
    newShownHeaders.splice(i, 0, headers[index]);
    newFormattedShownHeaders.splice(i, 0, formattedHeaders[index]);
    setShownHeaders(newShownHeaders);
    setShownFormattedHeaders(newFormattedShownHeaders);
  };

  const hideHeader = (index: number) => {
    setShownHeaders(shownHeaders.filter((shownHeader) => shownHeader !== headers[index]));
    setShownFormattedHeaders(shownFormattedHeaders.filter((formattedHeader) => formattedHeader !== formattedHeaders[index]));
  };

  return loading ? (
    <LoadingWrapper title={'Outstandings are loading..'} modal={false} />
  ) : (
    <Box sx={{ p: 3 }}>
      <Title style={{ margin: '0px' }}>Outstandings</Title>
      <ResponsiveDiv>
        <div>
          <p>
            Please email all outstanding information to <a href={`mailto:${project.email}`}>{project.email}</a>
          </p>
          <p>For all outstandings cheques, please come directly to the office and drop them off</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
          <div>Cheque Types</div>
          {Object.keys(chequeTypes).map((key) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{ backgroundColor: chequeTypes[key], width: '20px', height: '10px', border: '1px solid black', marginRight: '10px' }}
              ></div>
              <span>
                {capitalizeFirstLetter(key)} - {chequeDescriptions[key]}
              </span>
            </div>
          ))}
        </div>
        {outstandings.length ? (
          <Box sx={{ marginTop: '10px' }}>
            <Button
              sx={{ marginRight: '10px' }}
              variant="contained"
              color="primary"
              onClick={() =>
                downloadPDF(
                  excelData,
                  shownHeaders,
                  shownFormattedHeaders,
                  cellBackgrounds,
                  pdfColumnWidths,
                  project.name + ' Outstandings.pdf'
                )
              }
            >
              Download PDF
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                downloadExcel(
                  excelData,
                  shownHeaders,
                  shownFormattedHeaders,
                  cellBackgrounds,
                  columnWidths,
                  'Outstandings',
                  project.name + ' Outstandings.xlsx'
                )
              }
            >
              Download Excel
            </Button>
          </Box>
        ) : null}
      </ResponsiveDiv>
      {/* {outstandings.length ? (
        <div>
          <h3 style={{ marginBottom: '0px' }}>Displayed Columns</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {formattedHeaders.map((header, index) => (
              <FormControlLabel
                sx={{
                  marginTop: '10px',
                  marginBottom: '15px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  '.MuiTypography-root': {
                    marginTop: '8px',
                  },
                }}
                control={
                  <Checkbox
                    checked={shownFormattedHeaders.includes(header)}
                    onChange={(e) => (e.target.checked ? showHeader(index) : hideHeader(index))}
                  />
                }
                label={header}
              />
            ))}
          </div>
        </div>
      ) : null} */}
      {outstandings.length ? (
        <SortableTable
          headers={shownHeaders}
          formattedHeaders={shownFormattedHeaders}
          rows={outstandings}
          cellBackgrounds={cellBackgrounds}
        />
      ) : (
        <p>You currently have no outstandings</p>
      )}
    </Box>
  );
};

/* Types */
interface IDealRealtorMany {
  dealRealtorMany: IDealRealtor[];
}

interface IDealRealtor {
  deal: IDeal;
}

interface IDeal {
  unit: IUnit;
  purchasers: IPurchaser[];
  mortgage: IMortgage | null;
  solicitor: ISolicitor | null;
  deposit: IDeposit[];
  rescission: IRescission;
  documents: IDocument[];
  createdAt: string;
  signDate: string;
  executeDate: string;
  firmDate: string;
  basePrice: number;
  cancelled: ICancelled;
}

interface IPurchaser {
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
}

interface IUnit {
  suite: string;
  status: string;
}

interface ISolicitor {
  solicitor: string;
  email: string;
  primaryPhone: string;
}

interface IDeposit {
  chequeType: string;
  amount: number;
  dueDate: string;
  chequeDate: string;
}

interface IRescission {
  dateRescinded: string | null;
}

interface ICancelled {
  dateCancelled: string | null;
}

/* Styled Components */
const NoWrap = styled.div`
  white-space: nowrap;
`;

const ResponsiveDiv = styled.div`
  display: flex, 
  justify-content: space-between
`;

/* Queries */
const DEALMANY = gql`
  query dealRealtorMany($filter: FilterFindManyDealRealtorInput!) {
    dealRealtorMany(filter: $filter) {
      deal {
        unit {
          suite
          status
        }
        purchasers {
          firstName
          lastName
          email
          primaryPhone
        }
        mortgage {
          lender
          amount
          getUrl
        }
        solicitor {
          solicitor
          email
          primaryPhone
        }
        deposit {
          chequeType
          amount
          dueDate
          chequeDate
        }
        rescission {
          dateRescinded
        }
        documents {
          type
          status
        }
        createdAt
        signDate
        executeDate
        firmDate
        cancelled {
          dateCancelled
        }
        basePrice
      }
    }
  }
`;

export default Outstandings;
