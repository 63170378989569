import React from 'react';
import { Box, Typography, Button, Grid, Fade, Paper } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import 'photoswipe/dist/photoswipe.css';
import './photoswipe.css';

import { Gallery, Item } from 'react-photoswipe-gallery';
// import styles

import { IProject } from '../../types/project';

// import plugins if you need
import { IMedia } from '../../types/portal';
import { FlexBetween } from '../../utils/CommonStyles';
import { downloadMedia, imageUrlsToBlob } from '../../utils/Functions';

const Assets = (props: ChildProps) => {
  const { project, titles, images } = props;

  return (
    <Fade in={true} timeout={2000}>
      <Box
        sx={{
          '@media (max-width: 768px)': {
            height: '100%',
            overflowY: 'hidden',
          },
        }}
      >
        {titles.map((title: string, index: number) => {
          let levels: any[] = [];
          if (title === 'Aerial Photography') {
            levels = [...new Set(images[index].map((item: any) => item.name.replace(/\D/g, '')))].sort((a: any, b) =>
              a.localeCompare(b, undefined, { numeric: true })
            );
          }

          return (
            <React.Fragment key={index}>
              {images[index].length ? (
                <FlexBetween
                  sx={{
                    '@media (max-width: 768px)': {
                      mt: 4,
                      p: 2,
                      mb: 0,
                      backgroundColor: project.portal.primaryColor,
                    },
                    my: 2,
                  }}
                >
                  <Typography
                    variant="h3"
                    color="#fff"
                    sx={{
                      fontFamily: 'Abadi-Bold !important',
                      mt: index > 0 ? 2 : 0,
                      '@media (max-width: 768px)': {
                        color: '#fff',
                        mt: 0,
                      },
                    }}
                  >
                    {title}
                  </Typography>
                  <Box sx={{ alignSelf: 'center' }}>
                    <Button
                      variant="contained"
                      sx={{
                        '@media (max-width: 768px)': {
                          display: 'none',
                        },
                        display: 'block',
                        backgroundColor: project.portal.primaryColor === '#000000' ? '#fff' : project.portal.primaryColor,
                        color: project.portal.primaryColor === '#000000' ? '#000' : '#fff',
                        ':hover': {
                          backgroundColor: project.portal.primaryColor,
                          color: '#fff',
                        },
                        mr: 1,
                        alignSelf: 'center',
                        fontFamily: 'Abadi-Standard !important',
                      }}
                      onClick={async () => {
                        let imagesBase64 = await imageUrlsToBlob(
                          images[index].map((image: IMedia) => image.getUrl),
                          []
                        );
                        downloadMedia(imagesBase64, `${project.name} ${title}`, title);
                      }}
                    >
                      Download All {title}
                    </Button>
                    <DownloadIcon
                      sx={{
                        '@media (max-width: 768px)': {
                          display: 'block',
                        },
                        display: 'none',
                        color: '#fff',
                        cursor: 'pointer',
                        mr: 1,
                        alignSelf: 'center',
                        fontFamily: 'Abadi-Standard !important',
                      }}
                      onClick={async () => {
                        let imagesBase64 = await imageUrlsToBlob(
                          images[index].map((image: IMedia) => image.getUrl),
                          []
                        );
                        downloadMedia(imagesBase64, `${project.name} ${title}`, title);
                      }}
                    />
                  </Box>
                </FlexBetween>
              ) : null}
              {title === 'Aerial Photography' && levels.length && levels[0] ? (
                levels.map((level: string) => {
                  return (
                    <>
                      <Typography
                        variant="h3"
                        color="#fff"
                        sx={{
                          '@media (max-width: 768px)': {
                            color: '#000',
                            mt: 2,
                            px: 2,
                          },
                          fontFamily: 'Abadi-Bold !important',
                          mb: 2,
                          mt: index > 0 ? 2 : 0,
                        }}
                      >
                        Level {level} Views
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '@media (max-width: 768px)': {
                            p: 2,
                          },
                        }}
                      >
                        <Gallery withDownloadButton={true} withCaption={true}>
                          {images[index]
                            .filter((rendering: IMedia) => rendering.name.includes(level))
                            .map((rendering: IMedia, index: number) => {
                              return (
                                <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  xl={2}
                                  sx={{ cursor: 'pointer', height: '300px', flexGrow: 1 }}
                                >
                                  <Paper elevation={24} sx={{ height: '100%' }}>
                                    <Item original={rendering.getUrl} caption={rendering.name}>
                                      {({ ref, open }) => (
                                        <img
                                          ref={ref}
                                          onClick={open}
                                          alt={rendering.name}
                                          src={rendering.getThumbUrl ? rendering.getThumbUrl : rendering.getUrl}
                                          style={{ maxWidth: '100%', objectFit: 'cover', height: '100%', width: '100%' }}
                                          loading="lazy"
                                        />
                                      )}
                                    </Item>
                                  </Paper>
                                </Grid>
                              );
                            })}
                        </Gallery>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '@media (max-width: 768px)': {
                      p: 2,
                    },
                  }}
                >
                  <Gallery withDownloadButton={true} withCaption={true}>
                    {images[index].map((rendering: IMedia, index: number) => {
                      return (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          xl={3}
                          desktop={2}
                          sx={{ cursor: 'pointer', height: '300px', flexGrow: 1 }}
                        >
                          <Paper elevation={24} sx={{ height: '100%' }}>
                            <Item original={rendering.getUrl} caption={rendering.name}>
                              {({ ref, open }) => (
                                <img
                                  ref={ref}
                                  onClick={open}
                                  alt={rendering.name}
                                  src={rendering.getThumbUrl ? rendering.getThumbUrl : rendering.getUrl}
                                  style={{
                                    maxWidth: '100%',
                                    objectFit: titles.includes('Renderings') ? 'cover' : 'contain',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                  loading="lazy"
                                />
                              )}
                            </Item>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Gallery>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </Fade>
  );
};

interface ChildProps {
  project: IProject;
  titles: string[];
  images: any[];
}

export default Assets;
