import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Box, Checkbox } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { getInnerText, numToCurrency } from '../../utils/Functions';

const SortableTable = <T extends any>(props: ChildProps<T>) => {
  const {
    headers,
    rows,
    formattedHeaders,
    rowOnClick,
    span,
    sticky,
    checked,
    allChecked,
    setChecked,
    setAllChecked,
    defaultSort,
    cellBackgrounds,
    setParentRows,
  } = props;
  const [columnToSort, setColumnToSort] = useState<string>(defaultSort ? defaultSort : headers[0]);
  const [sortAsc, setsortAsc] = useState<boolean>(true);
  const [tableRows, setTableRows] = useState<any>(rows);
  const [tableBackgrounds, setTableBackgrounds] = useState<any>(cellBackgrounds);

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  useEffect(() => {
    setTableBackgrounds(cellBackgrounds);
  }, [cellBackgrounds]);

  useEffect(() => {
    const sortedRows = rows?.slice();
    sortedRows?.sort((a: any, b: any) => {
      //a and b are IUnits
      let keyA = a[columnToSort];
      let keyB = b[columnToSort];
      if (columnToSort === 'low' || columnToSort === 'high') {
        keyA = a['basePrice'];
        keyB = b['basePrice'];

        if (columnToSort === 'low') {
          if (typeof keyA === 'number' && typeof keyB === 'number') return sortAsc ? keyA - keyB : keyB - keyA;
        }
        if (columnToSort === 'high') {
          if (typeof keyA === 'number' && typeof keyB === 'number') return sortAsc ? keyB - keyA : keyA - keyB;
        }
      }

      if (keyA === null && keyB === null) return 0;
      else if (keyA === null) return 1;
      else if (keyB === null) return -1;

      if (typeof keyA === 'number' && typeof keyB === 'number') return sortAsc ? keyA - keyB : keyB - keyA;

      typeof keyA === 'string' ? keyA.toLowerCase() : (keyA = React.isValidElement(keyA) ? getInnerText(keyA) : keyA.toString());
      typeof keyB === 'string' ? keyB.toLowerCase() : (keyB = React.isValidElement(keyB) ? getInnerText(keyB) : keyB.toString());

      return sortAsc ? keyA.localeCompare(keyB, 'en', { numeric: true }) : -keyA.localeCompare(keyB, 'en', { numeric: true });
    });

    if (tableBackgrounds) setTableBackgrounds(sortedRows.map((row) => tableBackgrounds[tableRows.indexOf(row)]));

    if (setParentRows) setParentRows([...sortedRows]);

    setTableRows(sortedRows);
    // eslint-disable-next-line
  }, [columnToSort, sortAsc]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader>
        <TableHead>
          <TableRow>
            {setChecked && ( //filler column header
              <TableCell>
                <div></div>
              </TableCell>
            )}
            {setAllChecked && ( //filler column header
              <TableCell>
                <div></div>
              </TableCell>
            )}
            {headers.map((header, i) => (
              <TableCell
                key={i}
                onClick={() => {
                  if (header === columnToSort) setsortAsc(!sortAsc);
                  else {
                    setColumnToSort(header);
                    setsortAsc(true);
                  }
                }}
                align={'left'}
                sx={sticky && sticky.includes(header) ? { position: 'sticky', right: 0, background: 'white' } : {}}
              >
                <TableSortLabel active={columnToSort === header} direction={sortAsc ? 'asc' : 'desc'}>
                  <strong>{formattedHeaders ? formattedHeaders[i] : header}</strong>
                  {columnToSort === header ? (
                    <Box component="span" sx={visuallyHidden}>
                      {sortAsc ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows?.map((row: any, i: number) => (
            <TableRow
              key={i}
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
              onClick={() => {
                rowOnClick && rowOnClick(row);
              }}
            >
              {setChecked && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={row._id === checked}
                    onClick={() => {
                      setChecked(row);
                    }}
                  />
                </TableCell>
              )}
              {setAllChecked && (
                <TableCell
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  padding="checkbox"
                >
                  <Checkbox
                    color="primary"
                    checked={allChecked && allChecked.indexOf(row) > -1}
                    onClick={(e) => {
                      e.stopPropagation();
                      allChecked && setAllChecked(row);
                    }}
                  />
                </TableCell>
              )}
              {headers.map((header: string, j: number) => {
                if (Array.isArray(row[header])) {
                  return (
                    <TableCell
                      key={j}
                      align={'left'}
                      colSpan={span && span[header]}
                      style={{
                        backgroundColor:
                          tableBackgrounds && tableBackgrounds[i] && tableBackgrounds[i][header] ? tableBackgrounds[i][header] : 'white',
                        whiteSpace: 'pre-line',
                      }}
                      sx={sticky && sticky.includes(header) ? { position: 'sticky', right: 0, background: 'white' } : {}}
                    >
                      {row[header].map((item: any, k: number) => {
                        return <div key={k}>{`${item}`}</div>;
                      })}
                    </TableCell>
                  );
                } else if (React.isValidElement(row[header])) {
                  return (
                    <TableCell
                      key={j}
                      align={'left'}
                      colSpan={span && span[header]}
                      style={{
                        backgroundColor:
                          tableBackgrounds && tableBackgrounds[i] && tableBackgrounds[i][header] ? tableBackgrounds[i][header] : 'white',
                        whiteSpace: 'pre-line',
                      }}
                      sx={sticky && sticky.includes(header) ? { position: 'sticky', right: 0, background: 'white' } : {}}
                    >
                      {header === 'basePrice' ? numToCurrency.format(row[header]) : row[header]}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={j}
                      align={'left'}
                      colSpan={span && span[header]}
                      style={{
                        backgroundColor:
                          tableBackgrounds && tableBackgrounds[i] && tableBackgrounds[i][header] ? tableBackgrounds[i][header] : 'white',
                        whiteSpace: 'pre-line',
                      }}
                      sx={sticky && sticky.includes(header) ? { position: 'sticky', right: 0, background: 'white' } : {}}
                    >
                      {header === 'basePrice' ? numToCurrency.format(row[header]) : row[header]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface ChildProps<T> {
  headers: string[];
  defaultSort?: string;
  formattedHeaders?: string[];
  rows: T[];
  span?: { [key: string]: number };
  rowOnClick?: (row: T) => void;
  sticky?: string[];
  checked?: string;
  allChecked?: any[];
  setChecked?: (selected: any) => void;
  setAllChecked?: (selected: any) => void;
  setParentRows?: (rows: any) => void;
  cellBackgrounds?: { [key: string]: string }[];
}

export default SortableTable;
