/* Dependency Imports */
import { useContext, useEffect } from 'react';
import { Box, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import styled from 'styled-components';

/* Project Imports */
import { AppointmentContext } from '../../../context/AppointmentContext';
import { Title } from '../../../utils/CommonStyles';
import { ISchedule } from '../../../types/appointment';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';

const SelectLocation = (props: ChildProps) => {
  /* Props */
  const { schedules, setNext, page, index } = props;

  /* Contexts */
  const [appointment, dispatchAppointment] = useContext(AppointmentContext);
  const { project } = useSelector(selectProject);

  /* Effects */
  useEffect(() => {
    dispatchAppointment({ type: 'SET_LOCATION', payload: { value: schedules[0].locations[0] } });
  }, []);
  useEffect(() => {
    if (page === index) {
      if (!appointment.location) {
        setNext(false);
      } else {
        setNext(true);
      }
    }
  }, [appointment, setNext]);

  /* Functions */
  const setLocation = (value: string) => {
    dispatchAppointment({ type: 'SET_LOCATION', payload: { value: value } });
  };

  const mapURL = encodeURI(
    'https://maps.googleapis.com/maps/api/staticmap?size=1200x1200&markers=' +
      appointment.location +
      '&key=' +
      process.env.REACT_APP_REACT_MAPS
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 3 }}>
      <Title>Appointment Location</Title>
      <ContentWrapper>
        <ImageContainer image={mapURL} />
        <RadioWrapper>
          <FormControl>
            <FormLabel id="radio-label">Address</FormLabel>
            <RadioGroup
              aria-labelledby="radio-label"
              name="address-radio"
              value={appointment.location}
              onChange={(e) => setLocation(e.target.value)}
            >
              {schedules[0].locations.map((location: string, index: number) => {
                return <Label value={location} control={<Radio />} label={location} key={index} />;
              })}
            </RadioGroup>
          </FormControl>
        </RadioWrapper>
      </ContentWrapper>
    </Box>
  );
};

/* Types */
interface ChildProps {
  page: number;
  index: number;
  setNext: (next: boolean) => void;
  schedules: ISchedule[];
}

/* Styled Components */
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const SelectLocationWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RadioWrapper = styled.div`
  flex: 1;
  padding-left: 50px;
  @media (max-width: 700px) {
    margin-top: 10px;
    padding-left: 0px;
  }
`;

const Label = styled(FormControlLabel)`
  margin-top: 10px;
`;

const ImageContainer = styled.div<{ image: string }>`
  flex: 1;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export default SelectLocation;
