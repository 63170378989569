import { useState, FormEvent } from 'react';
import { withRouter } from '../../utils/WithRouter';
import { MutationFunction } from '@apollo/client';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Heading } from '../../utils/CommonStyles';
import styled from 'styled-components';
import { theme } from '../../theme/theme';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { validatePassword } from '../../utils/Functions';

const ProfileResetPassword = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const { updateUserInfo } = props;
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [confirmError, setConfirmError] = useState<string>('');

  // Password Validation

  const handleValidation = (type: string) => {
    if (type === 'password') {
      if (!validatePassword(password)) {
        setError('Passwords needs to have minimum 8 characters, at least one numerical character and one special character!');
      } else {
        setError('');
      }
    }
    if (type === 'confirmPassword') {
      if (!validatePassword(confirmPassword)) {
        setConfirmError('Passwords needs to have minimum 8 characters, at least one numerical character and one special character!');
      } else {
        setConfirmError('');
      }
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (error || confirmError) {
      return;
    }
    if (password !== confirmPassword) {
      return storeDispatch(showErrorSnackbar('Passwords are not the same!'));
    }

    updateUserInfo({
      variables: {
        _id: user?._id,
        record: {
          password: confirmPassword,
        },
      },
    });
    storeDispatch(showSuccessSnackbar('Password successfully updated'));
  };

  return (
    <>
      <ProfileResetPasswordContainer container spacing={2}>
        <Heading item lg={3} md={4} sm={12} xs={12}>
          Reset Password
        </Heading>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <TextField
              required
              name="password"
              label="Please Enter A New Password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onBlur={() => handleValidation('password')}
              error={error ? true : false}
              helperText={error ? error : ''}
              inputProps={{ minLength: 8 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                      <VisibilityOffIcon sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            <TextField
              required
              name="confirmPassword"
              label="Confirm New Password"
              value={confirmPassword}
              type={showConfirmPassword ? 'text' : 'password'}
              onBlur={() => handleValidation('confirmPassword')}
              error={confirmError ? true : false}
              helperText={confirmError ? confirmError : ''}
              inputProps={{ minLength: 8 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showConfirmPassword ? (
                      <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    ) : (
                      <VisibilityOffIcon sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
            />
            <SubmitButton type="submit" variant="contained" color="primary">
              Submit
            </SubmitButton>
          </Form>
        </Grid>
      </ProfileResetPasswordContainer>
    </>
  );
};

const ProfileResetPasswordContainer = styled(Grid)`
  display: flex;
  margin-top: 50px !important;
`;

export const SubmitButton = styled(Button)`
  & :hover {
    background-color: ${theme.palette.action.hover};
  }
  align-self: flex-end;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 200px;
`;

interface ChildProps {
  updateUserInfo: MutationFunction;
}

export default withRouter(ProfileResetPassword);
