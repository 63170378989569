import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { DealsPageLine } from '../../utils/CommonStyles';
import { IWorksheetPurchaserInfo } from '../../types/worksheet';
import { IDeal, IDealById } from '../../types/deal';
import PurchaserInfo from '../worksheet/PurchaserInfo';
import UnitInfoHeader from '../worksheet/UnitInfoHeader';
import DepositStructure from './DepositStructure';
import DealRealtorInfo from './DealRealtorInfo';
import SolicitorInfo from './SolicitorInfo';
import MortgagePreapproval from './MortgagePreapproval';
import ApsDocuments from './DealDocuments';
import VerticalStepper from '../common/VerticalStepper';
import ThirdPartyVerification from './ThirdPartyVerification';
import LoadingWrapper from '../common/LoadingWrapper';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';

const HEADERS = [
  'Suite Info',
  'Purchaser',
  'Realtor',
  'Deposits',
  'Solicitor',
  'Mortgage Preapproval',
  'Documents',
  'Third Party Verification',
];

const DealDetailPage = () => {
  const { project } = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [deal, setDeal] = useState<IDeal>();
  const [selectedUnitType, setSelectedUnitType] = useState('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { dealid } = useParams();

  useQuery(GETDEALBYID, {
    variables: { deal: dealid, project: project._id, realtor: user.realtor._id },
    onCompleted: (data) => {
      setDeal(data.getRealtorDeal);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    const onScroll = (e: Event) => {
      HEADERS.forEach((header: string, i: number) => {
        const currentDealSection = document.getElementById(`dealSection${i}`)?.getBoundingClientRect();
        if (currentDealSection?.top && currentDealSection.top >= 0 && currentDealSection.top <= 200) setCurrentIndex(i);
      });
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  const scrollTo = (elem: HTMLElement | null) => {
    if (elem) {
      setTimeout(() => {
        window.scrollTo({
          top: elem.offsetTop - 90,
          behavior: 'smooth',
        });
      }, 2);
    }
  };

  const handleStepNavigation = (currentIndex: number) => {
    scrollTo(document.getElementById(`dealSection${currentIndex}`));
  };

  return (
    <Box sx={{ py: 3 }}>
      {deal ? (
        <>
          <StepperContainer>
            <VerticalStepper currentIndex={currentIndex} handleStepNavigation={handleStepNavigation} headers={HEADERS} />
          </StepperContainer>
          <DealDetailPageContainer>
            <div id={`dealSection0`}>
              <UnitInfoHeader unitid={deal.unit._id} setSelectedUnitType={setSelectedUnitType} dealOptions={deal.options} />
            </div>

            <div id={`dealSection1`}>
              {deal.purchasers.map((purchaser: IWorksheetPurchaserInfo, i: number) => (
                <div key={i}>
                  <DealsPageLine />
                  {deal.purchasers.length === 1 ? (
                    <h1 style={{ textAlign: 'center' }}>{`Purchaser`}</h1>
                  ) : (
                    <h1 style={{ textAlign: 'center' }}>{`Purchaser ${i + 1}`}</h1>
                  )}
                  <PurchaserInfo
                    purchaserDetails={purchaser}
                    purchaserCount={deal.purchasers.length}
                    allExpand={true}
                    disabled={true}
                    subheading={'small'}
                    noCheckbox={true}
                  />
                </div>
              ))}
            </div>

            <div id={`dealSection2`}>
              <DealRealtorInfo dealid={dealid!} />
            </div>

            <div id={`dealSection3`}>
              <DepositStructure deposits={deal.deposit} depositImages={deal.depositImages} />
            </div>

            <div id={`dealSection4`}>
              <SolicitorInfo solicitor={deal.solicitor} />
            </div>

            <div id={`dealSection5`}>
              <MortgagePreapproval mortgage={deal.mortgage} />
            </div>

            <div id={`dealSection6`}>
              <ApsDocuments documents={deal.documents} />
            </div>

            <div id={`dealSection7`}>
              <ThirdPartyVerification thirdParty={deal.thirdParty} />
            </div>
          </DealDetailPageContainer>
        </>
      ) : (
        <LoadingWrapper title={'Deal is loading..'} modal={false} />
      )}
    </Box>
  );
};

const DealDetailPageContainer = styled.div`
  padding: 24px 0 0 220px;
  @media (max-width: 1150px) {
    padding: 24px;
  }
`;

const StepperContainer = styled.div`
  margin-left: 3vw;
  position: fixed;

  @media (max-width: 1150px) {
    display: none;
  }
`;

const GETDEALBYID = gql`
  query getRealtorDeal($deal: MongoID!, $project: MongoID!, $realtor: MongoID!) {
    getRealtorDeal(deal: $deal, project: $project, realtor: $realtor) {
      _id
      project {
        _id
      }
      unit {
        _id
        status
        suite
        unit
        level
        modelType
        unitType
        outdoorType
        basePrice
        bathroom
        exposure
        size
        outdoorSize
      }
      pendingUnit {
        status
        suite
        unit
        level
        modelType
        unitType
        outdoorType
        basePrice
        bathroom
        exposure
        size
        outdoorSize
      }
      purchasers {
        email
        firstName
        lastName
        corp
        sin
        dob
        streetAddress
        city
        province
        postalCode
        occupation
        employer
        purchaserType
        primaryPhone
        idType
        idNumber
        idJurisdiction
        idExpiry
        getUrl
        identifications {
          _id
          name
          getUrl
        }
        signingOfficers {
          dob
          fullName
          sin
          streetAddress
          primaryPhone
          email
        }
      }
      deposit {
        name
        amount
        dueDate
        accountNumber
        chequeDate
        chequeType
        chequeNumber
      }
      depositImages {
        name
        getUrl
      }
      pendingDeposits {
        name
        amount
        dueDate
        accountNumber
        chequeDate
        chequeType
      }
      options {
        name
        purchaseAmount
        amount
      }
      pendingOptions {
        name
        purchaseAmount
        amount
      }
      basePrice
      tags
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
      }
      draft
      mortgage {
        lender
        amount
        getUrl
        s3Key
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      documents {
        _id
        dsEnvelopeId
        status
        name
        isAPS
        updatedAt
        createdAt
        type
        s3Key
      }
      signDate
      executeDate
      firmDate
      totalPrice
      activities {
        title
      }
      thirdParty {
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
    }
  }
`;

export default DealDetailPage;
