import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useUserQuery } from '../../features/auth/authHooks';

const PublicRoute = ({ component: Component, children, ...rest }: any) => {
  const { loading, data } = useUserQuery();

  if (!loading) {
    if (data.getUser) {
      return <Navigate to={'/project-list'} />;
    } else return children;
  } else return;
};

export default PublicRoute;
