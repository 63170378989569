import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { gql, useQuery } from '@apollo/client';
import { WorksheetContext } from '../../context/WorksheetContext';
import { IUnit, IUnitData } from '../../types/unit';
import { FormControl, InputLabel, MenuItem, Select, Button, useMediaQuery, Tooltip, Grid, Box } from '@mui/material';
import { ErrorButton } from '../../utils/CommonStyles';
import { onlyUnique, splitNumber } from '../../utils/Functions';
import { IWishlistChoices } from '../../types/worksheet';
import { IProject } from '../../types/project';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';

const mdWidth = '834px';
const smWidth = '600px';

const WishlistChoices = (props: ChildProps) => {
  const { index, project } = props;
  const storeDispatch = useAppDispatch();
  const { wishlistValue } = useContext(WorksheetContext);
  const [wishlistChoices, dispatchChoices] = wishlistValue;
  const [allUnits, setAllUnits] = useState<IUnit[]>([]);
  const [unitTypes, setUnitTypes] = useState<string[]>([]);
  const [modelTypes, setModelTypes] = useState<any[]>([]);
  const [exposures, setExposures] = useState<string[]>([]);
  const [levels, setLevels] = useState({
    min: 0,
    max: 0,
  });
  const [splitLevels, setSplitLevels] = useState<any>([]);
  const [disableLow, setDisableLow] = useState(true);
  const [disableMid, setDisableMid] = useState(true);
  const [disableHigh, setDisableHigh] = useState(true);

  useEffect(() => {
    //filter based on unit, model, view, and level depending on which one the user picks first
    let filteredUnits = allUnits;
    setUnitTypes(filteredUnits.map((unit: IUnit) => unit.unitType).filter(onlyUnique));

    if (wishlistChoices[index].unitType) {
      filteredUnits = allUnits.filter((unit: IUnit) => unit.unitType === wishlistChoices[index].unitType);
    }

    if (!wishlistChoices[index].modelTypes.includes('Any') || wishlistChoices[index].modelTypes.length === 0) {
      filteredUnits = filteredUnits.filter((unit: IUnit) => wishlistChoices[index].modelTypes.includes(unit.modelType));
    }
    setExposures(filteredUnits.map((unit: IUnit) => unit.exposure).filter(onlyUnique));

    if (wishlistChoices[index].exposure !== 'Any' && wishlistChoices[index].exposure !== null) {
      filteredUnits = filteredUnits.filter((unit: IUnit) => unit.exposure === wishlistChoices[index].exposure);
    }

    const levelArray = filteredUnits.map((unit: IUnit) => unit.level).filter(onlyUnique);
    const levelArrayNum = levelArray.map((level: string) => parseInt(level));
    //if there aren't any number in level array num that is lower than the split levels then disable the choice
    setDisableLow(true);
    setDisableMid(true);
    setDisableHigh(true);
    levelArrayNum.forEach((level: number) => {
      if (level <= splitLevels[0]) {
        setDisableLow(false);
      } else if (splitLevels[0] < level && level <= splitLevels[0] + splitLevels[1]) {
        setDisableMid(false);
      } else if (splitLevels[0] + splitLevels[1] < level && level <= splitLevels[0] + splitLevels[1] + splitLevels[2]) {
        setDisableHigh(false);
      }
    });
  }, [allUnits, index, splitLevels, wishlistChoices]);

  useQuery(UNITS, {
    variables: { project: project._id },
    onCompleted: (data) => {
      setAllUnits(data.getAvailableUnits);
      let uniqueModelTypes = (arr: any, track = new Set()) =>
        arr.filter(({ modelType }: any) => (track.has(modelType) ? false : track.add(modelType)));
      let unique = uniqueModelTypes(data.getAvailableUnits).sort((a: any, b: any) => {
        if (a.modelType > b.modelType) return 1;
        if (a.modelType < b.modelType) return -1;
        return 0;
      });

      setModelTypes(unique);
      setUnitTypes(data.getAvailableUnits.map((unit: IUnit) => unit.unitType).filter(onlyUnique));
      setExposures(data.getAvailableUnits.map((unit: IUnit) => unit.exposure).filter(onlyUnique));
      //split levels into 3 parts
      const levelArray = data.getAvailableUnits.map((unit: IUnit) => unit.level).filter(onlyUnique);
      const levelArrayNum = levelArray.map((level: string) => parseInt(level));
      setLevels({
        min: Math.min(...levelArrayNum),
        max: Math.max(...levelArrayNum),
      });
      setSplitLevels(splitNumber(Math.max(...levelArrayNum), 3));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleUnitTypeChange = (event: any, value: string) => {
    dispatchChoices({
      type: 'SET_UNIT_TYPE',
      payload: {
        index: index,
        value: value,
      },
    });
  };

  const handleModelTypeChange = (event: any, value: any) => {
    let selectedUnit = null;
    if (value) {
      selectedUnit = allUnits.find((unitType: any) => value.modelType === unitType.modelType);
      if (!selectedUnit) return storeDispatch(showErrorSnackbar('No matching unit type found'));
    }

    dispatchChoices({
      type: 'SET_UNIT_TYPE',
      payload: {
        index: index,
        value: selectedUnit ? selectedUnit.unitType : '',
      },
    });

    dispatchChoices({
      type: 'SET_MODEL_TYPE',
      payload: {
        index: index,
        value: value ? [value.modelType] : '',
      },
    });
  };

  const handleLevelChange = (event: any) => {
    dispatchChoices({
      type: 'SET_LEVEL',
      payload: {
        index: index,
        value: event.target.value,
      },
    });
  };

  const handleExposureSelection = (event: any, value: string) => {
    dispatchChoices({
      type: 'SET_EXPOSURE_TYPE',
      payload: {
        index: index,
        value: value,
      },
    });
  };

  const handleRemoveChoice = () => {
    dispatchChoices({
      type: 'REMOVE_UNIT',
      payload: {
        index: index,
      },
    });
  };

  const unitTypeAutocomplete = (
    <Grid item lg={3} md={3} sm={12} xs={12}>
      <Tooltip title="Choose the number of bedrooms" placement="top">
        <Autocomplete
          id="tags-outlined"
          sx={{ minWidth: '30%', width: 'auto', mb: `0 !important` }}
          options={unitTypes}
          value={wishlistChoices[index].unitType}
          onChange={handleUnitTypeChange}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} variant={'outlined'} label="Unit Type" placeholder="Unit Type" />}
        />
      </Tooltip>
    </Grid>
  );

  const modelTypeAutocomplete = (
    <Grid item lg={3} md={3} sm={12} xs={12}>
      <Tooltip title="Choose a model" placement="top">
        <Autocomplete
          disablePortal
          sx={{ minWidth: '30%', width: 'auto', mb: `0 !important` }}
          freeSolo={false}
          id="tags-outlined"
          options={modelTypes}
          onChange={handleModelTypeChange}
          getOptionLabel={(option) => `${option.modelType} (${option.unitType})`}
          isOptionEqualToValue={(option, value) => {
            return `${option.modelType} (${option.unitType})` === value;
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant={'outlined'}
              error={!wishlistChoices[index].modelTypes.length}
              helperText={!wishlistChoices[index].modelTypes.length ? 'Please select a model type' : ''}
              label="Model Type"
              placeholder="Model Type"
            />
          )}
        />
      </Tooltip>
    </Grid>
  );

  const exposureSelection = (
    <Grid item lg={2} md={2} sm={12} xs={12}>
      <Tooltip title="Choose the exposure of the unit" placement="top">
        <Autocomplete
          sx={{ minWidth: '30%', width: 'auto', mb: `0 !important` }}
          id="tags-outlined"
          value={wishlistChoices[index].exposure}
          options={['Any'].concat(exposures.sort())}
          onChange={handleExposureSelection}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} variant={'outlined'} label="View Type" placeholder="View Type" />}
        />
      </Tooltip>
    </Grid>
  );

  const levelSelection = (
    <Grid item lg={3} md={3} sm={12} xs={12}>
      <Tooltip title="Choose a preferred level" placement="top">
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Level</InputLabel>
          <Select
            sx={{ width: '100%' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={wishlistChoices[index].level}
            label="Level"
            onChange={handleLevelChange}
          >
            <MenuItem value="Any">Any</MenuItem>
            <MenuItem disabled={disableLow} value={`Low ${levels.min}-${splitLevels[0]}`}>
              Low ({levels.min}-{splitLevels[0]})
            </MenuItem>
            <MenuItem disabled={disableMid} value={`Middle ${splitLevels[0] + 1}-${splitLevels[0] + splitLevels[1]}`}>
              Middle ({splitLevels[0] + 1}-{splitLevels[0] + splitLevels[1]})
            </MenuItem>
            <MenuItem disabled={disableHigh} value={`High ${splitLevels[0] + splitLevels[1] + 1}-${levels.max}`}>
              High ({splitLevels[0] + splitLevels[1] + 1}-{levels.max})
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  );

  return (
    <>
      <ChoicesContainer>
        <AutocompleteContainer container spacing={2}>
          {project.portal.showUnitTypes ? unitTypeAutocomplete : modelTypeAutocomplete}
          {exposureSelection}
          {levelSelection}
          <Grid item lg={1} md={1} sm={6} xs={12}>
            <ButtonContainer>
              {wishlistChoices.length > 1 && (
                <Box>
                  <ErrorButton
                    sx={{
                      minWidth: '50px',
                      display: 'block',
                    }}
                    disableElevation
                    variant="outlined"
                    onClick={handleRemoveChoice}
                  >
                    Remove
                  </ErrorButton>
                </Box>
              )}
            </ButtonContainer>
          </Grid>
        </AutocompleteContainer>
      </ChoicesContainer>
    </>
  );
};

interface ChildProps {
  index: number;
  choice: IWishlistChoices;
  project: IProject;
  style?: object;
}

const ChoicesContainer = styled.div`
  @media (max-width: ${mdWidth}) {
    display: inline-block;
    padding: 0 10px;
    width: 50%;
    margin-bottom: 10px;
    & .MuiAutocomplete-root {
      width: 100%;
      margin-bottom: 16px;
    }
    & .MuiFormControl-root {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${smWidth}) {
    padding: 0;
    width: 100%;
  }
`;

const AutocompleteContainer = styled(Grid)`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const UNITS = gql`
  query getAvailableUnits($project: MongoID!) {
    getAvailableUnits(project: $project) {
      _id
      modelType
      unitType
      level
      exposure
    }
  }
`;

export default WishlistChoices;
