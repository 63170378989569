import { IMenu } from './SideMenu';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import GridOnIcon from '@mui/icons-material/GridOn';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CommentIcon from '@mui/icons-material/Comment';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StyledLink } from '../../utils/CommonStyles';
import { urlName } from '../../utils/Functions';
import { theme } from '../../theme/theme';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';

const MenuItem = (props: ChildProps) => {
  const { menu, handleClose } = props;
  const { project } = useSelector(selectProject);

  const getAvataricon = (icon: string) => {
    switch (icon) {
      case 'HomeIcon':
        return <HomeIcon />;
      case 'AssignmentIcon':
        return <AssignmentIcon />;
      case 'GridOnIcon':
        return <GridOnIcon />;
      case 'InsertChartIcon':
        return <InsertChartIcon />;
      case 'SettingsIcon':
        return <SettingsIcon />;
      case 'ReceiptIcon':
        return <ReceiptIcon />;
      case 'CommentIcon':
        return <CommentIcon />;
      case 'Portal':
        return <ArrowBackIcon />;
      case 'AppointmentIcon':
        return <BookOnlineIcon />;
      default:
        return;
    }
  };

  return (
    <StyledLink onClick={handleClose} to={`/dashboard/${urlName(project.name)}/${menu.link}`}>
      <ListItem
        sx={{
          color: theme.palette.primary.main,
          ':hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
          },
          ':hover .MuiListItemIcon-root': {
            color: '#fff',
          },
        }}
        button
      >
        <ListItemIcon>{getAvataricon(menu.icon)}</ListItemIcon>
        <ListItemText primary={menu.title} />
      </ListItem>
    </StyledLink>
  );
};

interface ChildProps {
  menu: IMenu;
  handleClose: () => void;
}

export default MenuItem;
