/* Dependency Imports */
import styled from 'styled-components';
import React, { useEffect, useContext, useState } from 'react';
import { FormControl, FormControlLabel, Radio, Checkbox, FormGroup, RadioGroup, InputLabel, MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

/* Project Imports */
import { AppointmentContext } from '../../../context/AppointmentContext';
import { IProject } from '../../../types/project';
import { IAppointment, IAppointmentPurchaser, ISchedule } from '../../../types/appointment';
import { WorksheetTextField } from '../../../utils/CommonStyles';
import { IChoice, IQuestion } from '../../../types/question';
import { validateEmail, sanitizeNumber, validatePhone } from '../../../utils/Validations';
import { IUser } from '../../../types/user';
import { formatPhoneNumber } from '../../../utils/Functions';

const PurchaserForm = (props: ChildProps) => {
  /* Props */
  const { schedules, project, setNext, user, page, index } = props;

  /* States */
  const [questionsOutput, setQuestionsOutput] = useState<(void | JSX.Element)[]>([]);

  /* Contexts */
  const [appointment, dispatchAppointment]: [IAppointment, any] = useContext(AppointmentContext);

  /* Functions */
  const updatePurchaserInfo = (name: string, value: any) => {
    dispatchAppointment({ type: 'UPDATE_PURCHASER', payload: { key: name, value: value } });
  };

  const updateNotes = (name: string, value: string) => {
    dispatchAppointment({ type: 'UPDATE_NOTES', payload: { key: name, value: value } });
  };

  const modifyAnswer = (type: string, questionId: string, index: number, answer: string | undefined) => {
    if (!answer) return;
    dispatchAppointment({ type: type, payload: { questionId: questionId, index: index, answer: answer } });
  };

  const removeQuestion = (index: number) => {
    dispatchAppointment({ type: 'REMOVE_QUESTION', payload: { index: index } });
  };

  const singleAnswerChange = (index: number, questionId: string | undefined, value: string | null | undefined) => {
    if (!questionId) return;
    if (value) {
      modifyAnswer('SET_ANSWER', questionId, index, value);
      return;
    }
    removeQuestion(index);
  };

  const checkboxChange = (index: number, questionId: string | undefined, checked: boolean, value: string) => {
    if (!questionId) return;
    modifyAnswer(checked ? 'ADD_ANSWER' : 'REMOVE_ANSWER', questionId, index, value);
  };

  const getTextField = (
    label: string,
    name: string,
    required: boolean,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: string | number | null,
    error: boolean,
    helperText: string,
    type: string = 'text'
  ) => {
    return (
      <QuestionWrapper>
        <WorksheetTextField
          label={label}
          name={name}
          onChange={onChange}
          required={required}
          error={error}
          helperText={helperText}
          value={value}
          type={type}
        />
      </QuestionWrapper>
    );
  };

  const getQuestionOutput = (question: IQuestion, index: number, questions: IQuestion[]) => {
    let content = null;
    const q = appointment.questions?.length ? appointment.questions[index] : null;
    const firstChoice = q?.answer?.length ? q.answer[0] : null;
    // if (question.subQuestion) {
    //   answered = questionAnswered(question.parentQuestion);
    //   if (answered === undefined || answered === -1) return null;
    //   const requiredChoice = questions[answered].choices?.find(value =>  question._id && value.followUp?.includes(question._id));
    //   if (!requiredChoice || (appointment.questions && requiredChoice.choice && !appointment.questions[answered]?.answer?.includes(requiredChoice.choice))) return null;
    // }
    switch (question.type) {
      case 'dropdown':
        content = (
          <Autocomplete
            sx={{ width: '100%' }}
            options={question.choices ?? []}
            getOptionLabel={(option) => option.choice ?? ''}
            filterSelectedOptions
            value={firstChoice ? ({ choice: firstChoice } as IChoice) : null}
            isOptionEqualToValue={(option, value) => option.choice === value?.choice}
            renderInput={(params) => <WorksheetTextField {...params} />}
            onChange={(e, value) => singleAnswerChange(index, question._id, value?.choice)}
          />
        );
        break;
      case 'checkbox':
        content = (
          <FormGroup>
            {question.choices?.map((choice, numIndex) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={q?.answer && choice.choice ? q.answer.includes(choice.choice) : false}
                    onChange={(e) => checkboxChange(index, question._id, e.target.checked, choice.choice ?? '')}
                  />
                }
                label={choice.choice ?? ''}
                key={numIndex}
              />
            ))}
          </FormGroup>
        );
        break;
      case 'radio':
        content = (
          <FormControl>
            <RadioGroup value={firstChoice} onChange={(e) => singleAnswerChange(index, question._id, e.target.value)}>
              {question.choices?.map((choice, index) => (
                <FormControlLabel key={index} value={choice.choice ?? ''} control={<Radio />} label={choice.choice ?? ''} />
              ))}
            </RadioGroup>
          </FormControl>
        );
        break;
      case 'text':
        content = (
          <WorksheetTextField value={firstChoice ?? ''} onChange={(e) => singleAnswerChange(index, question._id, e.target.value)} />
        );
        break;
      case 'conditionalDropdown':
        content = (
          <Autocomplete
            sx={{ width: '100%' }}
            options={question.choices ?? []}
            getOptionLabel={(option) => option.choice ?? ''}
            filterSelectedOptions
            value={firstChoice ? ({ choice: firstChoice } as IChoice) : null}
            isOptionEqualToValue={(option, value) => option.choice === value?.choice}
            renderInput={(params) => <WorksheetTextField {...params} />}
            onChange={(e, value) => singleAnswerChange(index, question._id, value?.choice)}
          />
        );
        break;
      default:
        break;
    }
    if (question.display && (question.display === 'both' || question.display === 'public') && user === null) {
      return (
        <QuestionWrapper key={index}>
          <p style={{ margin: '0px' }}>{question.question} *</p>
          {content}
        </QuestionWrapper>
      );
    } else if (question.display && (question.display === 'both' || question.display === 'authenticated') && user) {
      return (
        <QuestionWrapper key={index}>
          <p style={{ margin: '0px' }}>{question.question} *</p>
          {content}
        </QuestionWrapper>
      );
    }
  };

  const emailValidated = !appointment.purchaserInfo.email || validateEmail(appointment.purchaserInfo.email);

  /* Effects */
  useEffect(() => {
    let allAnswered = true;
    let purchaserAnswered = true;
    questionsOutput?.forEach((output, index) => output && appointment.questions && !appointment.questions[index] && (allAnswered = false));
    if (
      !appointment.purchaserInfo.firstName ||
      !appointment.purchaserInfo.lastName ||
      !appointment.purchaserInfo.primaryPhone ||
      !appointment.purchaserInfo.email
    ) {
      purchaserAnswered = false;
    }
    if (page === index) {
      setNext(allAnswered && purchaserAnswered && emailValidated);
    }
    // eslint-disable-next-line
  }, [appointment]);

  useEffect(() => {
    if (schedules[0].questions)
      setQuestionsOutput(
        schedules[0].questions.map((question, index, self) => getQuestionOutput(question, index, self) ?? removeQuestion(index))
      );
    // eslint-disable-next-line
  }, [project, appointment]);

  return (
    <FormInfoContainer>
      <Heading>
        {user ? 'Reservation Information' : schedules[0].type === 'colourSelection' ? 'Purchaser Information' : 'Reservation Information'}
      </Heading>
      <FormQuestions>
        {getTextField(
          'First Name',
          'firstName',
          true,
          (e) => updatePurchaserInfo(e.target.name, e.target.value),
          appointment.purchaserInfo.firstName ?? '',
          false,
          ''
        )}
        {getTextField(
          'Last Name',
          'lastName',
          true,
          (e) => updatePurchaserInfo(e.target.name, e.target.value),
          appointment.purchaserInfo.lastName ?? '',
          false,
          ''
        )}
        {getTextField(
          'Phone Number',
          'primaryPhone',
          true,
          (e) => updatePurchaserInfo(e.target.name, formatPhoneNumber(e.target.value)),
          appointment.purchaserInfo.primaryPhone ?? '',
          false,
          ''
        )}
        {getTextField(
          'Email',
          'email',
          true,
          (e) => updatePurchaserInfo(e.target.name, e.target.value),
          appointment.purchaserInfo.email ?? '',
          !emailValidated,
          emailValidated ? '' : 'Please enter a valid email'
        )}
        <QuestionWrapper>
          <FormControl
            required
            sx={{
              width: '100%',
              '@media (max-width: 480px)': {
                width: '100%',
                '.MuiInputBase-root': {
                  width: '100%',
                },
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Are you working with a realtor?</InputLabel>
            <Select
              labelId="demo-realtor-type"
              name="realtorType"
              id="realtorTypeId"
              label="Are you working with a realtor?"
              value={appointment.purchaserInfo.realtorType}
              onChange={(e) => updatePurchaserInfo(e.target.name, e.target.value)}
              required
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="I am a Realtor">I am a Realtor</MenuItem>
            </Select>
          </FormControl>
        </QuestionWrapper>
        {schedules[0].allowGuests ? (
          <QuestionWrapper>
            <Autocomplete
              sx={{ width: '100%' }}
              options={Array.from({ length: schedules[0].maxGuests }, (v, k) => `${k + 1}`)}
              getOptionLabel={(option) => option.toString()}
              title="Number Of Additional Guests"
              value={appointment.purchaserInfo.numberOfGuests ? appointment.purchaserInfo.numberOfGuests : '0'}
              renderInput={(params) => (
                <WorksheetTextField label="Number Of Additional Guests" title="Number Of Additional Guests" {...params} />
              )}
              onChange={(e: any, value) => {
                updatePurchaserInfo('numberOfGuests', value)
              }}
            />
          </QuestionWrapper>
        ) : null}
        {schedules[0].questions.length ? (
          <>
            <Heading style={{ marginTop: '20px' }}>Questions</Heading>
            <FormQuestions>{questionsOutput}</FormQuestions>
          </>
        ) : null}
        {getTextField(
          schedules[0].type === 'colourSelection' ? 'Suite Number' : 'Notes',
          'notes',
          false,
          (e) => updateNotes(e.target.name, e.target.value),
          appointment.notes ?? '',
          false,
          ''
        )}
      </FormQuestions>
    </FormInfoContainer>
  );
};

/* Types */
interface ChildProps {
  project: IProject;
  schedules: ISchedule[];
  setNext: (next: boolean) => void;
  user: IUser;
  page: number;
  index: number;
}

/* Styled Components */
const Heading = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
`;

const FormQuestions = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FormInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 36.5px;
  padding-left: 24px;
  padding-right: 24px;
`;

const QuestionWrapper = styled.div`
  padding: 8px;
  min-width: 40vw;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export default PurchaserForm;
