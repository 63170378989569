import React, { useState, useContext } from 'react';
import { Link } from '@mui/material';
import { InvertedButton } from '../../utils/CommonStyles';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import logo from '../../assets/rds-logo-transparent.png';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IRealtor } from '../../types/realtor';
import ProfilePersonalInfo from '../profile/ProfilePersonalInfo';
import ProfileBrokerageInfo from '../profile/ProfileBrokerageInfo';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

const SignupFormForm = ({ setSelector, history }: { setSelector: (selector: string) => void, history: any }) => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [realtor, setRealtor] = useState<IRealtor>({
    email: '',
    firstName: '',
    lastName: '',
    brokerage: '',
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    brokeragePhone: '',
    brokerageFax: '',
    directPhone: '',
    recoNumber: '',
  });

  const [createUser] = useMutation(NEWUSER, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Registration has been completed successfully!'));
      navigate('/thank-you', {
          state: { 
            title: "Thank you for signing up!", 
            text: "Your registration form is currently being reviewed by our team. You will receive a confirmation email once your account is ready.",
            link: "/"
          }
        }
      )
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Creating Account'));
    },
  });

  const handleSubmit = () => {
    createUser({ variables: { realtor: realtor } });
  };

  const handleTextInput = (e: any) => {
    setRealtor({
      ...realtor,
      [e.target.name]: e.target.value,
    });
  };

  const handleText = (name: string, value: string) => {
    setRealtor({
      ...realtor,
      [name]: value,
    });
  };

  return (
    <div>
      <Signup>
        <LogoImgContainer>
          <LogoImg src={logo} />
        </LogoImgContainer>
        <SignupFormContainer>
          <TitleContainer>
            <BrokerTitle>Broker Portal</BrokerTitle>
            <SignupTitle>
              <SignupLongText>Sign up for an account</SignupLongText>
              <SignupShortText>Sign up</SignupShortText>
            </SignupTitle>
          </TitleContainer>
          <SignupForm
            id="signUpForm"
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ProfilePersonalInfo fields={realtor} handleTextInput={handleTextInput} invert={true} handleText={handleText} />
            <ProfileBrokerageInfo fields={realtor} handleTextInput={handleTextInput} invert={true} handleText={handleText} disableAutoComplete={true} />
            <p>
                By submitting this form, you are agreeing to receive communications from Residential Development Services and its clients and
                acknowledge that you may unsubscribe at any time.
              </p>
            <InvertedButton variant="contained" id="signUpBtn" type="submit" form="signUpForm" size="large">
              Sign Up
            </InvertedButton>
          </SignupForm>
          <div>
            <LoginPrompt> Have an account? </LoginPrompt>
            <Link
              component="button"
              style={{ color: 'white', margin: '10px 0' }}
              underline="hover"
              variant="body2"
              onClick={() => {
                setSelector('login');
              }}
            >
              <span style={{ fontWeight: 600 }}>Log in</span>
            </Link>
          </div>
        </SignupFormContainer>
      </Signup>
    </div>
  );
};

const Signup = styled.div`
  position: absolute;
  right: 0;

  background: #002044;
  color: white;
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 850px) {
    width: 100% !important;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 100px;
  text-align: right;

  @media (max-height: 700px) {
    height: 5%;
  }
`;

const BrokerTitle = styled.h1`
  margin: 0;
  white-space: nowrap;

  @media (max-height: 700px) {
    display: none;
  }
`;

const SignupTitle = styled.h5`
  margin: 0;

  @media (max-height: 700px) {
    font-size: 16px;
  }
`;

const SignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 70%;

  max-width: 600px;
`;

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LoginPrompt = styled.p`
  display: inline;
  font-size: 14px;
`;

const LogoImgContainer = styled.div`
  height: 100px;
  width: 70%;
  max-width: 600px;

  display: flex;
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;

  margin-right: 0;
  margin-left: auto;
  margin-top: 5%;
`;

const SignupLongText = styled.span`
  display: block;
  @media (max-height: 700px) {
    display: none;
  }
`;

const SignupShortText = styled.span`
  display: none;
  margin-bottom: 10px;
  @media (max-height: 700px) {
    display: block;
  }
`;

const NEWUSER = gql`
  mutation createRealtorUser($realtor: NewUserRealtorInput!) {
    createRealtorUser(realtor: $realtor) {
      firstName
      lastName
    }
  }
`;

export default SignupFormForm;
