import styled from 'styled-components';
import { Box } from '@mui/material';
import { IProject } from '../../../types/project';
import { IUser } from '../../../types/user';
import { useSearchParams } from 'react-router-dom';

// Project Imports

const FirstPage = (props: ChildProps) => {
  const { project, schedules } = props;
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  return (
    <FirstPageContainer style={{ width: '100%' }} image={project.imageGetUrl!}>
      <ColorOverlay style={{ width: '100%' }}></ColorOverlay>
      <div>
        <TitleOverlay>Please RSVP</TitleOverlay>
        <TextOverlay>
          {schedules.length
            ? schedules[0].mainText
            : 'Appointments are not currently available. Please check back in the future for our availability!'}
        </TextOverlay>
      </div>
      {type ? (
        <Box sx={{ fontSize: '8px', textAlign: 'center', color: '#fff', opacity: 1, filter: 'brightness(150%)' }}>
          If you would like to stop receiving these texts, please reply STOP to the SMS message
        </Box>
      ) : null}
    </FirstPageContainer>
  );
};

interface ChildProps {
  project: IProject;
  user: IUser;
  schedules: any[];
  scheduleId?: string;
}

const FirstPageContainer = styled.div<{ image: string }>`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${(props) => props.image});
  color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ColorOverlay = styled.div`
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: absolute;
`;

const TitleOverlay = styled.div`
  color: #fff;
  filter: brightness(150%);
  font-size: calc(5vw + 10px);
  font-weight: 500;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const TextOverlay = styled.div`
  color: #fff;
  filter: brightness(150%);
  font-size: calc(2vw + 3px);
  font-weight: 500;
  width: 70%;
  margin: auto;
  text-align: center;
`;

export default FirstPage;
