import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    desktop: true;
  }
}


export const theme = createTheme({
  palette: {    
    primary: {
      main: '#002044',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1577,
      desktop: 1800,
    },
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#002044',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#002044',
            color: '#fff',
          },
        },
        outlined: {
          borderColor: '#fff',
          color: '#fff',
          '&:hover': {
            borderColor: '#002044',
            backgroundColor: '#002044',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: '64px'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '64px'
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {      
          '& .Mui-selected': { // <-- mixing the two classes
            backgroundColor: '#002044 !important',
            color: '#fff !important'
          },
          border: '1px solid #000'
        },
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: '#000',
          border: '1px solid #000',
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#002044',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {   
        root: {  
          color: "#000"       
        }
      }
    },   
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#a17700"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ":disabled": {
            textFillColor: "#000"
          },
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color:'grey'
          }
        }
      }
    }  
  },
});

theme.palette.secondary.main = '#e8ddb2';
theme.palette.error.main = '#f54394';

theme.typography.h1 = {
  fontSize: '1.3rem',
  fontFamily: 'Inter',
  '@media (min-width:600px)': {
    fontSize: '2rem',
  },
};

theme.typography.h2 = {
  fontSize: '1rem',
  fontFamily: 'Inter',
  '@media (min-width:600px)': {
    fontSize: '1.7rem',
  },
};

theme.typography.h3 = {
  fontSize: '1.3rem',
  fontFamily: 'Inter',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
};

theme.typography.h5 = {
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 400,
};

theme.typography.h4 = {
  fontSize: '14px',
  fontWeight: 500,
};
