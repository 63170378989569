import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import { menuItems } from '../../utils/Constants';
import { IProject } from '../../types/project';

const SideMenu = (props: ChildProps) => {
  const { handleClose, project } = props;

  return (
    <div>
      <CloseContainer>
        <Close onClick={handleClose} />
      </CloseContainer>
      <Divider />
      {menuItems.map((menu: IMenu, index: number) => {
        if (menu.permission === "createWorksheets" && !project.portal.createWorksheets) {
          return null
        } else {
          return <MenuItem handleClose={handleClose} key={index} menu={menu} />;
        }
      })}
    </div>
  );
};

interface ChildProps {
  handleClose: () => void;
  project: IProject
}

const Close = styled(CloseIcon)({
  cursor: 'pointer',
});

const CloseContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
`;

export interface IMenu {
  title: string;
  icon: string;
  link: string;
  subMenu: boolean;
  permission: string;
}

export default SideMenu;
