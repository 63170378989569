/* Dependency Imports */
import { useState, useContext, useEffect } from 'react';
import Calendar from 'react-calendar';
import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

/* Project Imports */
import { AppointmentContext } from '../../../context/AppointmentContext';
import { IAppointmentTimeSlot } from '../../../types/appointment';
import { Title } from '../../../utils/CommonStyles';
import './CalendarStyles.css';

const SelectDateTime = (props: ChildProps) => {
  /* Props */
  const { appointmentTimeslots, setNext, page, index } = props;

  /* Contexts */
  const [appointment, dispatchAppointment] = useContext(AppointmentContext);

  /* States */
  const [date, setDate] = useState<Date | null>(appointment.date ?? null);

  /* Effects */
  useEffect(() => {
    if (page === index) {
      if (!appointment.date) {
        setNext(false);
      } else {
        setNext(true);
      }
    }
  }, [appointment, setNext]);

  /* Queries */

  /* Functions */
  const dateChange = (value: any, event: any) => {
    setDate(value);
  };

  const selectTime = (value: string | null) => {
    const dateValue = value ? new Date(value) : null;
    dispatchAppointment({ type: 'SET_DATE', payload: { value: dateValue } });
  };

  const appointmentsExist = (date: Date) => {
    return (
      appointmentTimeslots[date.getFullYear()] &&
      appointmentTimeslots[date.getFullYear()][date.getMonth()] &&
      appointmentTimeslots[date.getFullYear()][date.getMonth()][date.getDate()]
    );
  };

  const getTileClassName = (d: Date) => {
    let className = '';
    if (!appointmentsExist(d)) return className;
    if (date?.toUTCString() === d.toUTCString()) className += 'selected ';
    for (const timeslot of appointmentTimeslots[d.getFullYear()][d.getMonth()][d.getDate()]) {
      if (timeslot.numAvailable > 0) {
        className += 'available';
        return className;
      }
    }
    className += 'booked';
    return className;
  };

  const tileDisabled = (date: Date) => {
    return !appointmentsExist(date) || !appointmentsNum(appointmentTimeslots[date.getFullYear()][date.getMonth()][date.getDate()]);
  };

  const appointmentsNum = (timeslots: IAppointmentTimeSlot[]) => {
    return timeslots.filter((timeslot: any) => timeslot.numAvailable >= 0).reduce((prev, curr) => prev + curr.numAvailable, 0);
  };

  const appointmentsText = (timeslots: IAppointmentTimeSlot[], date: Date) => {
    let num = appointmentsNum(timeslots);
    if (new Date().setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0)) {
      return null;
    } else {
      return <AppointmentCount style={{ color: num > 10 ? 'green' : num > 5 ? 'rgb(220, 203, 0)' : 'red' }}>Available</AppointmentCount>;
    }
  };

  return (
    <ThirdPageWrapper>
      <Title>Reservation Date</Title>
      <Calendar
        value={date}
        defaultActiveStartDate={
          new Date().valueOf() >
          new Date(
            appointmentTimeslots[new Date().getFullYear()] ? new Date().getFullYear() : new Date().getFullYear() + 1,
            appointmentTimeslots.length
              ? parseInt(
                  Object.keys(
                    appointmentTimeslots[new Date().getFullYear()]
                      ? appointmentTimeslots[new Date().getFullYear()]
                      : appointmentTimeslots[new Date().getFullYear() + 1]
                  )[0],
                  10
                )
              : new Date().getFullYear(),
            1
          ).valueOf()
            ? new Date()
            : new Date(
                appointmentTimeslots[new Date().getFullYear()] ? new Date().getFullYear() : new Date().getFullYear() + 1,
                appointmentTimeslots.length
                  ? parseInt(
                      Object.keys(
                        appointmentTimeslots[new Date().getFullYear()]
                          ? appointmentTimeslots[new Date().getFullYear()]
                          : appointmentTimeslots[new Date().getFullYear() + 1]
                      )[0],
                      10
                    )
                  : new Date().getFullYear(),
                1
              )
        }
        onChange={dateChange}
        tileClassName={({ date }) => getTileClassName(date)}
        tileDisabled={({ date }) => tileDisabled(date)}
        tileContent={({ date }) =>
          !tileDisabled(date) ? appointmentsText(appointmentTimeslots[date.getFullYear()][date.getMonth()][date.getDate()], date) : null
        }
        minDate={new Date()}
        minDetail={'month'}
        calendarType={'US'}
        onActiveStartDateChange={(e) => {
          selectTime(null);
          dateChange(null, e);
        }}
      />
      <SelectWrapper style={{ visibility: date && appointmentsExist(date) ? 'visible' : 'hidden' }}>
        <Box>
          <div style={{ marginBottom: '10px' }}>
            {date?.toLocaleString('default', { month: 'long' }) + ' ' + date?.getDate() + ', ' + date?.getFullYear()}
          </div>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="timeslot-label">Time Slot</InputLabel>
            <Select
              labelId="timeslot-label"
              style={{ zIndex: 111 }}
              value={appointment.date ? appointment.date.toISOString() : ''}
              label="Time Slot"
              onChange={(e) => selectTime(e.target.value)}
            >
              {date &&
                appointmentsExist(date) &&
                appointmentTimeslots[date.getFullYear()][date.getMonth()][date.getDate()]
                  .sort((a, b) => a.startHour - b.startHour || a.startMin - b.startMin)
                  .map((value, index) => (
                    <MenuItem
                      value={new Date(date.getFullYear(), date.getMonth(), date.getDate(), value.startHour, value.startMin).toISOString()}
                      disabled={value.numAvailable <= 0}
                      key={index}
                    >
                      {value.timeslot}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Box>
      </SelectWrapper>
    </ThirdPageWrapper>
  );
};

/* Types */
interface ChildProps {
  appointmentTimeslots: IAppointmentTimeSlot[][][][];
  page: number;
  index: number;
  setNext: (next: boolean) => void;
}

/* Styled Components */
const ThirdPageWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
`;

const AppointmentCount = styled.div`
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
`;

const SelectWrapper = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
  @media (max-width: 600px) {
    margin-bottom: 35px;
  }
`;

/* Queries */

/* Mutations */

export default SelectDateTime;
