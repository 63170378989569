import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Box, FormGroup, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import { WorksheetContext } from '../../context/WorksheetContext';
import { ErrorButton } from '../../utils/CommonStyles';
import { numToCurrency } from '../../utils/Functions';
import { IOption } from '../../types/project';
import Checkbox from '@mui/material/Checkbox';

const SuiteOptionsCard = (props: ChildProps) => {
  const { optionsValue, joinWaitlistValue } = useContext(WorksheetContext);
  const [joinWaitlist, setJoinWaitlist] = joinWaitlistValue;
  const [options, setOptions] = optionsValue;
  const [purchaseAmount, setPurchaseAmount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    const chosenOption: IOption = options.find((o: IOption) => o.name === props.title);
    if (chosenOption) {
      setPurchaseAmount(chosenOption.purchaseAmount!);
      setAmount(chosenOption.amount!);
    } else {
      setPurchaseAmount(0);
      setAmount(0);
    }
  }, [options, props.title]);

  const handleAddOption = () => {
    let newPurchaseAmount = purchaseAmount + 1;
    let newAmount = newPurchaseAmount * props.price!;
    let newOptions = options.filter((option: any) => option.name !== props.title);
    newOptions = [...newOptions, { name: props.title, purchaseAmount: newPurchaseAmount, amount: newAmount }];

    setPurchaseAmount(newPurchaseAmount);
    setAmount(newAmount);
    setOptions(newOptions);
  };

  const handleRemoveOption = () => {
    setPurchaseAmount(purchaseAmount - 1);
    setAmount((purchaseAmount - 1) * props.price!);
    setOptions(options.filter((option: any) => option.name !== props.title));
  };

  const handleJoinWaitlist = (title: string) => {
    let waitlist = joinWaitlist.find((waitlist: string) => waitlist === title);

    if (waitlist) {
      setJoinWaitlist(joinWaitlist.filter((waitlist: string) => waitlist !== title));
    } else {
      setJoinWaitlist([...joinWaitlist, title]);
    }
  };

  return (
    <CardContainer item xs={12} sm={4} md={3}>
      <div style={{ padding: '20px', border: '1px solid #c4c4c4', borderRadius: '4px', height: '100%' }}>
        {!props.displayPrice ? (
          <h2>{props.title}</h2>
        ) : (
          <h2>
            {props.title} {props.price ? numToCurrency.format(props.price!) : null}
          </h2>
        )}
        {props.type === 'dashboard' && <PriceHeader>{numToCurrency.format(props.price!)}</PriceHeader>}
        {props.type === 'deals' && <PriceHeader>{`Total: ${numToCurrency.format(props.amount!)}`}</PriceHeader>}
        {props.type === 'deals' && <PriceHeader>{<h3>Amount: {props.purchaseAmount}</h3>}</PriceHeader>}
        {/* just for worksheet */}
        {props.displayOptions ? (
          <>
            {!props.type && purchaseAmount > 0 ? (
              <h3>{`${purchaseAmount} ${props.displayPrice ? `for $${amount}` : ''}`}</h3>
            ) : (
              !props.type && <h3>NONE ADDED</h3>
            )}
            {!props.type && props.amount && purchaseAmount > 0 && (
              <ErrorButton variant="outlined" disableElevation sx={{ marginRight: '5px', minWidth: '15px' }} onClick={handleRemoveOption}>
                -
              </ErrorButton>
            )}
            {!props.type && props.amount && purchaseAmount < props.amount && (
              <Button variant="contained" disableElevation sx={{ minWidth: '15px' }} onClick={handleAddOption}>
                +
              </Button>
            )}
            {!props.type && props.errorText && <ErrorText>{props.errorText}</ErrorText>}
          </>
        ) : (
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={joinWaitlist.find((waitlist: string) => waitlist === props.title) ? true : false}
                    onClick={() => handleJoinWaitlist(props.title)}
                  />
                }
                label="Join Waitlist"
              />
            </FormGroup>
          </Box>
        )}
      </div>
    </CardContainer>
  );
};

interface ChildProps {
  title: string;
  price?: number;
  purchaseAmount?: number;
  buttonText?: string;
  amount?: number;
  type?: string;
  totalAvailable?: number;
  errorText?: string;
  displayPrice?: boolean;
  displayOptions: boolean;
}

const ErrorText = styled.p`
  color: #f54394;
  font-size: 12px;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

const PriceHeader = styled.h2`
  padding-bottom: 0;
  margin-bottom: 0;
`;

const CardContainer = styled(Grid)`
  padding: 1rem;
  & h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  & h3 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 5px;
  }
`;

export default SuiteOptionsCard;
